export default class OperationStepSystem {
    constructor() {
        this.steps = [];
        this.undoSteps = [];
    }
    addStep(memoData) {
        this.steps.push(memoData);
        if (this.steps.length > 10) {
            this.steps.shift();
        }
        this.undoSteps = [];
    }
    undo() {
        if (this.steps.length > 0) {
            let memoData = this.steps.pop();
            this.undoSteps.push(memoData);
            return memoData;
        }
        return undefined;
    }
    redo() {
        if (this.undoSteps.length > 0) {
            let memoData = this.undoSteps.pop();
            this.steps.push(memoData);
            return memoData;
        }
        return undefined;
    }
    clear() {
        this.steps = [];
        this.undoSteps = [];
    }
}
export var OperationType;
(function (OperationType) {
    /**图像处理 */
    OperationType[OperationType["ImageProcessing"] = 0] = "ImageProcessing";
    /**轮廓修饰 */
    OperationType[OperationType["Decoration"] = 1] = "Decoration";
    /**平移旋转着丝点 */
    OperationType[OperationType["Transform"] = 2] = "Transform";
    /**染色体分割 */
    OperationType[OperationType["Segmentation"] = 3] = "Segmentation";
    /**染色体计数 */
    OperationType[OperationType["Count"] = 4] = "Count";
    /**标注 */
    OperationType[OperationType["Annotation"] = 5] = "Annotation";
    /** 删除染色体 */
    OperationType[OperationType["Delete"] = 6] = "Delete";
})(OperationType || (OperationType = {}));
