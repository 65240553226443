export const IDIOGRAM_DATA = [
    [
        [
            { maxY: 0.023, minY: 0, name: "p36.3" },
            { maxY: 0.038, minY: 0.023, name: "p36.2" },
            { maxY: 0.116, minY: 0.038, name: "p36.1" },
            { maxY: 0.131, minY: 0.116, name: "p35" },
            { maxY: 0.19, minY: 0.131, name: "p34" },
            { maxY: 0.204, minY: 0.19, name: "p33" },
            { maxY: 0.233, minY: 0.204, name: "p32" },
            { maxY: 0.307, minY: 0.233, name: "p31" },
            { maxY: 0.338, minY: 0.307, name: "p22" },
            { maxY: 0.39, minY: 0.338, name: "p21" },
            { maxY: 0.461, minY: 0.39, name: "p13" },
            { maxY: 0.469, minY: 0.461, name: "p12" },
            { maxY: 0.481, minY: 0.469, name: "p11" },
            { maxY: 0.495, minY: 0.481, name: "q11" },
            { maxY: 0.582, minY: 0.495, name: "q12" },
            { maxY: 0.691, minY: 0.582, name: "q21" },
            { maxY: 0.717, minY: 0.691, name: "q22-24" },
            { maxY: 0.741, minY: 0.717, name: "q25" },
            { maxY: 0.793, minY: 0.741, name: "q31" },
            { maxY: 0.844, minY: 0.793, name: "q32" },
            { maxY: 0.874, minY: 0.844, name: "q41" },
            { maxY: 0.975, minY: 0.874, name: "q42" },
            { maxY: 1, minY: 0.975, name: "q43-44" }
        ],
        [
            { maxY: 0.017, minY: 0, name: "p25" },
            { maxY: 0.049, minY: 0.017, name: "p24" },
            { maxY: 0.144, minY: 0.049, name: "p23" },
            { maxY: 0.167, minY: 0.144, name: "p22" },
            { maxY: 0.202, minY: 0.167, name: "p21" },
            { maxY: 0.249, minY: 0.202, name: "p14-16" },
            { maxY: 0.325, minY: 0.249, name: "p13" },
            { maxY: 0.356, minY: 0.325, name: "p12" },
            { maxY: 0.374, minY: 0.356, name: "p11.2" },
            { maxY: 0.386, minY: 0.374, name: "p11.1" },
            { maxY: 0.398, minY: 0.386, name: "q11.1" },
            { maxY: 0.474, minY: 0.398, name: "q11.2" },
            { maxY: 0.528, minY: 0.474, name: "q12-14" },
            { maxY: 0.592, minY: 0.528, name: "q21" },
            { maxY: 0.677, minY: 0.592, name: "q22-24" },
            { maxY: 0.739, minY: 0.677, name: "q31" },
            { maxY: 0.807, minY: 0.739, name: "q32" },
            { maxY: 0.839, minY: 0.807, name: "q33" },
            { maxY: 0.883, minY: 0.839, name: "q34-36" },
            { maxY: 1, minY: 0.883, name: "q37" }
        ],
        [
            { maxY: 0.017, minY: 0, name: "p26" },
            { maxY: 0.05, minY: 0.017, name: "p25" },
            { maxY: 0.117, minY: 0.05, name: "p22-24" },
            { maxY: 0.326, minY: 0.117, name: "p21" },
            { maxY: 0.348, minY: 0.326, name: "p14" },
            { maxY: 0.406, minY: 0.348, name: "p13" },
            { maxY: 0.441, minY: 0.406, name: "p12" },
            { maxY: 0.458, minY: 0.441, name: "p11" },
            { maxY: 0.474, minY: 0.458, name: "q11.1" },
            { maxY: 0.493, minY: 0.474, name: "q11.2" },
            { maxY: 0.541, minY: 0.493, name: "q12" },
            { maxY: 0.583, minY: 0.541, name: "q13" },
            { maxY: 0.71, minY: 0.583, name: "q21" },
            { maxY: 0.757, minY: 0.71, name: "q22-24" },
            { maxY: 0.79, minY: 0.757, name: "q25" },
            { maxY: 0.866, minY: 0.79, name: "q26" },
            { maxY: 0.915, minY: 0.866, name: "q27" },
            { maxY: 0.956, minY: 0.915, name: "q28" },
            { maxY: 1, minY: 0.956, name: "q29" }
        ],
        [
            { maxY: 0.122, minY: 0, name: "p16" },
            { maxY: 0.201, minY: 0.122, name: "p15" },
            { maxY: 0.254, minY: 0.201, name: "p14" },
            { maxY: 0.271, minY: 0.254, name: "p11" },
            { maxY: 0.286, minY: 0.271, name: "q11" },
            { maxY: 0.312, minY: 0.286, name: "q12" },
            { maxY: 0.361, minY: 0.312, name: "q13" },
            { maxY: 0.48, minY: 0.361, name: "q21" },
            { maxY: 0.56, minY: 0.48, name: "q22-24" },
            { maxY: 0.62, minY: 0.56, name: "q25" },
            { maxY: 0.718, minY: 0.62, name: "q26-28" },
            { maxY: 0.869, minY: 0.718, name: "q31" },
            { maxY: 0.938, minY: 0.869, name: "q32-34" },
            { maxY: 1, minY: 0.938, name: "q35" }
        ],
        [
            { maxY: 0.106, minY: 0, name: "p15" },
            { maxY: 0.179, minY: 0.106, name: "p14" },
            { maxY: 0.231, minY: 0.179, name: "p13" },
            { maxY: 0.245, minY: 0.231, name: "p12" },
            { maxY: 0.262, minY: 0.245, name: "p11" },
            { maxY: 0.278, minY: 0.262, name: "q11.1" },
            { maxY: 0.304, minY: 0.278, name: "q11.2" },
            { maxY: 0.343, minY: 0.304, name: "q12" },
            { maxY: 0.459, minY: 0.343, name: "q13" },
            { maxY: 0.551, minY: 0.459, name: "q14-21" },
            { maxY: 0.592, minY: 0.551, name: "q22" },
            { maxY: 0.666, minY: 0.592, name: "q23" },
            { maxY: 0.841, minY: 0.666, name: "q31" },
            { maxY: 0.904, minY: 0.841, name: "q32-34" },
            { maxY: 1, minY: 0.904, name: "q35" },
        ],
        [
            { maxY: 0.031, minY: 0, name: "p25" },
            { maxY: 0.057, minY: 0.031, name: "p24" },
            { maxY: 0.099, minY: 0.057, name: "p23" },
            { maxY: 0.142, minY: 0.099, name: "p22" },
            { maxY: 0.27, minY: 0.142, name: "p21" },
            { maxY: 0.321, minY: 0.27, name: "p12" },
            { maxY: 0.342, minY: 0.321, name: "p11" },
            { maxY: 0.363, minY: 0.342, name: "q11" },
            { maxY: 0.402, minY: 0.363, name: "q12" },
            { maxY: 0.497, minY: 0.402, name: "q13" },
            { maxY: 0.55, minY: 0.497, name: "q14-16" },
            { maxY: 0.63, minY: 0.55, name: "q21" },
            { maxY: 0.695, minY: 0.63, name: "q22" },
            { maxY: 0.731, minY: 0.695, name: "q23" },
            { maxY: 0.792, minY: 0.731, name: "q24" },
            { maxY: 1, minY: 0.792, name: "q25-27" }
        ],
        [
            { maxY: 0.024, minY: 0, name: "p22" },
            { maxY: 0.103, minY: 0.024, name: "p21" },
            { maxY: 0.208, minY: 0.103, name: "p15" },
            { maxY: 0.231, minY: 0.208, name: "p14" },
            { maxY: 0.311, minY: 0.231, name: "p13" },
            { maxY: 0.332, minY: 0.311, name: "p12" },
            { maxY: 0.352, minY: 0.332, name: "p11" },
            { maxY: 0.367, minY: 0.352, name: "q11.1" },
            { maxY: 0.48, minY: 0.367, name: "q11.2" },
            { maxY: 0.576, minY: 0.48, name: "q21" },
            { maxY: 0.681, minY: 0.576, name: "q22" },
            { maxY: 0.764, minY: 0.681, name: "q31" },
            { maxY: 0.819, minY: 0.764, name: "q32" },
            { maxY: 0.853, minY: 0.819, name: "q33-35" },
            { maxY: 1, minY: 0.853, name: "q36" }
        ],
        [
            { maxY: 0.063, minY: 0, name: "p23" },
            { maxY: 0.097, minY: 0.063, name: "p22" },
            { maxY: 0.229, minY: 0.097, name: "p21" },
            { maxY: 0.254, minY: 0.229, name: "p12" },
            { maxY: 0.292, minY: 0.254, name: "p11.2" },
            { maxY: 0.307, minY: 0.292, name: "p11.1" },
            { maxY: 0.327, minY: 0.307, name: "q11.1" },
            { maxY: 0.354, minY: 0.327, name: "q11.2" },
            { maxY: 0.39, minY: 0.354, name: "q12" },
            { maxY: 0.45, minY: 0.39, name: "q13" },
            { maxY: 0.542, minY: 0.45, name: "q21" },
            { maxY: 0.671, minY: 0.542, name: "q22" },
            { maxY: 0.791, minY: 0.671, name: "q23" },
            { maxY: 1, minY: 0.791, name: "q24" }
        ],
        [
            { maxY: 0.042, minY: 0, name: "p24" },
            { maxY: 0.097, minY: 0.042, name: "p23" },
            { maxY: 0.151, minY: 0.097, name: "p22" },
            { maxY: 0.217, minY: 0.151, name: "p21" },
            { maxY: 0.299, minY: 0.217, name: "p13" },
            { maxY: 0.316, minY: 0.299, name: "p12" },
            { maxY: 0.33, minY: 0.316, name: "p11" },
            { maxY: 0.347, minY: 0.33, name: "q11" },
            { maxY: 0.445, minY: 0.347, name: "q12" },
            { maxY: 0.555, minY: 0.445, name: "q13" },
            { maxY: 0.677, minY: 0.555, name: "q21" },
            { maxY: 0.737, minY: 0.677, name: "q22" },
            { maxY: 0.845, minY: 0.737, name: "q31-33" },
            { maxY: 1, minY: 0.845, name: "q34" }
        ],
        [
            { maxY: 0.032, minY: 0, name: "p15" },
            { maxY: 0.059, minY: 0.032, name: "p14" },
            { maxY: 0.146, minY: 0.059, name: "p13" },
            { maxY: 0.192, minY: 0.146, name: "p12" },
            { maxY: 0.31, minY: 0.192, name: "p11.2" },
            { maxY: 0.323, minY: 0.31, name: "p11.1" },
            { maxY: 0.342, minY: 0.323, name: "q11.1" },
            { maxY: 0.39, minY: 0.342, name: "q11.2" },
            { maxY: 0.487, minY: 0.39, name: "q21" },
            { maxY: 0.612, minY: 0.487, name: "q22" },
            { maxY: 0.694, minY: 0.612, name: "q23" },
            { maxY: 0.776, minY: 0.694, name: "q24" },
            { maxY: 0.859, minY: 0.776, name: "q25" },
            { maxY: 1, minY: 0.859, name: "q26" }
        ],
        [
            { maxY: 0.186, minY: 0, name: "p15" },
            { maxY: 0.284, minY: 0.186, name: "p11-14" },
            { maxY: 0.38, minY: 0.284, name: "p11.2" },
            { maxY: 0.401, minY: 0.38, name: "p11.1" },
            { maxY: 0.426, minY: 0.401, name: "q11" },
            { maxY: 0.449, minY: 0.426, name: "q12" },
            { maxY: 0.624, minY: 0.449, name: "q13" },
            { maxY: 0.789, minY: 0.624, name: "q14-22" },
            { maxY: 0.929, minY: 0.789, name: "q23" },
            { maxY: 0.956, minY: 0.929, name: "q24" },
            { maxY: 1, minY: 0.956, name: "q25" }
        ],
        [
            { maxY: 0.088, minY: 0, name: "p13" },
            { maxY: 0.173, minY: 0.088, name: "p12" },
            { maxY: 0.242, minY: 0.173, name: "p11.2" },
            { maxY: 0.262, minY: 0.242, name: "p11.1" },
            { maxY: 0.285, minY: 0.262, name: "q11" },
            { maxY: 0.303, minY: 0.285, name: "q12" },
            { maxY: 0.427, minY: 0.303, name: "q13" },
            { maxY: 0.524, minY: 0.427, name: "q14" },
            { maxY: 0.537, minY: 0.524, name: "q15" },
            { maxY: 0.669, minY: 0.537, name: "q21-23" },
            { maxY: 0.837, minY: 0.669, name: "q24.1" },
            { maxY: 0.876, minY: 0.837, name: "q24.2" },
            { maxY: 1, minY: 0.876, name: "q24.3" }
        ],
        [
            { maxY: 0.051, minY: 0, name: "p13" },
            { maxY: 0.079, minY: 0.051, name: "p12" },
            { maxY: 0.13, minY: 0.079, name: "p11.2" },
            { maxY: 0.153, minY: 0.13, name: "p11.1" },
            { maxY: 0.174, minY: 0.153, name: "q11" },
            { maxY: 0.323, minY: 0.174, name: "q12" },
            { maxY: 0.377, minY: 0.323, name: "q13" },
            { maxY: 0.535, minY: 0.377, name: "q14" },
            { maxY: 0.651, minY: 0.535, name: "q21" },
            { maxY: 0.721, minY: 0.651, name: "q22" },
            { maxY: 0.844, minY: 0.721, name: "q31-33" },
            { maxY: 1, minY: 0.844, name: "q34" }
        ],
        [
            { maxY: 0.05, minY: 0, name: "p13" },
            { maxY: 0.071, minY: 0.05, name: "p12" },
            { maxY: 0.136, minY: 0.071, name: "p11.2" },
            { maxY: 0.156, minY: 0.136, name: "p11.1" },
            { maxY: 0.182, minY: 0.156, name: "q11.1" },
            { maxY: 0.285, minY: 0.182, name: "q11.2" },
            { maxY: 0.385, minY: 0.285, name: "q12-21" },
            { maxY: 0.402, minY: 0.385, name: "q22" },
            { maxY: 0.442, minY: 0.402, name: "q23" },
            { maxY: 0.761, minY: 0.442, name: "q24" },
            { maxY: 0.842, minY: 0.761, name: "q31" },
            { maxY: 1, minY: 0.842, name: "q32" }
        ],
        [
            { maxY: 0.053, minY: 0, name: "p13" },
            { maxY: 0.086, minY: 0.053, name: "p12" },
            { maxY: 0.149, minY: 0.086, name: "p11.2" },
            { maxY: 0.17, minY: 0.149, name: "p11.1" },
            { maxY: 0.195, minY: 0.17, name: "q11.1" },
            { maxY: 0.299, minY: 0.195, name: "q11.2" },
            { maxY: 0.329, minY: 0.299, name: "q12-14" },
            { maxY: 0.4, minY: 0.329, name: "q15" },
            { maxY: 0.514, minY: 0.4, name: "q21" },
            { maxY: 0.78, minY: 0.514, name: "q22" },
            { maxY: 0.802, minY: 0.78, name: "q23" },
            { maxY: 0.884, minY: 0.802, name: "q24" },
            { maxY: 0.939, minY: 0.884, name: "q25" },
            { maxY: 1, minY: 0.939, name: "q26" }
        ],
        [
            { maxY: 0.072, minY: 0, name: "p13.3" },
            { maxY: 0.121, minY: 0.072, name: "p13.2" },
            { maxY: 0.209, minY: 0.121, name: "p13.1" },
            { maxY: 0.278, minY: 0.209, name: "p12" },
            { maxY: 0.391, minY: 0.278, name: "p11.2" },
            { maxY: 0.419, minY: 0.391, name: "p11.1" },
            { maxY: 0.446, minY: 0.419, name: "q11.1" },
            { maxY: 0.534, minY: 0.446, name: "q11.2" },
            { maxY: 0.645, minY: 0.534, name: "q12-13" },
            { maxY: 0.788, minY: 0.645, name: "q21-23" },
            { maxY: 1, minY: 0.788, name: "q24" }
        ],
        [
            { maxY: 0.168, minY: 0, name: "p13" },
            { maxY: 0.248, minY: 0.168, name: "p12" },
            { maxY: 0.31, minY: 0.248, name: "p11.2" },
            { maxY: 0.339, minY: 0.31, name: "p11.1" },
            { maxY: 0.368, minY: 0.339, name: "q11.1" },
            { maxY: 0.422, minY: 0.368, name: "q11.2" },
            { maxY: 0.479, minY: 0.422, name: "q12" },
            { maxY: 0.718, minY: 0.479, name: "q21" },
            { maxY: 0.877, minY: 0.718, name: "q22-24" },
            { maxY: 1, minY: 0.877, name: "q25" }
        ],
        [
            { maxY: 0.056, minY: 0, name: "p11.3" },
            { maxY: 0.231, minY: 0.056, name: "p11.2" },
            { maxY: 0.266, minY: 0.231, name: "p11.1" },
            { maxY: 0.298, minY: 0.266, name: "q11.1" },
            { maxY: 0.348, minY: 0.298, name: "q11.2" },
            { maxY: 0.48, minY: 0.348, name: "q12" },
            { maxY: 0.771, minY: 0.48, name: "q21" },
            { maxY: 0.937, minY: 0.771, name: "q22" },
            { maxY: 1, minY: 0.937, name: "q23" }
        ],
        [
            { maxY: 0.371, minY: 0, name: "p13" },
            { maxY: 0.425, minY: 0.371, name: "p12" },
            { maxY: 0.458, minY: 0.425, name: "p11" },
            { maxY: 0.494, minY: 0.458, name: "q11" },
            { maxY: 0.545, minY: 0.494, name: "q12" },
            { maxY: 0.934, minY: 0.545, name: "q13.1-13.3" },
            { maxY: 1, minY: 0.934, name: "q13.4" }
        ],
        [
            { maxY: 0.101, minY: 0, name: "p13" },
            { maxY: 0.239, minY: 0.101, name: "p12" },
            { maxY: 0.41, minY: 0.239, name: "p11.2" },
            { maxY: 0.448, minY: 0.41, name: "p11.1" },
            { maxY: 0.485, minY: 0.448, name: "q11.1" },
            { maxY: 0.761, minY: 0.485, name: "q11.2-13.1" },
            { maxY: 0.88, minY: 0.761, name: "q13.2" },
            { maxY: 1, minY: 0.88, name: "q13.3" }
        ],
        [
            { maxY: 0.106, minY: 0, name: "p13" },
            { maxY: 0.154, minY: 0.106, name: "p12" },
            { maxY: 0.28, minY: 0.154, name: "p11.2" },
            { maxY: 0.324, minY: 0.28, name: "p11.1" },
            { maxY: 0.362, minY: 0.324, name: "q11.1" },
            { maxY: 0.425, minY: 0.362, name: "q11.2" },
            { maxY: 0.614, minY: 0.425, name: "q21" },
            { maxY: 1, minY: 0.614, name: "q22" }
        ],
        [
            { maxY: 0.098, minY: 0, name: "p13" },
            { maxY: 0.143, minY: 0.098, name: "p12" },
            { maxY: 0.25, minY: 0.143, name: "p11.2" },
            { maxY: 0.286, minY: 0.25, name: "p11.1" },
            { maxY: 0.335, minY: 0.286, name: "q11.1" },
            { maxY: 0.54, minY: 0.335, name: "q11.2" },
            { maxY: 0.638, minY: 0.54, name: "q12" },
            { maxY: 1, minY: 0.638, name: "q13" }
        ],
        [
            { maxY: 0.016, minY: 0, name: "p22.3" },
            { maxY: 0.045, minY: 0.016, name: "p22.2" },
            { maxY: 0.135, minY: 0.045, name: "p22.1" },
            { maxY: 0.216, minY: 0.135, name: "p21" },
            { maxY: 0.348, minY: 0.216, name: "p11.2-11.4" },
            { maxY: 0.362, minY: 0.348, name: "p11.1" },
            { maxY: 0.377, minY: 0.362, name: "q11" },
            { maxY: 0.494, minY: 0.377, name: "q13" },
            { maxY: 0.598, minY: 0.494, name: "q21" },
            { maxY: 0.816, minY: 0.598, name: "q22-24" },
            { maxY: 0.921, minY: 0.816, name: "q25-27" },
            { maxY: 1, minY: 0.921, name: "q28" }
        ],
        [
            { maxY: 0.059, minY: 0, name: "p11.3" },
            { maxY: 0.288, minY: 0.059, name: "p11.2" },
            { maxY: 0.333, minY: 0.288, name: "p11.1" },
            { maxY: 0.379, minY: 0.333, name: "q11.1" },
            { maxY: 0.416, minY: 0.379, name: "q11.21" },
            { maxY: 0.484, minY: 0.416, name: "q11.22" },
            { maxY: 0.694, minY: 0.484, name: "q11.23" },
            { maxY: 1, minY: 0.694, name: "q12" }
        ]
    ],
    [
        [
            { maxY: 0.022, minY: 0, name: "p36.3" },
            { maxY: 0.039, minY: 0.022, name: "p36.2" },
            { maxY: 0.115, minY: 0.039, name: "p36.1" },
            { maxY: 0.131, minY: 0.115, name: "p35" },
            { maxY: 0.144, minY: 0.131, name: "p34.3" },
            { maxY: 0.162, minY: 0.144, name: "p34.2" },
            { maxY: 0.182, minY: 0.162, name: "p34.1" },
            { maxY: 0.197, minY: 0.182, name: "p33" },
            { maxY: 0.233, minY: 0.197, name: "p32" },
            { maxY: 0.307, minY: 0.233, name: "p31" },
            { maxY: 0.337, minY: 0.307, name: "p22" },
            { maxY: 0.389, minY: 0.337, name: "p21" },
            { maxY: 0.459, minY: 0.389, name: "p13" },
            { maxY: 0.468, minY: 0.459, name: "p12" },
            { maxY: 0.483, minY: 0.468, name: "p11" },
            { maxY: 0.494, minY: 0.483, name: "q11" },
            { maxY: 0.58, minY: 0.494, name: "q12" },
            { maxY: 0.653, minY: 0.58, name: "q21" },
            { maxY: 0.673, minY: 0.653, name: "q22" },
            { maxY: 0.685, minY: 0.673, name: "q23" },
            { maxY: 0.717, minY: 0.685, name: "q24" },
            { maxY: 0.74, minY: 0.717, name: "q25" },
            { maxY: 0.791, minY: 0.74, name: "q31" },
            { maxY: 0.862, minY: 0.791, name: "q32" },
            { maxY: 0.892, minY: 0.862, name: "q41" },
            { maxY: 0.965, minY: 0.892, name: "q42" },
            { maxY: 0.984, minY: 0.965, name: "q43" },
            { maxY: 1, minY: 0.984, name: "q44" }
        ],
        [
            { maxY: 0.037, minY: 0, name: "p25" },
            { maxY: 0.067, minY: 0.037, name: "p24" },
            { maxY: 0.124, minY: 0.067, name: "p23" },
            { maxY: 0.157, minY: 0.124, name: "p22" },
            { maxY: 0.202, minY: 0.157, name: "p21" },
            { maxY: 0.242, minY: 0.202, name: "p16" },
            { maxY: 0.245, minY: 0.242, name: "p15" },
            { maxY: 0.262, minY: 0.245, name: "p14" },
            { maxY: 0.323, minY: 0.262, name: "p13" },
            { maxY: 0.355, minY: 0.323, name: "p12" },
            { maxY: 0.372, minY: 0.355, name: "p11.2" },
            { maxY: 0.385, minY: 0.372, name: "p11.1" },
            { maxY: 0.399, minY: 0.385, name: "q11.1" },
            { maxY: 0.407, minY: 0.399, name: "q11.2" },
            { maxY: 0.445, minY: 0.407, name: "q12" },
            { maxY: 0.47, minY: 0.445, name: "q13" },
            { maxY: 0.49, minY: 0.47, name: "q14.1" },
            { maxY: 0.493, minY: 0.49, name: "q14.2" },
            { maxY: 0.516, minY: 0.493, name: "q14.3" },
            { maxY: 0.591, minY: 0.516, name: "q21" },
            { maxY: 0.635, minY: 0.591, name: "q22" },
            { maxY: 0.652, minY: 0.635, name: "q23" },
            { maxY: 0.694, minY: 0.652, name: "q24" },
            { maxY: 0.737, minY: 0.694, name: "q31" },
            { maxY: 0.808, minY: 0.737, name: "q32" },
            { maxY: 0.837, minY: 0.808, name: "q33" },
            { maxY: 0.868, minY: 0.837, name: "q34" },
            { maxY: 0.891, minY: 0.868, name: "q35" },
            { maxY: 0.923, minY: 0.891, name: "q36" },
            { maxY: 1, minY: 0.923, name: "q37" },
        ],
        [
            { maxY: 0.018, minY: 0, name: "p26" },
            { maxY: 0.051, minY: 0.018, name: "p25" },
            { maxY: 0.098, minY: 0.051, name: "p24" },
            { maxY: 0.107, minY: 0.098, name: "p23" },
            { maxY: 0.152, minY: 0.107, name: "p22" },
            { maxY: 0.297, minY: 0.152, name: "p21" },
            { maxY: 0.318, minY: 0.297, name: "p14" },
            { maxY: 0.366, minY: 0.318, name: "p13" },
            { maxY: 0.408, minY: 0.366, name: "p12" },
            { maxY: 0.445, minY: 0.408, name: "p11" },
            { maxY: 0.459, minY: 0.445, name: "q11.1" },
            { maxY: 0.472, minY: 0.459, name: "q11.2" },
            { maxY: 0.495, minY: 0.472, name: "q12" },
            { maxY: 0.511, minY: 0.495, name: "q13.1" },
            { maxY: 0.55, minY: 0.511, name: "q13.2" },
            { maxY: 0.605, minY: 0.55, name: "q13.3" },
            { maxY: 0.67, minY: 0.605, name: "q21" },
            { maxY: 0.692, minY: 0.67, name: "q22" },
            { maxY: 0.71, minY: 0.692, name: "q23" },
            { maxY: 0.759, minY: 0.71, name: "q24" },
            { maxY: 0.795, minY: 0.759, name: "q25" },
            { maxY: 0.848, minY: 0.795, name: "q26.1" },
            { maxY: 0.861, minY: 0.848, name: "q26.2" },
            { maxY: 0.91, minY: 0.861, name: "q26.3" },
            { maxY: 0.948, minY: 0.91, name: "q27" },
            { maxY: 0.968, minY: 0.948, name: "q28" },
            { maxY: 1, minY: 0.968, name: "q29" }
        ],
        [
            { maxY: 0.092, minY: 0, name: "p16" },
            { maxY: 0.127, minY: 0.092, name: "p15.3" },
            { maxY: 0.135, minY: 0.127, name: "p15.2" },
            { maxY: 0.168, minY: 0.135, name: "p15.1" },
            { maxY: 0.224, minY: 0.168, name: "p14" },
            { maxY: 0.248, minY: 0.224, name: "p13" },
            { maxY: 0.254, minY: 0.248, name: "p12" },
            { maxY: 0.272, minY: 0.254, name: "p11" },
            { maxY: 0.286, minY: 0.272, name: "q11" },
            { maxY: 0.312, minY: 0.286, name: "q12" },
            { maxY: 0.362, minY: 0.312, name: "q13" },
            { maxY: 0.479, minY: 0.362, name: "q21" },
            { maxY: 0.517, minY: 0.479, name: "q22" },
            { maxY: 0.527, minY: 0.517, name: "q23" },
            { maxY: 0.565, minY: 0.527, name: "q24" },
            { maxY: 0.585, minY: 0.565, name: "q25" },
            { maxY: 0.639, minY: 0.585, name: "q26" },
            { maxY: 0.658, minY: 0.639, name: "q27" },
            { maxY: 0.728, minY: 0.658, name: "q28" },
            { maxY: 0.77, minY: 0.728, name: "q31.1" },
            { maxY: 0.789, minY: 0.77, name: "q31.2" },
            { maxY: 0.855, minY: 0.789, name: "q31.3" },
            { maxY: 0.896, minY: 0.855, name: "q32" },
            { maxY: 0.925, minY: 0.896, name: "q33" },
            { maxY: 0.956, minY: 0.925, name: "q34" },
            { maxY: 1, minY: 0.956, name: "q35" }
        ],
        [
            { maxY: 0.05, minY: 0, name: "p15.3" },
            { maxY: 0.082, minY: 0.05, name: "p15.2" },
            { maxY: 0.11, minY: 0.082, name: "p15.1" },
            { maxY: 0.172, minY: 0.11, name: "p14" },
            { maxY: 0.231, minY: 0.172, name: "p13" },
            { maxY: 0.247, minY: 0.231, name: "p12" },
            { maxY: 0.264, minY: 0.247, name: "p11" },
            { maxY: 0.278, minY: 0.264, name: "q11.1" },
            { maxY: 0.318, minY: 0.278, name: "q11.2" },
            { maxY: 0.363, minY: 0.318, name: "q12" },
            { maxY: 0.42, minY: 0.363, name: "q13" },
            { maxY: 0.492, minY: 0.42, name: "q14" },
            { maxY: 0.515, minY: 0.492, name: "q15" },
            { maxY: 0.597, minY: 0.515, name: "q21" },
            { maxY: 0.627, minY: 0.597, name: "q22" },
            { maxY: 0.7, minY: 0.627, name: "q23" },
            { maxY: 0.769, minY: 0.7, name: "q31" },
            { maxY: 0.802, minY: 0.769, name: "q32" },
            { maxY: 0.842, minY: 0.802, name: "q33" },
            { maxY: 0.907, minY: 0.842, name: "q34" },
            { maxY: 1, minY: 0.907, name: "q35" }
        ],
        [
            { maxY: 0.032, minY: 0, name: "p26" },
            { maxY: 0.059, minY: 0.032, name: "p24" },
            { maxY: 0.086, minY: 0.059, name: "p23" },
            { maxY: 0.13, minY: 0.086, name: "p22" },
            { maxY: 0.205, minY: 0.13, name: "p21.3" },
            { maxY: 0.22, minY: 0.205, name: "p21.2" },
            { maxY: 0.27, minY: 0.22, name: "p21.1" },
            { maxY: 0.321, minY: 0.27, name: "p12" },
            { maxY: 0.326, minY: 0.321, name: "p11.2" },
            { maxY: 0.344, minY: 0.326, name: "p11.1" },
            { maxY: 0.366, minY: 0.344, name: "q11" },
            { maxY: 0.406, minY: 0.366, name: "q12" },
            { maxY: 0.435, minY: 0.406, name: "q13" },
            { maxY: 0.474, minY: 0.435, name: "q14" },
            { maxY: 0.498, minY: 0.474, name: "q15" },
            { maxY: 0.555, minY: 0.498, name: "q16" },
            { maxY: 0.65, minY: 0.555, name: "q21" },
            { maxY: 0.725, minY: 0.65, name: "q22" },
            { maxY: 0.762, minY: 0.725, name: "q23" },
            { maxY: 0.824, minY: 0.762, name: "q24" },
            { maxY: 0.906, minY: 0.824, name: "q25" },
            { maxY: 0.945, minY: 0.906, name: "q26" },
            { maxY: 1, minY: 0.945, name: "q27" }
        ],
        [
            { maxY: 0.04, minY: 0, name: "p22" },
            { maxY: 0.102, minY: 0.04, name: "p21" },
            { maxY: 0.192, minY: 0.102, name: "p15" },
            { maxY: 0.236, minY: 0.192, name: "p14" },
            { maxY: 0.294, minY: 0.236, name: "p13" },
            { maxY: 0.32, minY: 0.294, name: "p12" },
            { maxY: 0.333, minY: 0.32, name: "p11.2" },
            { maxY: 0.35, minY: 0.333, name: "p11.1" },
            { maxY: 0.374, minY: 0.35, name: "q11.1" },
            { maxY: 0.478, minY: 0.374, name: "q11.2" },
            { maxY: 0.577, minY: 0.478, name: "q21" },
            { maxY: 0.658, minY: 0.577, name: "q22" },
            { maxY: 0.764, minY: 0.658, name: "q31" },
            { maxY: 0.849, minY: 0.764, name: "q32" },
            { maxY: 0.876, minY: 0.849, name: "q33" },
            { maxY: 0.883, minY: 0.876, name: "q34" },
            { maxY: 0.922, minY: 0.883, name: "q35" },
            { maxY: 1, minY: 0.922, name: "q36" }
        ],
        [
            { maxY: 0.062, minY: 0, name: "p23" },
            { maxY: 0.103, minY: 0.062, name: "p22" },
            { maxY: 0.203, minY: 0.103, name: "p21" },
            { maxY: 0.254, minY: 0.203, name: "p12" },
            { maxY: 0.292, minY: 0.254, name: "p11.2" },
            { maxY: 0.306, minY: 0.292, name: "p11.1" },
            { maxY: 0.322, minY: 0.306, name: "q11.1" },
            { maxY: 0.35, minY: 0.322, name: "q11.2" },
            { maxY: 0.391, minY: 0.35, name: "q12" },
            { maxY: 0.471, minY: 0.391, name: "q13" },
            { maxY: 0.547, minY: 0.471, name: "q21.1" },
            { maxY: 0.572, minY: 0.547, name: "q21.2" },
            { maxY: 0.647, minY: 0.572, name: "q21.3" },
            { maxY: 0.743, minY: 0.647, name: "q22" },
            { maxY: 0.804, minY: 0.743, name: "q23" },
            { maxY: 0.879, minY: 0.804, name: "q24.1" },
            { maxY: 0.936, minY: 0.879, name: "q24.2" },
            { maxY: 1, minY: 0.936, name: "q24.3" }
        ],
        [
            { maxY: 0.04, minY: 0, name: "p24" },
            { maxY: 0.86, minY: 0.04, name: "p23" },
            { maxY: 0.142, minY: 0.86, name: "p22" },
            { maxY: 0.21, minY: 0.142, name: "p21" },
            { maxY: 0.29, minY: 0.21, name: "p13" },
            { maxY: 0.314, minY: 0.29, name: "p12" },
            { maxY: 0.33, minY: 0.314, name: "p11" },
            { maxY: 0.348, minY: 0.33, name: "q11" },
            { maxY: 0.447, minY: 0.348, name: "q12" },
            { maxY: 0.546, minY: 0.447, name: "q13" },
            { maxY: 0.651, minY: 0.546, name: "q21" },
            { maxY: 0.71, minY: 0.651, name: "q22" },
            { maxY: 0.788, minY: 0.71, name: "q31" },
            { maxY: 0.797, minY: 0.788, name: "q32" },
            { maxY: 0.839, minY: 0.797, name: "q33" },
            { maxY: 1, minY: 0.839, name: "q34" }
        ],
        [
            { maxY: 0.038, minY: 0, name: "p15" },
            { maxY: 0.068, minY: 0.038, name: "p14" },
            { maxY: 0.148, minY: 0.068, name: "p13" },
            { maxY: 0.194, minY: 0.148, name: "p12" },
            { maxY: 0.309, minY: 0.194, name: "p11.2" },
            { maxY: 0.322, minY: 0.309, name: "p11.1" },
            { maxY: 0.342, minY: 0.322, name: "q11.1" },
            { maxY: 0.389, minY: 0.342, name: "q11.2" },
            { maxY: 0.505, minY: 0.389, name: "q21" },
            { maxY: 0.611, minY: 0.505, name: "q22" },
            { maxY: 0.694, minY: 0.611, name: "q23" },
            { maxY: 0.776, minY: 0.694, name: "q24" },
            { maxY: 0.858, minY: 0.776, name: "q25" },
            { maxY: 1, minY: 0.858, name: "q26" }
        ],
        [
            { maxY: 0.186, minY: 0, name: "p15" },
            { maxY: 0.253, minY: 0.186, name: "p14" },
            { maxY: 0.272, minY: 0.253, name: "p13" },
            { maxY: 0.315, minY: 0.272, name: "p12" },
            { maxY: 0.38, minY: 0.315, name: "p11.2" },
            { maxY: 0.403, minY: 0.38, name: "p11.1" },
            { maxY: 0.428, minY: 0.403, name: "q11" },
            { maxY: 0.482, minY: 0.428, name: "q12" },
            { maxY: 0.595, minY: 0.482, name: "q13" },
            { maxY: 0.679, minY: 0.595, name: "q14" },
            { maxY: 0.689, minY: 0.679, name: "q21" },
            { maxY: 0.774, minY: 0.689, name: "q22" },
            { maxY: 0.927, minY: 0.774, name: "q23" },
            { maxY: 0.958, minY: 0.927, name: "q24" },
            { maxY: 1, minY: 0.958, name: "q25" }
        ],
        [
            { maxY: 0.108, minY: 0, name: "p13" },
            { maxY: 0.199, minY: 0.108, name: "p12" },
            { maxY: 0.242, minY: 0.199, name: "p11.2" },
            { maxY: 0.262, minY: 0.242, name: "p11.1" },
            { maxY: 0.283, minY: 0.262, name: "q11" },
            { maxY: 0.325, minY: 0.283, name: "q12" },
            { maxY: 0.443, minY: 0.325, name: "q13" },
            { maxY: 0.512, minY: 0.443, name: "q14" },
            { maxY: 0.545, minY: 0.512, name: "q15" },
            { maxY: 0.657, minY: 0.545, name: "q21" },
            { maxY: 0.687, minY: 0.657, name: "q22" },
            { maxY: 0.732, minY: 0.687, name: "q23" },
            { maxY: 0.876, minY: 0.732, name: "q24.1" },
            { maxY: 0.917, minY: 0.876, name: "q24.2" },
            { maxY: 1, minY: 0.917, name: "q24.3" }
        ],
        [
            { maxY: 0.051, minY: 0, name: "p13" },
            { maxY: 0.074, minY: 0.051, name: "p12" },
            { maxY: 0.13, minY: 0.074, name: "p11.2" },
            { maxY: 0.15, minY: 0.13, name: "p11.1" },
            { maxY: 0.176, minY: 0.15, name: "q11" },
            { maxY: 0.32, minY: 0.176, name: "q12" },
            { maxY: 0.376, minY: 0.32, name: "q13" },
            { maxY: 0.494, minY: 0.376, name: "q14" },
            { maxY: 0.615, minY: 0.494, name: "q21" },
            { maxY: 0.673, minY: 0.615, name: "q22" },
            { maxY: 0.784, minY: 0.673, name: "q31" },
            { maxY: 0.882, minY: 0.784, name: "q32" },
            { maxY: 0.935, minY: 0.882, name: "q33" },
            { maxY: 1, minY: 0.935, name: "q34" }
        ],
        [
            { maxY: 0.053, minY: 0, name: "p13" },
            { maxY: 0.081, minY: 0.053, name: "p12" },
            { maxY: 0.136, minY: 0.081, name: "p11.2" },
            { maxY: 0.16, minY: 0.136, name: "p11.1" },
            { maxY: 0.182, minY: 0.16, name: "q11.1" },
            { maxY: 0.256, minY: 0.182, name: "q11.2" },
            { maxY: 0.299, minY: 0.256, name: "q12" },
            { maxY: 0.313, minY: 0.299, name: "q13" },
            { maxY: 0.419, minY: 0.313, name: "q21" },
            { maxY: 0.478, minY: 0.419, name: "q22" },
            { maxY: 0.522, minY: 0.478, name: "q23" },
            { maxY: 0.761, minY: 0.522, name: "q24" },
            { maxY: 0.84, minY: 0.761, name: "q31" },
            { maxY: 1, minY: 0.84, name: "q32" }
        ],
        [
            { maxY: 0.053, minY: 0, name: "p13" },
            { maxY: 0.084, minY: 0.0537, name: "p12" },
            { maxY: 0.142, minY: 0.0847, name: "p11.2" },
            { maxY: 0.168, minY: 0.1427, name: "p11.1" },
            { maxY: 0.19, minY: 0.1687, name: "q11.1" },
            { maxY: 0.284, minY: 0.197, name: "q11.2" },
            { maxY: 0.35, minY: 0.2847, name: "q12-14" },
            { maxY: 0.404, minY: 0.357, name: "q15" },
            { maxY: 0.523, minY: 0.4047, name: "q21" },
            { maxY: 0.637, minY: 0.5237, name: "q22" },
            { maxY: 0.657, minY: 0.6377, name: "q23" },
            { maxY: 0.802, minY: 0.6577, name: "q24" },
            { maxY: 0.863, minY: 0.8027, name: "q25" },
            { maxY: 0.934, minY: 0.8637, name: "q26.1" },
            { maxY: 0.967, minY: 0.9347, name: "q26.2" },
            { maxY: 1, minY: 0.967, name: "q26.3" }
        ],
        [
            { maxY: 0.099, minY: 0, name: "p13.3" },
            { maxY: 0.156, minY: 0.099, name: "p13.2" },
            { maxY: 0.225, minY: 0.156, name: "p13.1" },
            { maxY: 0.293, minY: 0.225, name: "p12" },
            { maxY: 0.386, minY: 0.293, name: "p11.2" },
            { maxY: 0.414, minY: 0.386, name: "p11.1" },
            { maxY: 0.446, minY: 0.414, name: "q11.1" },
            { maxY: 0.532, minY: 0.446, name: "q11.2" },
            { maxY: 0.641, minY: 0.532, name: "q12-13" },
            { maxY: 0.726, minY: 0.641, name: "q21" },
            { maxY: 0.781, minY: 0.726, name: "q22" },
            { maxY: 0.871, minY: 0.781, name: "q23" },
            { maxY: 1, minY: 0.871, name: "q24" }
        ],
        [
            { maxY: 0.166, minY: 0, name: "p13" },
            { maxY: 0.234, minY: 0.166, name: "p12" },
            { maxY: 0.308, minY: 0.234, name: "p11.2" },
            { maxY: 0.34, minY: 0.308, name: "p11.1" },
            { maxY: 0.371, minY: 0.34, name: "q11.1" },
            { maxY: 0.42, minY: 0.371, name: "q11.2" },
            { maxY: 0.483, minY: 0.42, name: "q12" },
            { maxY: 0.688, minY: 0.483, name: "q21" },
            { maxY: 0.791, minY: 0.688, name: "q22" },
            { maxY: 0.8, minY: 0.791, name: "q23" },
            { maxY: 0.897, minY: 0.8, name: "q24" },
            { maxY: 1, minY: 0.897, name: "q25" },
        ],
        [
            { maxY: 0.056, minY: 0, name: "p11.3" },
            { maxY: 0.235, minY: 0.056, name: "p11.2" },
            { maxY: 0.266, minY: 0.235, name: "p11.1" },
            { maxY: 0.298, minY: 0.266, name: "q11.1" },
            { maxY: 0.348, minY: 0.298, name: "q11.2" },
            { maxY: 0.48, minY: 0.348, name: "q12" },
            { maxY: 0.727, minY: 0.48, name: "q21.1" },
            { maxY: 0.78, minY: 0.727, name: "q21.2" },
            { maxY: 0.79, minY: 0.78, name: "q21.3" },
            { maxY: 0.903, minY: 0.79, name: "q22" },
            { maxY: 1, minY: 0.903, name: "q23" }
        ],
        [
            { maxY: 0.178, minY: 0, name: "p13.3" },
            { maxY: 0.24, minY: 0.178, name: "p13.2" },
            { maxY: 0.374, minY: 0.24, name: "p13.1" },
            { maxY: 0.425, minY: 0.374, name: "p12" },
            { maxY: 0.454, minY: 0.425, name: "p11" },
            { maxY: 0.491, minY: 0.454, name: "q11" },
            { maxY: 0.545, minY: 0.491, name: "q12" },
            { maxY: 0.691, minY: 0.545, name: "q13.1" },
            { maxY: 0.753, minY: 0.691, name: "q13.2" },
            { maxY: 0.934, minY: 0.753, name: "q13.3" },
            { maxY: 1, minY: 0.934, name: "q13.4" }
        ],
        [
            { maxY: 0.097, minY: 0, name: "p13" },
            { maxY: 0.242, minY: 0.097, name: "p12" },
            { maxY: 0.407, minY: 0.242, name: "p11.2" },
            { maxY: 0.444, minY: 0.407, name: "p11.1" },
            { maxY: 0.485, minY: 0.444, name: "q11.1" },
            { maxY: 0.657, minY: 0.485, name: "q11.2" },
            { maxY: 0.698, minY: 0.657, name: "q12" },
            { maxY: 0.798, minY: 0.698, name: "q13.1" },
            { maxY: 0.888, minY: 0.798, name: "q13.2" },
            { maxY: 1, minY: 0.888, name: "q13.3" }
        ],
        [
            { maxY: 0.11, minY: 0, name: "p13" },
            { maxY: 0.154, minY: 0.11, name: "p12" },
            { maxY: 0.279, minY: 0.154, name: "p11.2" },
            { maxY: 0.317, minY: 0.279, name: "p11.1" },
            { maxY: 0.36, minY: 0.317, name: "q11.1" },
            { maxY: 0.423, minY: 0.36, name: "q11.2" },
            { maxY: 0.61, minY: 0.423, name: "q21" },
            { maxY: 1, minY: 0.61, name: "q22" }
        ],
        [
            { maxY: 0.097, minY: 0, name: "p13" },
            { maxY: 0.15, minY: 0.097, name: "p12" },
            { maxY: 0.261, minY: 0.15, name: "p11.2" },
            { maxY: 0.296, minY: 0.261, name: "p11.1" },
            { maxY: 0.341, minY: 0.296, name: "q11.1" },
            { maxY: 0.535, minY: 0.341, name: "q11.2" },
            { maxY: 0.642, minY: 0.535, name: "q12" },
            { maxY: 1, minY: 0.642, name: "q13" }
        ],
        [
            { maxY: 0.024, minY: 0, name: "p22.3" },
            { maxY: 0.053, minY: 0.024, name: "p22.2" },
            { maxY: 0.134, minY: 0.053, name: "p22.1" },
            { maxY: 0.216, minY: 0.134, name: "p21" },
            { maxY: 0.241, minY: 0.216, name: "p11.4" },
            { maxY: 0.27, minY: 0.241, name: "p11.3" },
            { maxY: 0.345, minY: 0.27, name: "p11.2" },
            { maxY: 0.359, minY: 0.345, name: "p11.1" },
            { maxY: 0.374, minY: 0.359, name: "q11" },
            { maxY: 0.393, minY: 0.374, name: "q12" },
            { maxY: 0.489, minY: 0.393, name: "q13" },
            { maxY: 0.58, minY: 0.489, name: "q21" },
            { maxY: 0.677, minY: 0.58, name: "q22" },
            { maxY: 0.704, minY: 0.677, name: "q23" },
            { maxY: 0.8, minY: 0.704, name: "q24" },
            { maxY: 0.839, minY: 0.8, name: "q25" },
            { maxY: 0.902, minY: 0.839, name: "q26" },
            { maxY: 0.954, minY: 0.902, name: "q27" },
            { maxY: 1, minY: 0.954, name: "q28" }
        ],
        [
            { maxY: 0.064, minY: 0, name: "p11.3" },
            { maxY: 0.291, minY: 0.064, name: "p11.2" },
            { maxY: 0.332, minY: 0.291, name: "p11.1" },
            { maxY: 0.377, minY: 0.332, name: "q11.1" },
            { maxY: 0.404, minY: 0.377, name: "q11.21" },
            { maxY: 0.486, minY: 0.404, name: "q11.22" },
            { maxY: 0.695, minY: 0.486, name: "q11.23" },
            { maxY: 1, minY: 0.695, name: "q12" }
        ]
    ],
    [
        [
            { maxY: 0.016, minY: 0, name: "p36.3" },
            { maxY: 0.033, minY: 0.016, name: "p36.2" },
            { maxY: 0.067, minY: 0.033, name: "p36.1" },
            { maxY: 0.085, minY: 0.067, name: "p35" },
            { maxY: 0.104, minY: 0.085, name: "p34.3" },
            { maxY: 0.121, minY: 0.104, name: "p34.2" },
            { maxY: 0.152, minY: 0.121, name: "p34.1" },
            { maxY: 0.17, minY: 0.152, name: "p33" },
            { maxY: 0.186, minY: 0.17, name: "p32.3" },
            { maxY: 0.205, minY: 0.186, name: "p32.2" },
            { maxY: 0.226, minY: 0.205, name: "p32.1" },
            { maxY: 0.256, minY: 0.226, name: "p31.3" },
            { maxY: 0.264, minY: 0.256, name: "p31.2" },
            { maxY: 0.294, minY: 0.264, name: "p31.1" },
            { maxY: 0.314, minY: 0.294, name: "p22.3" },
            { maxY: 0.327, minY: 0.314, name: "p22.2" },
            { maxY: 0.359, minY: 0.327, name: "p22.1" },
            { maxY: 0.399, minY: 0.359, name: "p21" },
            { maxY: 0.426, minY: 0.399, name: "p13.3" },
            { maxY: 0.442, minY: 0.426, name: "p13.2" },
            { maxY: 0.454, minY: 0.442, name: "p13.1" },
            { maxY: 0.467, minY: 0.454, name: "p12" },
            { maxY: 0.482, minY: 0.467, name: "p11" },
            { maxY: 0.489, minY: 0.482, name: "q11" },
            { maxY: 0.58, minY: 0.489, name: "q12" },
            { maxY: 0.616, minY: 0.58, name: "q21" },
            { maxY: 0.632, minY: 0.616, name: "q22" },
            { maxY: 0.665, minY: 0.632, name: "q23.1" },
            { maxY: 0.681, minY: 0.665, name: "q23.2" },
            { maxY: 0.684, minY: 0.681, name: "q23.3" },
            { maxY: 0.706, minY: 0.684, name: "q24" },
            { maxY: 0.711, minY: 0.706, name: "q25.1" },
            { maxY: 0.724, minY: 0.711, name: "q25.2" },
            { maxY: 0.769, minY: 0.724, name: "q25.3" },
            { maxY: 0.815, minY: 0.769, name: "q31" },
            { maxY: 0.844, minY: 0.815, name: "q32.1" },
            { maxY: 0.857, minY: 0.844, name: "q32.2" },
            { maxY: 0.863, minY: 0.857, name: "q32.3" },
            { maxY: 0.9, minY: 0.863, name: "q41" },
            { maxY: 0.944, minY: 0.9, name: "q42.1" },
            { maxY: 0.957, minY: 0.944, name: "q42.2" },
            { maxY: 0.963, minY: 0.957, name: "q42.3" },
            { maxY: 0.982, minY: 0.963, name: "q43" },
            { maxY: 1, minY: 0.982, name: "q44" }
        ],
        [
            { maxY: 0.011, minY: 0, name: "p25.3" },
            { maxY: 0.018, minY: 0.011, name: "p25.2" },
            { maxY: 0.046, minY: 0.018, name: "p25.1" },
            { maxY: 0.076, minY: 0.046, name: "p24" },
            { maxY: 0.116, minY: 0.076, name: "p23" },
            { maxY: 0.151, minY: 0.116, name: "p22" },
            { maxY: 0.186, minY: 0.151, name: "p21" },
            { maxY: 0.225, minY: 0.186, name: "p16" },
            { maxY: 0.242, minY: 0.225, name: "p15" },
            { maxY: 0.261, minY: 0.242, name: "p14" },
            { maxY: 0.32, minY: 0.261, name: "p13" },
            { maxY: 0.352, minY: 0.32, name: "p12" },
            { maxY: 0.379, minY: 0.352, name: "p11.2" },
            { maxY: 0.39, minY: 0.379, name: "p11.1" },
            { maxY: 0.399, minY: 0.39, name: "q11.1" },
            { maxY: 0.426, minY: 0.399, name: "q11.2" },
            { maxY: 0.446, minY: 0.426, name: "q12" },
            { maxY: 0.467, minY: 0.446, name: "q13" },
            { maxY: 0.482, minY: 0.467, name: "q14.1" },
            { maxY: 0.493, minY: 0.482, name: "q14.2" },
            { maxY: 0.509, minY: 0.493, name: "q14.3" },
            { maxY: 0.546, minY: 0.509, name: "q21.1" },
            { maxY: 0.554, minY: 0.546, name: "q21.2" },
            { maxY: 0.576, minY: 0.554, name: "q21.3" },
            { maxY: 0.613, minY: 0.576, name: "q22" },
            { maxY: 0.634, minY: 0.613, name: "q23" },
            { maxY: 0.659, minY: 0.634, name: "q24.1" },
            { maxY: 0.664, minY: 0.659, name: "q24.2" },
            { maxY: 0.693, minY: 0.664, name: "q24.3" },
            { maxY: 0.758, minY: 0.693, name: "q31" },
            { maxY: 0.798, minY: 0.758, name: "q32.1" },
            { maxY: 0.802, minY: 0.798, name: "q32.2" },
            { maxY: 0.826, minY: 0.802, name: "q32.3" },
            { maxY: 0.88, minY: 0.826, name: "q33" },
            { maxY: 0.9, minY: 0.88, name: "q34" },
            { maxY: 0.926, minY: 0.9, name: "q35" },
            { maxY: 0.948, minY: 0.926, name: "q36" },
            { maxY: 0.97, minY: 0.948, name: "q37.1" },
            { maxY: 0.98, minY: 0.97, name: "q37.2" },
            { maxY: 1, minY: 0.98, name: "q37.3" }
        ],
        [
            { maxY: 0.032, minY: 0, name: "p26" },
            { maxY: 0.073, minY: 0.032, name: "p25" },
            { maxY: 0.096, minY: 0.073, name: "p24.3" },
            { maxY: 0.108, minY: 0.096, name: "p24.2" },
            { maxY: 0.125, minY: 0.108, name: "p24.1" },
            { maxY: 0.143, minY: 0.125, name: "p23" },
            { maxY: 0.161, minY: 0.143, name: "p22" },
            { maxY: 0.261, minY: 0.161, name: "p21.3" },
            { maxY: 0.274, minY: 0.261, name: "p21.2" },
            { maxY: 0.296, minY: 0.274, name: "p21.1" },
            { maxY: 0.319, minY: 0.296, name: "p14.3" },
            { maxY: 0.323, minY: 0.319, name: "p14.2" },
            { maxY: 0.343, minY: 0.323, name: "p14.1" },
            { maxY: 0.393, minY: 0.343, name: "p13" },
            { maxY: 0.436, minY: 0.393, name: "p12" },
            { maxY: 0.448, minY: 0.436, name: "p11.2" },
            { maxY: 0.46, minY: 0.448, name: "p11.1" },
            { maxY: 0.472, minY: 0.46, name: "q11.1" },
            { maxY: 0.494, minY: 0.472, name: "q11.2" },
            { maxY: 0.497, minY: 0.494, name: "q12" },
            { maxY: 0.542, minY: 0.497, name: "q13.1" },
            { maxY: 0.56, minY: 0.542, name: "q13.2" },
            { maxY: 0.602, minY: 0.56, name: "q13.3" },
            { maxY: 0.676, minY: 0.602, name: "q21" },
            { maxY: 0.71, minY: 0.676, name: "q22" },
            { maxY: 0.735, minY: 0.71, name: "q23" },
            { maxY: 0.76, minY: 0.735, name: "q24" },
            { maxY: 0.763, minY: 0.76, name: "q25.1" },
            { maxY: 0.779, minY: 0.763, name: "q25.2" },
            { maxY: 0.82, minY: 0.779, name: "q25.3" },
            { maxY: 0.847, minY: 0.82, name: "q26.1" },
            { maxY: 0.869, minY: 0.847, name: "q26.2" },
            { maxY: 0.897, minY: 0.869, name: "q26.3" },
            { maxY: 0.946, minY: 0.897, name: "q27" },
            { maxY: 0.965, minY: 0.946, name: "q28" },
            { maxY: 1, minY: 0.965, name: "q29" }
        ],
        [
            { maxY: 0.014, minY: 0, name: "p16.3" },
            { maxY: 0.027, minY: 0.014, name: "p16.2" },
            { maxY: 0.075, minY: 0.027, name: "p16.1" },
            { maxY: 0.111, minY: 0.075, name: "p15.3" },
            { maxY: 0.135, minY: 0.111, name: "p15.2" },
            { maxY: 0.179, minY: 0.135, name: "p15.1" },
            { maxY: 0.218, minY: 0.179, name: "p14" },
            { maxY: 0.248, minY: 0.218, name: "p13" },
            { maxY: 0.254, minY: 0.248, name: "p12" },
            { maxY: 0.269, minY: 0.254, name: "p11" },
            { maxY: 0.284, minY: 0.269, name: "q11" },
            { maxY: 0.319, minY: 0.284, name: "q12" },
            { maxY: 0.356, minY: 0.319, name: "q13.1" },
            { maxY: 0.36, minY: 0.356, name: "q13.2" },
            { maxY: 0.387, minY: 0.36, name: "q13.3" },
            { maxY: 0.42, minY: 0.387, name: "q21.1" },
            { maxY: 0.446, minY: 0.42, name: "q21.2" },
            { maxY: 0.472, minY: 0.446, name: "q21.3" },
            { maxY: 0.516, minY: 0.472, name: "q22" },
            { maxY: 0.544, minY: 0.516, name: "q23" },
            { maxY: 0.562, minY: 0.544, name: "q24" },
            { maxY: 0.59, minY: 0.562, name: "q25" },
            { maxY: 0.64, minY: 0.59, name: "q26" },
            { maxY: 0.668, minY: 0.64, name: "q27" },
            { maxY: 0.732, minY: 0.668, name: "q28" },
            { maxY: 0.767, minY: 0.732, name: "q31.1" },
            { maxY: 0.786, minY: 0.767, name: "q31.2" },
            { maxY: 0.819, minY: 0.786, name: "q31.3" },
            { maxY: 0.869, minY: 0.819, name: "q32" },
            { maxY: 0.914, minY: 0.869, name: "q33" },
            { maxY: 0.956, minY: 0.914, name: "q34" },
            { maxY: 1, minY: 0.956, name: "q35" }
        ],
        [
            { maxY: 0.053, minY: 0, name: "p15.3" },
            { maxY: 0.076, minY: 0.053, name: "p15.2" },
            { maxY: 0.104, minY: 0.076, name: "p15.1" },
            { maxY: 0.175, minY: 0.104, name: "p14" },
            { maxY: 0.208, minY: 0.175, name: "p13.3" },
            { maxY: 0.218, minY: 0.208, name: "p13.2" },
            { maxY: 0.231, minY: 0.218, name: "p13.1" },
            { maxY: 0.249, minY: 0.231, name: "p12" },
            { maxY: 0.264, minY: 0.249, name: "p11" },
            { maxY: 0.276, minY: 0.264, name: "q11.1" },
            { maxY: 0.318, minY: 0.276, name: "q11.2" },
            { maxY: 0.354, minY: 0.318, name: "q12" },
            { maxY: 0.384, minY: 0.354, name: "q13.1" },
            { maxY: 0.402, minY: 0.384, name: "q13.2" },
            { maxY: 0.434, minY: 0.402, name: "q13.3" },
            { maxY: 0.497, minY: 0.434, name: "q14" },
            { maxY: 0.534, minY: 0.497, name: "q15" },
            { maxY: 0.594, minY: 0.534, name: "q21" },
            { maxY: 0.629, minY: 0.594, name: "q22" },
            { maxY: 0.659, minY: 0.629, name: "q23.1" },
            { maxY: 0.665, minY: 0.659, name: "q23.2" },
            { maxY: 0.696, minY: 0.665, name: "q23.3" },
            { maxY: 0.764, minY: 0.696, name: "q31.1" },
            { maxY: 0.775, minY: 0.764, name: "q31.2" },
            { maxY: 0.809, minY: 0.775, name: "q31.3" },
            { maxY: 0.842, minY: 0.809, name: "q32" },
            { maxY: 0.858, minY: 0.842, name: "q33.1" },
            { maxY: 0.871, minY: 0.858, name: "q33.2" },
            { maxY: 0.904, minY: 0.871, name: "q33.3" },
            { maxY: 0.936, minY: 0.904, name: "q34" },
            { maxY: 0.951, minY: 0.936, name: "q35.1" },
            { maxY: 0.964, minY: 0.951, name: "q35.2" },
            { maxY: 1, minY: 0.964, name: "q35.3" }
        ],
        [
            { maxY: 0.039, minY: 0, name: "p26" },
            { maxY: 0.057, minY: 0.039, name: "p24" },
            { maxY: 0.087, minY: 0.057, name: "p23" },
            { maxY: 0.122, minY: 0.087, name: "p22.3" },
            { maxY: 0.127, minY: 0.122, name: "p22.2" },
            { maxY: 0.149, minY: 0.127, name: "p22.1" },
            { maxY: 0.211, minY: 0.149, name: "p21.3" },
            { maxY: 0.225, minY: 0.211, name: "p21.2" },
            { maxY: 0.276, minY: 0.225, name: "p21.1" },
            { maxY: 0.325, minY: 0.276, name: "p12" },
            { maxY: 0.33, minY: 0.325, name: "p11.2" },
            { maxY: 0.342, minY: 0.33, name: "p11.1" },
            { maxY: 0.353, minY: 0.342, name: "q11" },
            { maxY: 0.404, minY: 0.353, name: "q12" },
            { maxY: 0.453, minY: 0.404, name: "q13" },
            { maxY: 0.497, minY: 0.453, name: "q14" },
            { maxY: 0.526, minY: 0.497, name: "q15" },
            { maxY: 0.578, minY: 0.526, name: "q16.1" },
            { maxY: 0.588, minY: 0.578, name: "q16.2" },
            { maxY: 0.613, minY: 0.588, name: "q16.3" },
            { maxY: 0.673, minY: 0.613, name: "q21" },
            { maxY: 0.706, minY: 0.673, name: "q22.1" },
            { maxY: 0.716, minY: 0.706, name: "q22.2" },
            { maxY: 0.756, minY: 0.716, name: "q22.3" },
            { maxY: 0.766, minY: 0.756, name: "q23.1" },
            { maxY: 0.779, minY: 0.766, name: "q23.2" },
            { maxY: 0.822, minY: 0.779, name: "q23.3" },
            { maxY: 0.858, minY: 0.822, name: "q24" },
            { maxY: 0.897, minY: 0.858, name: "q25.1" },
            { maxY: 0.917, minY: 0.897, name: "q25.2" },
            { maxY: 0.946, minY: 0.917, name: "q25.3" },
            { maxY: 0.97, minY: 0.946, name: "q26" },
            { maxY: 1, minY: 0.97, name: "q27" }
        ],
        [
            { maxY: 0.031, minY: 0, name: "p22" },
            { maxY: 0.073, minY: 0.031, name: "p21" },
            { maxY: 0.109, minY: 0.073, name: "p15.3" },
            { maxY: 0.119, minY: 0.109, name: "p15.2" },
            { maxY: 0.189, minY: 0.119, name: "p15.1" },
            { maxY: 0.229, minY: 0.189, name: "p14" },
            { maxY: 0.294, minY: 0.229, name: "p13" },
            { maxY: 0.324, minY: 0.294, name: "p12" },
            { maxY: 0.336, minY: 0.324, name: "p11.2" },
            { maxY: 0.351, minY: 0.336, name: "p11.1" },
            { maxY: 0.363, minY: 0.351, name: "q11.1" },
            { maxY: 0.4, minY: 0.363, name: "q11.21" },
            { maxY: 0.431, minY: 0.4, name: "q11.22" },
            { maxY: 0.488, minY: 0.431, name: "q11.23" },
            { maxY: 0.543, minY: 0.488, name: "q21.1" },
            { maxY: 0.559, minY: 0.543, name: "q21.2" },
            { maxY: 0.59, minY: 0.559, name: "q21.3" },
            { maxY: 0.71, minY: 0.59, name: "q22" },
            { maxY: 0.732, minY: 0.71, name: "q31.1" },
            { maxY: 0.743, minY: 0.732, name: "q31.2" },
            { maxY: 0.808, minY: 0.743, name: "q31.3" },
            { maxY: 0.87, minY: 0.808, name: "q32" },
            { maxY: 0.89, minY: 0.87, name: "q33" },
            { maxY: 0.906, minY: 0.89, name: "q34" },
            { maxY: 0.936, minY: 0.906, name: "q35" },
            { maxY: 1, minY: 0.936, name: "q36" }
        ],
        [
            { maxY: 0.009, minY: 0, name: "p23.3" },
            { maxY: 0.031, minY: 0.009, name: "p23.2" },
            { maxY: 0.074, minY: 0.031, name: "p23.1" },
            { maxY: 0.11, minY: 0.074, name: "p22" },
            { maxY: 0.165, minY: 0.11, name: "p21.3" },
            { maxY: 0.185, minY: 0.165, name: "p21.2" },
            { maxY: 0.217, minY: 0.185, name: "p21.1" },
            { maxY: 0.255, minY: 0.217, name: "p12" },
            { maxY: 0.29, minY: 0.255, name: "p11.2" },
            { maxY: 0.306, minY: 0.29, name: "p11.1" },
            { maxY: 0.319, minY: 0.306, name: "q11.1" },
            { maxY: 0.332, minY: 0.319, name: "q11.21" },
            { maxY: 0.36, minY: 0.332, name: "q11.22" },
            { maxY: 0.395, minY: 0.36, name: "q11.23" },
            { maxY: 0.431, minY: 0.395, name: "q12" },
            { maxY: 0.5, minY: 0.431, name: "q13" },
            { maxY: 0.563, minY: 0.5, name: "q21.1" },
            { maxY: 0.587, minY: 0.563, name: "q21.2" },
            { maxY: 0.641, minY: 0.587, name: "q21.3" },
            { maxY: 0.67, minY: 0.641, name: "q22.1" },
            { maxY: 0.694, minY: 0.67, name: "q22.2" },
            { maxY: 0.75, minY: 0.694, name: "q22.3" },
            { maxY: 0.812, minY: 0.75, name: "q23" },
            { maxY: 0.895, minY: 0.812, name: "q24.1" },
            { maxY: 0.913, minY: 0.895, name: "q24.21" },
            { maxY: 0.926, minY: 0.913, name: "q24.22" },
            { maxY: 0.958, minY: 0.926, name: "q24.23" },
            { maxY: 1, minY: 0.958, name: "q24.3" }
        ],
        [
            { maxY: 0.062, minY: 0, name: "p24" },
            { maxY: 0.102, minY: 0.062, name: "p23" },
            { maxY: 0.155, minY: 0.102, name: "p22" },
            { maxY: 0.208, minY: 0.155, name: "p21" },
            { maxY: 0.294, minY: 0.208, name: "p13" },
            { maxY: 0.316, minY: 0.294, name: "p12" },
            { maxY: 0.33, minY: 0.316, name: "p11" },
            { maxY: 0.343, minY: 0.33, name: "q11" },
            { maxY: 0.447, minY: 0.343, name: "q12" },
            { maxY: 0.496, minY: 0.447, name: "q13" },
            { maxY: 0.551, minY: 0.496, name: "q21.1" },
            { maxY: 0.575, minY: 0.551, name: "q21.2" },
            { maxY: 0.626, minY: 0.575, name: "q21.3" },
            { maxY: 0.664, minY: 0.626, name: "q22.1" },
            { maxY: 0.681, minY: 0.664, name: "q22.2" },
            { maxY: 0.735, minY: 0.681, name: "q22.3" },
            { maxY: 0.783, minY: 0.735, name: "q31" },
            { maxY: 0.808, minY: 0.783, name: "q32" },
            { maxY: 0.865, minY: 0.808, name: "q33" },
            { maxY: 0.907, minY: 0.865, name: "q34.1" },
            { maxY: 0.931, minY: 0.907, name: "q34.2" },
            { maxY: 1, minY: 0.931, name: "q34.3" }
        ],
        [
            { maxY: 0.049, minY: 0, name: "p15" },
            { maxY: 0.08, minY: 0.049, name: "p14" },
            { maxY: 0.129, minY: 0.08, name: "p13" },
            { maxY: 0.174, minY: 0.129, name: "p12.3" },
            { maxY: 0.193, minY: 0.174, name: "p12.2" },
            { maxY: 0.222, minY: 0.193, name: "p12.1" },
            { maxY: 0.309, minY: 0.222, name: "p11.2" },
            { maxY: 0.322, minY: 0.309, name: "p11.1" },
            { maxY: 0.339, minY: 0.322, name: "q11.1" },
            { maxY: 0.394, minY: 0.339, name: "q11.2" },
            { maxY: 0.447, minY: 0.394, name: "q21.1" },
            { maxY: 0.468, minY: 0.447, name: "q21.2" },
            { maxY: 0.513, minY: 0.468, name: "q21.3" },
            { maxY: 0.561, minY: 0.513, name: "q22.1" },
            { maxY: 0.58, minY: 0.561, name: "q22.2" },
            { maxY: 0.604, minY: 0.58, name: "q22.3" },
            { maxY: 0.636, minY: 0.604, name: "q23.1" },
            { maxY: 0.661, minY: 0.636, name: "q23.2" },
            { maxY: 0.693, minY: 0.661, name: "q23.3" },
            { maxY: 0.706, minY: 0.693, name: "q24.1" },
            { maxY: 0.722, minY: 0.706, name: "q24.2" },
            { maxY: 0.776, minY: 0.722, name: "q24.3" },
            { maxY: 0.816, minY: 0.776, name: "q25.1" },
            { maxY: 0.83, minY: 0.816, name: "q25.2" },
            { maxY: 0.854, minY: 0.83, name: "q25.3" },
            { maxY: 0.928, minY: 0.854, name: "q26.1" },
            { maxY: 0.949, minY: 0.928, name: "q26.2" },
            { maxY: 1, minY: 0.949, name: "q26.3" }
        ],
        [
            { maxY: 0.044, minY: 0, name: "p15.5" },
            { maxY: 0.063, minY: 0.044, name: "p15.4" },
            { maxY: 0.093, minY: 0.063, name: "p15.3" },
            { maxY: 0.108, minY: 0.093, name: "p15.2" },
            { maxY: 0.166, minY: 0.108, name: "p15.1" },
            { maxY: 0.213, minY: 0.166, name: "p14" },
            { maxY: 0.272, minY: 0.213, name: "p13" },
            { maxY: 0.301, minY: 0.272, name: "p12" },
            { maxY: 0.362, minY: 0.301, name: "p11.2" },
            { maxY: 0.383, minY: 0.362, name: "p11.12" },
            { maxY: 0.4, minY: 0.383, name: "p11.11" },
            { maxY: 0.415, minY: 0.4, name: "q11" },
            { maxY: 0.448, minY: 0.415, name: "q12" },
            { maxY: 0.526, minY: 0.448, name: "q13.1" },
            { maxY: 0.541, minY: 0.526, name: "q13.2" },
            { maxY: 0.617, minY: 0.541, name: "q13.3" },
            { maxY: 0.632, minY: 0.617, name: "q13.4" },
            { maxY: 0.65, minY: 0.632, name: "q13.5" },
            { maxY: 0.688, minY: 0.65, name: "q14.1" },
            { maxY: 0.697, minY: 0.688, name: "q14.2" },
            { maxY: 0.726, minY: 0.697, name: "q14.3" },
            { maxY: 0.745, minY: 0.726, name: "q21" },
            { maxY: 0.771, minY: 0.745, name: "q22.1" },
            { maxY: 0.777, minY: 0.771, name: "q22.2" },
            { maxY: 0.81, minY: 0.777, name: "q22.3" },
            { maxY: 0.83, minY: 0.81, name: "q23.1" },
            { maxY: 0.846, minY: 0.83, name: "q23.2" },
            { maxY: 0.918, minY: 0.846, name: "q23.3" },
            { maxY: 0.947, minY: 0.918, name: "q24.1" },
            { maxY: 0.975, minY: 0.947, name: "q24.2" },
            { maxY: 0.987, minY: 0.975, name: "q24.3" },
            { maxY: 1, minY: 0.987, name: "q25" }
        ],
        [
            { maxY: 0.074, minY: 0, name: "p13.3" },
            { maxY: 0.086, minY: 0.074, name: "p13.2" },
            { maxY: 0.115, minY: 0.086, name: "p13.1" },
            { maxY: 0.151, minY: 0.115, name: "p12.3" },
            { maxY: 0.156, minY: 0.151, name: "p12.2" },
            { maxY: 0.182, minY: 0.156, name: "p12.1" },
            { maxY: 0.246, minY: 0.182, name: "p11.2" },
            { maxY: 0.264, minY: 0.246, name: "p11.1" },
            { maxY: 0.276, minY: 0.264, name: "q11" },
            { maxY: 0.311, minY: 0.276, name: "q12" },
            { maxY: 0.391, minY: 0.311, name: "q13.1" },
            { maxY: 0.407, minY: 0.391, name: "q13.2" },
            { maxY: 0.446, minY: 0.407, name: "q13.3" },
            { maxY: 0.497, minY: 0.446, name: "q14" },
            { maxY: 0.538, minY: 0.497, name: "q15" },
            { maxY: 0.577, minY: 0.538, name: "q21.1" },
            { maxY: 0.599, minY: 0.577, name: "q21.2" },
            { maxY: 0.654, minY: 0.599, name: "q21.3" },
            { maxY: 0.71, minY: 0.654, name: "q22" },
            { maxY: 0.757, minY: 0.71, name: "q23" },
            { maxY: 0.865, minY: 0.757, name: "q24.1" },
            { maxY: 0.888, minY: 0.865, name: "q24.2" },
            { maxY: 0.943, minY: 0.888, name: "q24.31" },
            { maxY: 0.969, minY: 0.943, name: "q24.32" },
            { maxY: 1, minY: 0.969, name: "q24.33" }
        ],
        [
            { maxY: 0.051, minY: 0, name: "p13" },
            { maxY: 0.076, minY: 0.051, name: "p12" },
            { maxY: 0.142, minY: 0.076, name: "p11.2" },
            { maxY: 0.151, minY: 0.142, name: "p11.1" },
            { maxY: 0.167, minY: 0.151, name: "q11" },
            { maxY: 0.223, minY: 0.167, name: "q12.1" },
            { maxY: 0.239, minY: 0.223, name: "q12.2" },
            { maxY: 0.278, minY: 0.239, name: "q12.3" },
            { maxY: 0.329, minY: 0.278, name: "q13" },
            { maxY: 0.387, minY: 0.329, name: "q14.1" },
            { maxY: 0.406, minY: 0.387, name: "q14.2" },
            { maxY: 0.441, minY: 0.406, name: "q14.3" },
            { maxY: 0.49, minY: 0.441, name: "q21.1" },
            { maxY: 0.515, minY: 0.49, name: "q21.2" },
            { maxY: 0.587, minY: 0.515, name: "q21.3" },
            { maxY: 0.68, minY: 0.587, name: "q22" },
            { maxY: 0.763, minY: 0.68, name: "q31" },
            { maxY: 0.875, minY: 0.763, name: "q32" },
            { maxY: 0.937, minY: 0.875, name: "q33" },
            { maxY: 1, minY: 0.937, name: "q34" }
        ],
        [
            { maxY: 0.053, minY: 0, name: "p13" },
            { maxY: 0.079, minY: 0.053, name: "p12" },
            { maxY: 0.136, minY: 0.079, name: "p11.2" },
            { maxY: 0.153, minY: 0.136, name: "p11.1" },
            { maxY: 0.172, minY: 0.153, name: "q11.1" },
            { maxY: 0.258, minY: 0.172, name: "q11.2" },
            { maxY: 0.311, minY: 0.258, name: "q12" },
            { maxY: 0.354, minY: 0.311, name: "q13" },
            { maxY: 0.433, minY: 0.354, name: "q21" },
            { maxY: 0.538, minY: 0.433, name: "q22" },
            { maxY: 0.579, minY: 0.538, name: "q23" },
            { maxY: 0.622, minY: 0.579, name: "q24.1" },
            { maxY: 0.651, minY: 0.622, name: "q24.2" },
            { maxY: 0.742, minY: 0.651, name: "q24.3" },
            { maxY: 0.811, minY: 0.742, name: "q31" },
            { maxY: 0.888, minY: 0.811, name: "q32.1" },
            { maxY: 0.914, minY: 0.888, name: "q32.2" },
            { maxY: 1, minY: 0.914, name: "q32.3" }
        ],
        [
            { maxY: 0.056, minY: 0, name: "p13" },
            { maxY: 0.079, minY: 0.056, name: "p12" },
            { maxY: 0.137, minY: 0.079, name: "p11.2" },
            { maxY: 0.157, minY: 0.137, name: "p11.1" },
            { maxY: 0.183, minY: 0.157, name: "q11.1" },
            { maxY: 0.246, minY: 0.183, name: "q11.2" },
            { maxY: 0.274, minY: 0.246, name: "q12" },
            { maxY: 0.31, minY: 0.274, name: "q13" },
            { maxY: 0.353, minY: 0.31, name: "q14" },
            { maxY: 0.454, minY: 0.353, name: "q15" },
            { maxY: 0.497, minY: 0.454, name: "q21.1" },
            { maxY: 0.51, minY: 0.497, name: "q21.2" },
            { maxY: 0.553, minY: 0.51, name: "q21.3" },
            { maxY: 0.571, minY: 0.553, name: "q22.1" },
            { maxY: 0.594, minY: 0.571, name: "q22.2" },
            { maxY: 0.663, minY: 0.594, name: "q22.3" },
            { maxY: 0.693, minY: 0.663, name: "q23" },
            { maxY: 0.812, minY: 0.693, name: "q24" },
            { maxY: 0.86, minY: 0.812, name: "q25" },
            { maxY: 0.931, minY: 0.86, name: "q26.1" },
            { maxY: 0.967, minY: 0.931, name: "q26.2" },
            { maxY: 1, minY: 0.967, name: "q26.3" }
        ],
        [
            { maxY: 0.074, minY: 0, name: "p13.3" },
            { maxY: 0.123, minY: 0.074, name: "p13.2" },
            { maxY: 0.213, minY: 0.123, name: "p13.1" },
            { maxY: 0.284, minY: 0.213, name: "p12" },
            { maxY: 0.393, minY: 0.284, name: "p11.2" },
            { maxY: 0.412, minY: 0.393, name: "p11.1" },
            { maxY: 0.437, minY: 0.412, name: "q11.1" },
            { maxY: 0.544, minY: 0.437, name: "q11.2" },
            { maxY: 0.609, minY: 0.544, name: "q12.1" },
            { maxY: 0.634, minY: 0.609, name: "q12.2" },
            { maxY: 0.699, minY: 0.634, name: "q13" },
            { maxY: 0.754, minY: 0.699, name: "q21" },
            { maxY: 0.816, minY: 0.754, name: "q22" },
            { maxY: 0.915, minY: 0.816, name: "q23" },
            { maxY: 1, minY: 0.915, name: "q24" }
        ],
        [
            { maxY: 0.145, minY: 0, name: "p13" },
            { maxY: 0.208, minY: 0.145, name: "p12" },
            { maxY: 0.313, minY: 0.208, name: "p11.2" },
            { maxY: 0.336, minY: 0.313, name: "p11.1" },
            { maxY: 0.364, minY: 0.336, name: "q11.1" },
            { maxY: 0.396, minY: 0.364, name: "q11.2" },
            { maxY: 0.444, minY: 0.396, name: "q12" },
            { maxY: 0.481, minY: 0.444, name: "q21.1" },
            { maxY: 0.504, minY: 0.481, name: "q21.2" },
            { maxY: 0.692, minY: 0.504, name: "q21.3" },
            { maxY: 0.766, minY: 0.692, name: "q22" },
            { maxY: 0.809, minY: 0.766, name: "q23" },
            { maxY: 0.88, minY: 0.809, name: "q24" },
            { maxY: 1, minY: 0.88, name: "q25" }
        ],
        [
            { maxY: 0.022, minY: 0, name: "p11.32" },
            { maxY: 0.079, minY: 0.022, name: "p11.31" },
            { maxY: 0.233, minY: 0.079, name: "p11.2" },
            { maxY: 0.27, minY: 0.233, name: "p11.1" },
            { maxY: 0.296, minY: 0.27, name: "q11.1" },
            { maxY: 0.374, minY: 0.296, name: "q11.2" },
            { maxY: 0.437, minY: 0.374, name: "q12.1" },
            { maxY: 0.468, minY: 0.437, name: "q12.2" },
            { maxY: 0.531, minY: 0.468, name: "q12.3" },
            { maxY: 0.689, minY: 0.531, name: "q21.1" },
            { maxY: 0.739, minY: 0.689, name: "q21.2" },
            { maxY: 0.774, minY: 0.739, name: "q21.3" },
            { maxY: 0.896, minY: 0.774, name: "q22" },
            { maxY: 1, minY: 0.896, name: "q23" }
        ],
        [
            { maxY: 0.159, minY: 0, name: "p13.3" },
            { maxY: 0.25, minY: 0.159, name: "p13.2" },
            { maxY: 0.366, minY: 0.25, name: "p13.1" },
            { maxY: 0.406, minY: 0.366, name: "p12" },
            { maxY: 0.446, minY: 0.406, name: "p11" },
            { maxY: 0.478, minY: 0.446, name: "q11" },
            { maxY: 0.514, minY: 0.478, name: "q12" },
            { maxY: 0.645, minY: 0.514, name: "q13.1" },
            { maxY: 0.721, minY: 0.645, name: "q13.2" },
            { maxY: 0.938, minY: 0.721, name: "q13.3" },
            { maxY: 1, minY: 0.938, name: "q13.4" }
        ],
        [
            { maxY: 0.108, minY: 0, name: "p13" },
            { maxY: 0.208, minY: 0.108, name: "p12" },
            { maxY: 0.405, minY: 0.208, name: "p11.2" },
            { maxY: 0.439, minY: 0.405, name: "p11.1" },
            { maxY: 0.468, minY: 0.439, name: "q11.1" },
            { maxY: 0.617, minY: 0.468, name: "q11.2" },
            { maxY: 0.71, minY: 0.617, name: "q12" },
            { maxY: 0.81, minY: 0.71, name: "q13.1" },
            { maxY: 0.9, minY: 0.81, name: "q13.2" },
            { maxY: 1, minY: 0.9, name: "q13.3" }
        ],
        [
            { maxY: 0.101, minY: 0, name: "p13" },
            { maxY: 0.169, minY: 0.101, name: "p12" },
            { maxY: 0.28, minY: 0.169, name: "p11.2" },
            { maxY: 0.324, minY: 0.28, name: "p11.1" },
            { maxY: 0.362, minY: 0.324, name: "q11.1" },
            { maxY: 0.406, minY: 0.362, name: "q11.2" },
            { maxY: 0.589, minY: 0.406, name: "q21" },
            { maxY: 0.71, minY: 0.589, name: "q22.1" },
            { maxY: 0.797, minY: 0.71, name: "q22.2" },
            { maxY: 1, minY: 0.797, name: "q22.3" }
        ],
        [
            { maxY: 0.097, minY: 0, name: "p13" },
            { maxY: 0.15, minY: 0.097, name: "p12" },
            { maxY: 0.251, minY: 0.15, name: "p11.2" },
            { maxY: 0.286, minY: 0.251, name: "p11.1" },
            { maxY: 0.33, minY: 0.286, name: "q11.1" },
            { maxY: 0.568, minY: 0.33, name: "q11.2" },
            { maxY: 0.599, minY: 0.568, name: "q12.1" },
            { maxY: 0.63, minY: 0.599, name: "q12.2" },
            { maxY: 0.683, minY: 0.63, name: "q12.3" },
            { maxY: 0.806, minY: 0.683, name: "q13.1" },
            { maxY: 0.841, minY: 0.806, name: "q13.2" },
            { maxY: 1, minY: 0.841, name: "q13.3" }
        ],
        [
            { maxY: 0.021, minY: 0, name: "p22.33" },
            { maxY: 0.042, minY: 0.021, name: "p22.32" },
            { maxY: 0.061, minY: 0.042, name: "p22.31" },
            { maxY: 0.078, minY: 0.061, name: "p22.2" },
            { maxY: 0.141, minY: 0.078, name: "p22.1" },
            { maxY: 0.178, minY: 0.141, name: "p21.3" },
            { maxY: 0.184, minY: 0.178, name: "p21.2" },
            { maxY: 0.223, minY: 0.184, name: "p21.1" },
            { maxY: 0.263, minY: 0.223, name: "p11.4" },
            { maxY: 0.282, minY: 0.263, name: "p11.3" },
            { maxY: 0.317, minY: 0.282, name: "p11.23" },
            { maxY: 0.335, minY: 0.317, name: "p11.22" },
            { maxY: 0.346, minY: 0.335, name: "p11.21" },
            { maxY: 0.357, minY: 0.346, name: "p11.1" },
            { maxY: 0.368, minY: 0.357, name: "q11.1" },
            { maxY: 0.377, minY: 0.368, name: "q11.2" },
            { maxY: 0.401, minY: 0.377, name: "q12" },
            { maxY: 0.506, minY: 0.401, name: "q13" },
            { maxY: 0.538, minY: 0.506, name: "q21.1" },
            { maxY: 0.553, minY: 0.538, name: "q21.2" },
            { maxY: 0.623, minY: 0.553, name: "q21.3" },
            { maxY: 0.684, minY: 0.623, name: "q22.1" },
            { maxY: 0.702, minY: 0.684, name: "q22.2" },
            { maxY: 0.736, minY: 0.702, name: "q22.3" },
            { maxY: 0.763, minY: 0.736, name: "q23" },
            { maxY: 0.796, minY: 0.763, name: "q24" },
            { maxY: 0.84, minY: 0.796, name: "q25" },
            { maxY: 0.926, minY: 0.84, name: "q26" },
            { maxY: 0.966, minY: 0.926, name: "q27" },
            { maxY: 1, minY: 0.966, name: "q28" }
        ],
        [
            { maxY: 0.045, minY: 0, name: "p11.32" },
            { maxY: 0.109, minY: 0.045, name: "p11.31" },
            { maxY: 0.286, minY: 0.109, name: "p11.2" },
            { maxY: 0.327, minY: 0.286, name: "p11.1" },
            { maxY: 0.364, minY: 0.327, name: "q11.1" },
            { maxY: 0.404, minY: 0.364, name: "q11.21" },
            { maxY: 0.477, minY: 0.404, name: "q11.221" },
            { maxY: 0.5, minY: 0.477, name: "q11.222" },
            { maxY: 0.577, minY: 0.5, name: "q11.223" },
            { maxY: 0.65, minY: 0.577, name: "q11.23" },
            { maxY: 1, minY: 0.65, name: "q12" }
        ]
    ]
];
