import * as PIXI from "pixi.js";
import ScalePanel from "../Component/ScalePanel";
import { SYSTEM_WINDOW_RESIZE } from "../Event/SystemEventName";
import Toolbar from "../../Component/Toolbar";
import { WindowGloablEvent } from "../../Event/WindowGloablEvent";
export default class PIXICanvas {
    constructor(canvasType, canvasMain, isMainCanvas, container, backgroundColor, callback, failCallback, caller) {
        this._isMainCanvas = false;
        this.xmlns = "http://www.w3.org/2000/svg";
        //private cursorColor = "#008AFF";
        this.cursorColor = "rgba(00, 138, 255, 1)";
        this._canvasType = canvasType;
        this._canvasMain = canvasMain;
        this.backgroundColor = backgroundColor;
        this._isMainCanvas = isMainCanvas;
        this._pixi = new PIXI.Application();
        this.imageMap = new Map();
        this.events = new PIXI.EventEmitter();
        this.parentCanvas = container;
        this._pixi.init({ preference: "webgl", powerPreference: "high-performance", antialias: false, resolution: 2, resizeTo: container, backgroundColor: backgroundColor }).then(() => {
            container.appendChild(this._pixi.canvas);
            console.log("创建");
            // let styleStr = `scale(0.5) translate(${-this._pixi.canvas.width / 2}px, ${-this._pixi.canvas.height / 2}px)`;
            // this._pixi.canvas.style.transform = styleStr;
            this._pixi.canvas.style.width = "100%";
            this._pixi.canvas.style.height = "100%";
            this._pixi.canvas.addEventListener("contextmenu", (e) => { e.preventDefault(); e.stopPropagation(); });
            this._pixi.canvas.addEventListener("mousedown", (() => { this._pixi.ticker.maxFPS = 60; }).bind(this));
            this._pixi.canvas.addEventListener("mouseup", (() => { this._pixi.ticker.maxFPS = 1; }).bind(this));
            this._pixi.canvas.addEventListener("mouseout", ((e) => { this._pixi.ticker.maxFPS = 1; }).bind(this));
            this._pixi.stage.boundsArea = new PIXI.Rectangle(0, 0, this._pixi.screen.width, this._pixi.screen.height);
            this._stage = new ScalePanel(this, backgroundColor, { width: this._pixi.screen.width, height: this._pixi.screen.height });
            this._stage.interactive = true;
            this.stage.interactiveChildren = isMainCanvas;
            this._stage.on("click", () => {
                this.emit("click", null);
            });
            this._pixi.stage.addChild(this._stage);
            this.maskBoxInit(this._pixi.screen.width, this._pixi.screen.height);
            this.onInit(this._pixi.screen.width, this._pixi.screen.height);
            this.addItemBar();
            this.setItemBarShow(isMainCanvas);
            callback === null || callback === void 0 ? void 0 : callback.call(caller);
            this._pixi.ticker.maxFPS = 1;
        }).catch((e) => {
            console.error("PIXICanvas create error: ", e);
            failCallback === null || failCallback === void 0 ? void 0 : failCallback.call(caller, e);
        });
        WindowGloablEvent.getSingleton().addEventListener("resize", (() => {
            setTimeout(() => {
                var _a;
                this.onResize(this._pixi.screen.width, this._pixi.screen.height);
                (_a = this.toolbar) === null || _a === void 0 ? void 0 : _a.onResize();
                this._pixi.stage.boundsArea = new PIXI.Rectangle(0, 0, this._pixi.screen.width, this._pixi.screen.height);
                this.canvasMain.emit(SYSTEM_WINDOW_RESIZE, this);
            }, 100);
        }).bind(this));
    }
    get rootCanvas() { return this.parentCanvas; }
    get stage() { return this._stage; }
    get isMainCanvas() { return this._isMainCanvas; }
    get pixi() { return this._pixi; }
    get canvasMain() { return this._canvasMain; }
    get canvasType() { return this._canvasType; }
    changeResizeTo(container, isMainCanvas) {
        this._isMainCanvas = isMainCanvas;
        container.appendChild(this._pixi.canvas);
        this.parentCanvas = container;
        this._pixi.resizeTo = container;
        this._pixi.resize();
        // let styleStr = `scale(0.5) translate(${-this._pixi.canvas.width / 2}px, ${-this._pixi.canvas.height / 2}px)`;
        // this._pixi.canvas.style.transform = styleStr;
        this._pixi.canvas.style.width = "100%";
        this._pixi.canvas.style.height = "100%";
        this._stage.resize();
        this.setMaskSize(this._pixi.screen.width, this._pixi.screen.height);
        this.onResize(this._pixi.screen.width, this._pixi.screen.height);
        this._pixi.stage.boundsArea = new PIXI.Rectangle(0, 0, this._pixi.screen.width, this._pixi.screen.height);
        this.stage.interactiveChildren = isMainCanvas;
        this.setItemBarShow(false);
        setTimeout((() => {
            var _a;
            (_a = this.toolbar) === null || _a === void 0 ? void 0 : _a.onResize();
            this.setItemBarShow(isMainCanvas);
        }).bind(this), 100);
    }
    /**
     * 加载图片
     * @param urls 图片路径列表
     * @param callback 完成回调，按照路径列表的顺序返图，加载失败的位置为undefined
     */
    loadImg(urls, callback) {
        let imgs = [];
        let count = 0;
        for (let i = 0; i < urls.length; i++) {
            let index = i;
            if (this.imageMap.has(urls[index])) {
                imgs[index] = this.imageMap.get(urls[index]);
                count++;
                if (count == urls.length) {
                    callback(imgs);
                }
                continue;
            }
            PIXI.Assets.load(urls[index]).then((texture) => {
                imgs[index] = texture;
                this.imageMap.set(urls[index], texture);
            }).catch((e) => {
                imgs[index] = undefined;
                console.error("Image load error. src: " + urls[index], e);
            }).finally(() => {
                count++;
                if (count == urls.length) {
                    callback === null || callback === void 0 ? void 0 : callback(imgs);
                }
            });
        }
    }
    /**
     * 销毁图片资源
     * ⚠销毁加载的资源前一定要先销毁使用该资源的显示对象，不然渲染器内部会抛严重异常
     * @param urls 资源路径
     * @param callback 完成回调
     */
    unloadImg(urls, callback) {
        let count = 0;
        for (let i = 0; i < urls.length; i++) {
            let index = i;
            if (!this.imageMap.has(urls[index])) {
                count++;
                if (count == urls.length) {
                    callback === null || callback === void 0 ? void 0 : callback();
                }
                continue;
            }
            let texture = this.imageMap.get(urls[index]);
            PIXI.Assets.unload(urls[index]).then(() => {
                this.imageMap.delete(urls[index]);
                if (!texture.destroyed) {
                    texture.destroy(true);
                }
            }).catch((e) => { console.error("Image unload error. src: " + urls[index], e); }).finally(() => {
                count++;
                if (count == urls.length) {
                    callback === null || callback === void 0 ? void 0 : callback();
                }
            });
        }
    }
    /**
     * 销毁所有图片资源
     * ⚠销毁加载的资源前一定要先销毁使用该资源的显示对象，不然渲染器内部会抛严重异常
     * @param callback 完成回调
     */
    unloadAllImg(callback) {
        let urls = [];
        for (let key of this.imageMap.keys()) {
            urls.push(key);
        }
        this.unloadImg(urls, callback);
    }
    on(event, fn, context) {
        this.events.on(event, fn, context);
    }
    off(event, fn, context, once) {
        this.events.off(event, fn, context, once);
    }
    once(event, fn, context) {
        this.events.once(event, fn, context);
    }
    emit(event, args) {
        this.events.emit(event, args);
    }
    clear() {
        console.log("清除");
        this.unloadAllImg();
        this.events.removeAllListeners();
        for (let i = this._pixi.stage.children.length - 1; i >= 0; i--) {
            let child = this._pixi.stage.removeChildAt(i);
            child.destroy({ children: true, texture: true, textureSource: true, context: true, style: true });
        }
        this.onClear();
        this._stage = new ScalePanel(this, this.backgroundColor, { width: this._pixi.screen.width, height: this._pixi.screen.height });
        this._stage.interactive = true;
        this._stage.on("click", () => { this.emit("click", null); });
        this._pixi.stage.addChild(this._stage);
        this.maskBoxInit(this._pixi.screen.width, this._pixi.screen.height);
        this.onInit(this._pixi.screen.width, this._pixi.screen.height);
        this.addItemBar();
        this.setItemBarShow(this.isMainCanvas);
    }
    destroy() {
        this.unloadAllImg();
        this.events.removeAllListeners();
        this.events = undefined;
        this.imageMap.clear();
        this.imageMap = undefined;
        this._canvasMain = undefined;
        this._stage.destroy({ children: true, texture: true, textureSource: true, context: true, style: true });
        this._stage = undefined;
        this._pixi.destroy({ removeView: true }, { children: true, texture: true, textureSource: true, context: true, style: true });
        this._pixi = undefined;
        this._isMainCanvas = undefined;
        this.onDestroy();
    }
    maskBoxInit(width, height) {
        this.maskBox = new PIXI.Container();
        this._pixi.stage.addChild(this.maskBox);
        this.maskBg = new PIXI.Graphics();
        this.maskText = new PIXI.Text();
        this.maskText.text = "暂无优化图\n请先进行图像处理";
        this.maskText.style.align = "center";
        this.maskText.anchor = new PIXI.Point(0.5, 0.5);
        this.maskBox.addChild(this.maskBg);
        this.maskBox.addChild(this.maskText);
        this.maskBox.visible = false;
        this.maskBox.interactive = true;
        this.setMaskSize(width, height);
    }
    setMaskSize(width, height) {
        this.maskBox.hitArea = new PIXI.Rectangle(0, 0, width, height);
        console.log(this.maskBg);
        this.maskBg.clear();
        this.maskBg.rect(0, 0, width, height);
        this.maskBg.fill(0xffffff);
        this.maskText.style.fontSize = width / 13;
        this.maskText.position = new PIXI.Point(width / 2, height / 2);
    }
    showMask(bo) {
        //this.maskBox.visible = bo;
    }
    addItemBar() {
        this.toolbar = new Toolbar(this);
        this._pixi.stage.addChild(this.toolbar);
    }
    setItemBarShow(value) {
        this.toolbar.visible = value;
    }
    downloadImage(container, fileName) {
        let canvas = this.parentCanvas;
        let isMainCanvas = this.isMainCanvas;
        this.changeResizeTo(container, true);
        this.setMode(CanvasMode.None);
        this.pixi.renderer.extract.download({ target: this.stage, filename: fileName });
        this.changeResizeTo(canvas, isMainCanvas);
    }
    extractToBase64(target) {
        var _a;
        let texture = this.pixi.renderer.extract.texture(target);
        let canvas = this.pixi.renderer.extract.canvas(texture);
        let dataurl = canvas.toDataURL("image/jpeg");
        (_a = canvas.getContext("2d")) === null || _a === void 0 ? void 0 : _a.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = 0;
        canvas.height = 0;
        texture.destroy(true);
        return dataurl;
    }
    getCursorCircle(radius) {
        let diameter = radius * 2;
        let cursor = `url('data:image/svg+xml;utf8,<svg xmlns="${this.xmlns}" width="${diameter}" height="${diameter}" viewBox="0 0 ${diameter} ${diameter}"><circle cx="${radius}" cy="${radius}" r="${radius}" fill="${this.cursorColor}"/></svg>') ${radius} ${radius}, auto`;
        console.log(cursor);
        return cursor;
    }
}
export var CanvasType;
(function (CanvasType) {
    CanvasType[CanvasType["KaryotypeCanvas"] = 0] = "KaryotypeCanvas";
    CanvasType[CanvasType["OriginalImageCanvas"] = 1] = "OriginalImageCanvas";
    CanvasType[CanvasType["EnhancedImageCanvas"] = 2] = "EnhancedImageCanvas";
})(CanvasType || (CanvasType = {}));
export var CanvasMode;
(function (CanvasMode) {
    CanvasMode[CanvasMode["None"] = 0] = "None";
    /** 染色体剪切 */
    CanvasMode[CanvasMode["Segmentation"] = 1] = "Segmentation";
    /** 轮廓修饰 */
    CanvasMode[CanvasMode["OutlineDecorate"] = 2] = "OutlineDecorate";
    /** 箭头编辑 */
    CanvasMode[CanvasMode["ArrowEditor"] = 3] = "ArrowEditor";
    /** 文本框编辑 */
    CanvasMode[CanvasMode["TextEditor"] = 4] = "TextEditor";
    /** 着丝点 */
    CanvasMode[CanvasMode["Centromere"] = 5] = "Centromere";
    /** 染色体计数 */
    CanvasMode[CanvasMode["Count"] = 6] = "Count";
    /** 图形处理 */
    CanvasMode[CanvasMode["ImageProcessing"] = 7] = "ImageProcessing";
    /** 重叠分割 */
    CanvasMode[CanvasMode["OverlappingArea"] = 8] = "OverlappingArea";
})(CanvasMode || (CanvasMode = {}));
