import BoundsData from "./BoundsData";
export default class MainData {
    constructor() {
        this.language = 0;
        this.id = "";
        this.mainImgUrl = "";
        this.enhancedImgUrl = "";
        this.noBGImgUrl = "";
        this.karyotypeImgUrl = "";
        this.karyotypeNoXYImgUrl = "";
        /** 不遮挡轮廓图 */
        this.outlineImgUrl = "";
        /** 遮挡性染色体轮廓图 */
        this.outlineImgUrl1 = "";
        /** 遮挡性染色体且不含轮廓的轮廓图 */
        this.outlineImgUrl2 = "";
        this.singleData = [];
        this.markData = [];
        this.countData = "";
        this.curveData = "";
    }
    static getDataFromJson(json) {
        let data = new MainData();
        data.language = json.language;
        data.id = json.id;
        data.mainImgUrl = json.mainImgUrl;
        data.enhancedImgUrl = json.enhancedImgUrl;
        data.noBGImgUrl = json.noBGImgUrl;
        data.countData = json.countData;
        data.curveData = json.curveData;
        data.markData = json.markData;
        data.karyotypeImgUrl = json.karyotypeImgUrl;
        data.karyotypeNoXYImgUrl = json.karyotypeNoXYImgUrl;
        data.outlineImgUrl = json.outlineImgUrl;
        if (json.singleData) {
            for (let i = 0; i < json.singleData.length; i++) {
                let boundsData = new BoundsData();
                boundsData.copyFrom(json.singleData[i]);
                data.singleData.push(boundsData);
            }
        }
        return data;
    }
}
