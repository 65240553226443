import { Point, Polygon } from "pixi.js";
import OutlineTool from "./OutlineTool";
import UtilMath from "./UtilMath";
export default class OutlineData {
    constructor(pointArr, inverted = false) {
        this.minErrorValue = 0.000001;
        this.pointArr = [];
        this.inverted = false;
        /** 绘制点 */
        this.pointSize = 4;
        this.setData(pointArr, inverted);
    }
    setData(pointArr, inverted = false) {
        this.pointArr = [];
        for (let i = 0; i < pointArr.length; i++) {
            this.pointArr.push([]);
            for (let j = 0; j < pointArr[i].length; j++) {
                let p = pointArr[i][j];
                this.pointArr[i].push(new Point(p.x, p.y));
            }
        }
        this.inverted = inverted;
    }
    getOutlineArray() {
        return this.pointArr;
    }
    setSingleData(index, point) {
        this.pointArr[index] = point;
    }
    getOutlineData(index) {
        return this.pointArr[index];
    }
    get outlineCount() {
        return this.pointArr.length;
    }
    setEditorOutline(index, point, radius, isErase) {
        let editorPoints = this.pointArr[index];
        var points = [];
        for (var angle = 0; angle <= 360; angle += 10) {
            var radian = angle * Math.PI / 180;
            var x = point.x + radius * Math.cos(radian);
            var y = point.y + radius * Math.sin(radian);
            points.push({ x: x, y: y });
        }
        let fun = isErase ? OutlineTool.difference : OutlineTool.union;
        let result = fun([editorPoints], false, [points], false);
        points = result.points[0];
        for (let i = 0; i < result.points.length; i++) {
            if (result.points[i].length > points.length) {
                points = result.points[i];
            }
        }
        this.pointArr[index] = points;
    }
    getOutlineOfPoint(x, y) {
    }
    /** 绘制轮廓 */
    dirwOutline(graphic, color, office = new Point(0, 0), scale = 1, isClear = true) {
        if (isClear) {
            graphic.clear();
        }
        for (let i = 0; i < this.pointArr.length; i++) {
            let points = this.pointArr[i];
            graphic.beginPath();
            graphic.moveTo(points[0].x * scale + office.x, points[0].y * scale + office.y);
            for (let j = 1; j < points.length; j++) {
                graphic.lineTo(points[j].x * scale + office.x, points[j].y * scale + office.y);
            }
            graphic.closePath();
            //graphic.fill(color);
            //graphic.fillStyle.alpha = 80;
            graphic.stroke({ color, width: 1 });
        }
    }
    /** 绘制单个轮廓 */
    dirwSingleOutline(graphic, color, index, office = new Point(0, 0), scale = 1, isFill = false) {
        if (index < 0 || index >= this.pointArr.length) {
            console.error("没有这个轮廓");
            return;
        }
        let points = this.pointArr[index];
        graphic.beginPath();
        graphic.moveTo(points[0].x * scale + office.x, points[0].y * scale + office.y);
        for (let j = 1; j < points.length; j++) {
            graphic.lineTo(points[j].x * scale + office.x, points[j].y * scale + office.y);
        }
        graphic.closePath();
        if (isFill) {
            graphic.fill(color);
        }
        //graphic.fillStyle.alpha = 80;
        graphic.stroke({ color, width: 1 });
    }
    dirwPoint(point, graphic, color, office, scale) {
        graphic.rect(point.x * scale + office.x - this.pointSize / 2, point.y * scale + office.y - this.pointSize / 2, this.pointSize, this.pointSize);
        graphic.fill(color);
    }
    /** 绘制线 */
    dirwLine(line, graphic, color, office, scale) {
        graphic.moveTo(line[0].x * scale + office.x, line[0].y * scale + office.y);
        graphic.lineTo(line[1].x * scale + office.x, line[1].y * scale + office.y);
        graphic.stroke(color);
    }
    /** 绘制角 */
    dirwAngle(angle, graphic, color, office, scale) {
        graphic.moveTo(angle.point1.x * scale + office.x, angle.point1.y * scale + office.y);
        graphic.lineTo(angle.vertex.x * scale + office.x, angle.vertex.y * scale + office.y);
        graphic.lineTo(angle.point2.x * scale + office.x, angle.point2.y * scale + office.y);
        graphic.stroke({ color, alpha: 0.5 });
    }
    //#region 重叠区域算法
    getOverlappingAreaTest(point, graphic, color, office, scale) {
        let index = this.contains(point);
        if (index < 0) {
            console.error("点在外部");
            return undefined;
        }
        /**
         * 第一步，筛选出图形中所有的折角
         * 第二步，去除尖端没有朝向点击位置的折角
         * 第三步，去除被遮挡的折角（顶点和点击位置之间有交点）。
         * 第四步，如果剩余顶大于等于4个，取最近的4个点做矩形，如果剩余点小于4个取最近两点做垂线切割，如果剩余点少于2个，则判断失败
         */
        let vertexArr = this.getVertexOfPoly(this.pointArr[index]);
        console.log("发现 ", vertexArr.length, "个折角");
        let vertexArr2 = this.vertexTowardFilter(vertexArr, point);
        console.log("发现 ", vertexArr2.length, "个朝向目标的折角");
        for (let i = 0; i < vertexArr.length; i++) {
            let vertex = vertexArr[i];
            this.dirwAngle(vertex, graphic, 0x00ff00, office, scale);
        }
        for (let i = 0; i < vertexArr2.length; i++) {
            let vertex = vertexArr2[i];
            this.dirwAngle(vertex, graphic, 0x0000ff, office, scale);
        }
        //去除被遮挡的折角。
        let vertexArr3 = [];
        for (let i = 0; i < vertexArr2.length; i++) {
            let vertex = vertexArr2[i];
            if (!this.lineSegmentAndPolyIntersect(this.pointArr[index], [point, vertex.vertex])) {
                vertexArr3.push(vertex);
            }
        }
        console.log("发现 ", vertexArr3.length, "个可以用的折角");
        for (let i = 0; i < vertexArr3.length; i++) {
            let vertex = vertexArr3[i];
            this.dirwAngle(vertex, graphic, 0x00ffff, office, scale);
        }
        let nearestVertexArr = [...vertexArr3];
        if (nearestVertexArr.length < 2) {
            console.log("没有找到角");
            return undefined;
        }
        nearestVertexArr.sort((a, b) => { return UtilMath.sqrDistance(point, a.vertex) - UtilMath.sqrDistance(point, b.vertex); });
        if (nearestVertexArr.length >= 4) { /** 至少有4个尖端 */
            let vertex1 = nearestVertexArr[0].vertex;
            let vertex2 = nearestVertexArr[1].vertex;
            let vertex3 = nearestVertexArr[2].vertex;
            let vertex4 = nearestVertexArr[3].vertex;
            let x = (vertex1.x + vertex2.x + vertex3.x + vertex4.x) / 4;
            let y = (vertex1.y + vertex2.y + vertex3.y + vertex4.y) / 4;
            let v0 = new Point(x, y);
            let v01 = UtilMath.subtract(v0, nearestVertexArr[0].vertex);
            let v02 = UtilMath.subtract(v0, nearestVertexArr[1].vertex);
            let v03 = UtilMath.subtract(v0, nearestVertexArr[2].vertex);
            let v04 = UtilMath.subtract(v0, nearestVertexArr[3].vertex);
            let v1 = nearestVertexArr[0].toward;
            let v2 = nearestVertexArr[1].toward;
            let v3 = nearestVertexArr[2].toward;
            let v4 = nearestVertexArr[3].toward;
            let maxAngle = 60;
            let angle1 = Math.abs(this.getAngle(v01, v1));
            let angle2 = Math.abs(this.getAngle(v02, v2));
            let angle3 = Math.abs(this.getAngle(v03, v3));
            let angle4 = Math.abs(this.getAngle(v04, v4));
            if (angle1 < maxAngle && angle2 < maxAngle && angle3 < maxAngle && angle4 < maxAngle) { //有4个点
                let defaultV = new Point(1, 0);
                let a1 = this.getAngle(v01, defaultV);
                let a2 = this.getAngle(v02, defaultV);
                let a3 = this.getAngle(v03, defaultV);
                let a4 = this.getAngle(v04, defaultV);
                let pointArr = [{ angle: a1, point: nearestVertexArr[0] }, { angle: a2, point: nearestVertexArr[1] }, { angle: a3, point: nearestVertexArr[2] }, { angle: a4, point: nearestVertexArr[3] }];
                pointArr.sort((a, b) => { return a.angle - b.angle; });
                //console.log(pointArr);
                let points = [pointArr[0].point, pointArr[1].point, pointArr[2].point, pointArr[3].point];
                let polybool = new Polygon([points[0].vertex, points[1].vertex, points[2].vertex, points[3].vertex,]);
                if (polybool.contains(point.x, point.y)) {
                    let info = new OverlappingAreaInfo();
                    let data1 = { point: points[0].vertex, index: points[0].vertexIndex, t: 0 };
                    let data2 = { point: points[1].vertex, index: points[1].vertexIndex, t: 0 };
                    let data3 = { point: points[2].vertex, index: points[2].vertexIndex, t: 0 };
                    let data4 = { point: points[3].vertex, index: points[3].vertexIndex, t: 0 };
                    info.pointList = [data1, data2, data3, data4];
                    info.index = index;
                    info.isSquare = true;
                    return info; //points;
                }
                return undefined;
            }
        }
        let vertex1 = nearestVertexArr[0].vertex;
        let vertex2 = nearestVertexArr[1].vertex;
        let toward1 = nearestVertexArr[0].toward;
        let toward2 = nearestVertexArr[1].toward;
        let toward = UtilMath.add(toward1, toward2);
        if (toward.x == 0 && toward.y == 0) {
            return undefined;
        }
        let v = UtilMath.subtract(vertex2, vertex1);
        let dotProd = UtilMath.dot(v, toward);
        let magV2Squared = UtilMath.sqrMagnitude(v);
        let scalar = dotProd / magV2Squared;
        let v2 = new Point(scalar * v.x, scalar * v.y);
        let v3 = UtilMath.subtract(toward, v2);
        console.log(v3);
        let point1 = this.getPolyAndRay(this.pointArr[index], [vertex1, UtilMath.add(vertex1, v3)])[0];
        let point2 = this.getPolyAndRay(this.pointArr[index], [vertex2, UtilMath.add(vertex2, v3)])[0];
        if (!point1 || !point2) {
            return undefined;
        }
        let points = [point1.point, vertex1, vertex2, point2.point];
        let polybool = new Polygon(points);
        if (polybool.contains(point.x, point.y)) {
            let info = new OverlappingAreaInfo();
            let data1 = { point: point1.point, index: point1.index, t: point1.t1 };
            let data2 = { point: nearestVertexArr[0].vertex, index: nearestVertexArr[0].vertexIndex, t: 0 };
            let data3 = { point: nearestVertexArr[1].vertex, index: nearestVertexArr[1].vertexIndex, t: 0 };
            let data4 = { point: point2.point, index: point2.index, t: point2.t1 };
            info.pointList = [data1, data2, data3, data4];
            info.index = index;
            info.isSquare = false;
            return info; //points;
        }
        return undefined;
    }
    /** 重叠区域算法
     * @param 点击位置
     * @returns 线段的两个端点,没有这个线返回空数组
     */
    getOverlappingArea(point) {
        let index = this.contains(point);
        if (index < 0) {
            console.error("点在外部");
            return undefined;
        }
        /**
         * 第一步，筛选出图形中所有的折角
         * 第二步，去除尖端没有朝向点击位置的折角
         * 第三步，去除被遮挡的折角（顶点和点击位置之间有交点）。
         * 第四步，如果剩余顶大于等于4个，取最近的4个点做矩形，如果剩余点小于4个取最近两点做垂线切割，如果剩余点少于2个，则判断失败
         */
        let vertexArr = this.getVertexOfPoly(this.pointArr[index]);
        let vertexArr2 = this.vertexTowardFilter(vertexArr, point);
        //去除被遮挡的折角。
        let vertexArr3 = [];
        for (let i = 0; i < vertexArr2.length; i++) {
            let vertex = vertexArr2[i];
            if (!this.lineSegmentAndPolyIntersect(this.pointArr[index], [point, vertex.vertex])) {
                vertexArr3.push(vertex);
            }
        }
        let nearestVertexArr = [...vertexArr3];
        if (nearestVertexArr.length < 2) {
            console.log("没有找到角");
            return undefined;
        }
        nearestVertexArr.sort((a, b) => { return UtilMath.sqrDistance(point, a.vertex) - UtilMath.sqrDistance(point, b.vertex); });
        if (nearestVertexArr.length >= 4) { /** 至少有4个尖端 */
            let vertex1 = nearestVertexArr[0].vertex;
            let vertex2 = nearestVertexArr[1].vertex;
            let vertex3 = nearestVertexArr[2].vertex;
            let vertex4 = nearestVertexArr[3].vertex;
            let x = (vertex1.x + vertex2.x + vertex3.x + vertex4.x) / 4;
            let y = (vertex1.y + vertex2.y + vertex3.y + vertex4.y) / 4;
            let v0 = new Point(x, y);
            let v01 = UtilMath.subtract(v0, nearestVertexArr[0].vertex);
            let v02 = UtilMath.subtract(v0, nearestVertexArr[1].vertex);
            let v03 = UtilMath.subtract(v0, nearestVertexArr[2].vertex);
            let v04 = UtilMath.subtract(v0, nearestVertexArr[3].vertex);
            let v1 = nearestVertexArr[0].toward;
            let v2 = nearestVertexArr[1].toward;
            let v3 = nearestVertexArr[2].toward;
            let v4 = nearestVertexArr[3].toward;
            let maxAngle = 60;
            let angle1 = Math.abs(this.getAngle(v01, v1));
            let angle2 = Math.abs(this.getAngle(v02, v2));
            let angle3 = Math.abs(this.getAngle(v03, v3));
            let angle4 = Math.abs(this.getAngle(v04, v4));
            if (angle1 < maxAngle && angle2 < maxAngle && angle3 < maxAngle && angle4 < maxAngle) { //有4个点
                let defaultV = new Point(1, 0);
                let a1 = this.getAngle(v01, defaultV);
                let a2 = this.getAngle(v02, defaultV);
                let a3 = this.getAngle(v03, defaultV);
                let a4 = this.getAngle(v04, defaultV);
                let pointArr = [{ angle: a1, point: nearestVertexArr[0] }, { angle: a2, point: nearestVertexArr[1] }, { angle: a3, point: nearestVertexArr[2] }, { angle: a4, point: nearestVertexArr[3] }];
                pointArr.sort((a, b) => { return a.angle - b.angle; });
                //console.log(pointArr);
                let points = [pointArr[0].point, pointArr[1].point, pointArr[2].point, pointArr[3].point];
                let polybool = new Polygon([points[0].vertex, points[1].vertex, points[2].vertex, points[3].vertex,]);
                if (polybool.contains(point.x, point.y)) {
                    let info = new OverlappingAreaInfo();
                    let data1 = { point: points[0].vertex, index: points[0].vertexIndex, t: 0 };
                    let data2 = { point: points[1].vertex, index: points[1].vertexIndex, t: 0 };
                    let data3 = { point: points[2].vertex, index: points[2].vertexIndex, t: 0 };
                    let data4 = { point: points[3].vertex, index: points[3].vertexIndex, t: 0 };
                    info.pointList = [data1, data2, data3, data4];
                    info.index = index;
                    info.isSquare = true;
                    return info; //points;
                }
                return undefined;
            }
        }
        let vertex1 = nearestVertexArr[0].vertex;
        let vertex2 = nearestVertexArr[1].vertex;
        let toward1 = nearestVertexArr[0].toward;
        let toward2 = nearestVertexArr[1].toward;
        let toward = UtilMath.add(toward1, toward2);
        if (toward.x == 0 && toward.y == 0) {
            return undefined;
        }
        let v = UtilMath.subtract(vertex2, vertex1);
        let dotProd = UtilMath.dot(v, toward);
        let magV2Squared = UtilMath.sqrMagnitude(v);
        let scalar = dotProd / magV2Squared;
        let v2 = new Point(scalar * v.x, scalar * v.y);
        let v3 = UtilMath.subtract(toward, v2);
        let point1 = this.getPolyAndRay(this.pointArr[index], [vertex1, UtilMath.add(vertex1, v3)])[0];
        let point2 = this.getPolyAndRay(this.pointArr[index], [vertex2, UtilMath.add(vertex2, v3)])[0];
        if (!point1 || !point2) {
            return undefined;
        }
        let points = [point1.point, vertex1, vertex2, point2.point];
        let polybool = new Polygon(points);
        if (polybool.contains(point.x, point.y)) {
            let info = new OverlappingAreaInfo();
            let data1 = { point: point1.point, index: point1.index, t: point1.t1 };
            let data2 = { point: nearestVertexArr[0].vertex, index: nearestVertexArr[0].vertexIndex, t: 0 };
            let data3 = { point: nearestVertexArr[1].vertex, index: nearestVertexArr[1].vertexIndex, t: 0 };
            let data4 = { point: point2.point, index: point2.index, t: point2.t1 };
            info.pointList = [data1, data2, data3, data4];
            info.index = index;
            info.isSquare = false;
            return info; //points;
        }
        return undefined;
    }
    /** 重叠区域算法2
     * @param 点击位置
     * @returns 线段的两个端点,没有这个线返回空数组
     */
    getOverlappingAreaOfArea(pointList, func = undefined) {
        let bounds = UtilMath.getPolyBounds(pointList);
        let point = new Point((bounds.maxX + bounds.minX) / 2, (bounds.maxY + bounds.minY) / 2);
        let index = this.contains(point);
        if (index < 0) {
            console.error("点在外部");
            return undefined;
        }
        /**
         * 1、根据选的区域的中心位置判断出选中的染色体。
         * 2、筛选出框选区域内包含的当前染色体的轮廓点。
         * 3、查找筛选出的轮廓点中，可以形成角度小于150度的角的顶点。
         * 4、去除顶点朝向选择区域外的角。
         * 5、去除顶点和选择区域中心之间有轮廓遮挡的角。
         * 6、如果剩余角数大于等于4个，选项有最近的4个角的顶点进行分割。
         * 7、如果剩余顶点数小于4个，选取最近的2个顶点。由这两角个顶点，向两个角的综合朝向，分别发射两条射线，计算出与轮廓相交的两个顶点。与之前的两个顶点一起组成4个点进行分割。
         * 8、经过4个点将轮廓分成1、2、3、4,四个部分。将1、3连接形成轮廓1。2、4连接形成轮廓2。
         * 去除被遮挡的折角（顶点和点击位置之间有交点）。
         * 如果剩余顶大于等于4个，取最近的4个点做矩形，如果剩余点小于4个取最近两点做垂线切割，如果剩余点少于2个，则判断失败
         */
        let indexList = [];
        let p;
        for (let i = 0; i < this.pointArr[index].length; i++) {
            p = this.pointArr[index][i];
            if (OutlineTool.contains(pointList, p)) {
                let index = i;
                indexList.push(index);
            }
        }
        if (indexList.length == 0) {
            console.error("没有选中点");
            return undefined;
        }
        let dX = bounds.width;
        let dY = bounds.height;
        let d = dX > dY ? dX : dY;
        d = d / 4;
        let vertexArr = this.getVertexOfPoly2(this.pointArr[index], indexList, d);
        if (func)
            func(0, vertexArr);
        let vertexArr2 = this.vertexTowardFilter(vertexArr, point);
        if (func)
            func(1, vertexArr2);
        //去除被遮挡的折角。
        let vertexArr3 = [];
        for (let i = 0; i < vertexArr2.length; i++) {
            let vertex = vertexArr2[i];
            if (!this.lineSegmentAndPolyIntersect(this.pointArr[index], [point, vertex.vertex])) {
                vertexArr3.push(vertex);
            }
        }
        if (func)
            func(2, vertexArr3);
        let nearestVertexArr = [...vertexArr3];
        if (nearestVertexArr.length < 2) {
            console.log("没有找到角");
            return undefined;
        }
        nearestVertexArr.sort((a, b) => { return UtilMath.sqrDistance(point, a.vertex) - UtilMath.sqrDistance(point, b.vertex); });
        if (nearestVertexArr.length >= 4) { /** 至少有4个尖端 */
            let vertex1 = nearestVertexArr[0].vertex;
            let vertex2 = nearestVertexArr[1].vertex;
            let vertex3 = nearestVertexArr[2].vertex;
            let vertex4 = nearestVertexArr[3].vertex;
            let x = (vertex1.x + vertex2.x + vertex3.x + vertex4.x) / 4;
            let y = (vertex1.y + vertex2.y + vertex3.y + vertex4.y) / 4;
            let v0 = new Point(x, y);
            let v01 = UtilMath.subtract(v0, nearestVertexArr[0].vertex);
            let v02 = UtilMath.subtract(v0, nearestVertexArr[1].vertex);
            let v03 = UtilMath.subtract(v0, nearestVertexArr[2].vertex);
            let v04 = UtilMath.subtract(v0, nearestVertexArr[3].vertex);
            let v1 = nearestVertexArr[0].toward;
            let v2 = nearestVertexArr[1].toward;
            let v3 = nearestVertexArr[2].toward;
            let v4 = nearestVertexArr[3].toward;
            let maxAngle = 60;
            let angle1 = Math.abs(this.getAngle(v01, v1));
            let angle2 = Math.abs(this.getAngle(v02, v2));
            let angle3 = Math.abs(this.getAngle(v03, v3));
            let angle4 = Math.abs(this.getAngle(v04, v4));
            if (true) { //(angle1 < maxAngle && angle2 < maxAngle && angle3 < maxAngle && angle4 < maxAngle) {//有4个点
                let defaultV = new Point(1, 0);
                let a1 = this.getAngle(v01, defaultV);
                let a2 = this.getAngle(v02, defaultV);
                let a3 = this.getAngle(v03, defaultV);
                let a4 = this.getAngle(v04, defaultV);
                let pointArr = [{ angle: a1, point: nearestVertexArr[0] }, { angle: a2, point: nearestVertexArr[1] }, { angle: a3, point: nearestVertexArr[2] }, { angle: a4, point: nearestVertexArr[3] }];
                pointArr.sort((a, b) => { return a.angle - b.angle; });
                //console.log(pointArr);
                let points = [pointArr[0].point, pointArr[1].point, pointArr[2].point, pointArr[3].point];
                let polybool = new Polygon([points[0].vertex, points[1].vertex, points[2].vertex, points[3].vertex,]);
                if (polybool.contains(point.x, point.y)) {
                    let info = new OverlappingAreaInfo();
                    let data1 = { point: points[0].vertex, index: points[0].vertexIndex, t: 0 };
                    let data2 = { point: points[1].vertex, index: points[1].vertexIndex, t: 0 };
                    let data3 = { point: points[2].vertex, index: points[2].vertexIndex, t: 0 };
                    let data4 = { point: points[3].vertex, index: points[3].vertexIndex, t: 0 };
                    info.pointList = [data1, data2, data3, data4];
                    info.index = index;
                    info.isSquare = true;
                    return info; //points;
                }
                return undefined;
            }
        }
        let vertex1 = nearestVertexArr[0].vertex;
        let vertex2 = nearestVertexArr[1].vertex;
        let toward1 = nearestVertexArr[0].toward;
        let toward2 = nearestVertexArr[1].toward;
        let toward = UtilMath.add(toward1, toward2);
        if (toward.x == 0 && toward.y == 0) {
            return undefined;
        }
        let v = UtilMath.subtract(vertex2, vertex1);
        let magV2Squared = UtilMath.sqrMagnitude(v);
        // let dotProd = UtilMath.dot(v, toward);
        // let scalar = dotProd / magV2Squared;
        // let v2 = new Point(scalar * v.x, scalar * v.y);
        // let v3 = UtilMath.normalize(UtilMath.subtract(toward, v2)); //v3两个角顶点形成线段垂直且与两个角之和方向一致的射线方向
        let v3 = UtilMath.normalize(toward); //v3两个角之和方向一致的射线方向
        let vLen = Math.sqrt(magV2Squared) / 4;
        let point1List = this.getPolyAndRay(this.pointArr[index], [vertex1, UtilMath.add(vertex1, v3)]);
        let point2List = this.getPolyAndRay(this.pointArr[index], [vertex2, UtilMath.add(vertex2, v3)]);
        let point1;
        for (let i = 0; i < point1List.length; i++) {
            point1 = point1List[i];
            if (point1.t2 > vLen) {
                break;
            }
        }
        let point2;
        for (let i = 0; i < point2List.length; i++) {
            point2 = point2List[i];
            if (point2.t2 > vLen) {
                break;
            }
        }
        if (!point1 || !point2) {
            return undefined;
        }
        let points = [point1.point, vertex1, vertex2, point2.point];
        let polybool = new Polygon(points);
        if (polybool.contains(point.x, point.y)) {
            let info = new OverlappingAreaInfo();
            let data1 = { point: point1.point, index: point1.index, t: point1.t1 };
            let data2 = { point: nearestVertexArr[0].vertex, index: nearestVertexArr[0].vertexIndex, t: 0 };
            let data3 = { point: nearestVertexArr[1].vertex, index: nearestVertexArr[1].vertexIndex, t: 0 };
            let data4 = { point: point2.point, index: point2.index, t: point2.t1 };
            info.pointList = [data1, data2, data3, data4];
            info.index = index;
            info.isSquare = false;
            return info; //points;
        }
        return undefined;
    }
    /** 去除尖端没有朝向物体的折角 */
    vertexTowardFilter(vertexArr, point) {
        let rVertexArr = [];
        for (let i = 0; i < vertexArr.length; i++) {
            let vertex = vertexArr[i];
            let vc = vertex.toward;
            let v = UtilMath.normalize(UtilMath.subtract(point, vertex.vertex));
            // let a = Math.abs(this.getAngle(vc, v));
            // if(a < 30){
            //     rVertexArr.push(vertex);
            // }
            let t = UtilMath.dot(v, vc);
            if (t > 0) {
                rVertexArr.push(vertex);
            }
        }
        return rVertexArr;
    }
    /** 获取多边形和直线的交点 */
    getPolyAndLine(poly, line) {
        let pointInfo = [];
        for (let i = 0; i < poly.length; i++) {
            let outInfo = new IntersectionPointInfo();
            let newLine = [poly[i], poly[(i + 1) % poly.length]];
            if (this.getIntersectionPointOfLine(newLine, line, outInfo)) {
                if (outInfo.isInLine1()) {
                    pointInfo.push(outInfo);
                }
            }
        }
        return pointInfo;
    }
    /** 获取多边形和射线的交点 */
    getPolyAndRay(poly, line) {
        let pointInfo = [];
        for (let i = 0; i < poly.length; i++) {
            let outInfo = new IntersectionPointInfo();
            let newLine = [poly[i], poly[(i + 1) % poly.length]];
            if (this.getIntersectionPointOfLine(newLine, line, outInfo)) {
                if (outInfo.isInLine1() && outInfo.t2 > this.minErrorValue) {
                    let index = i;
                    outInfo.index = index;
                    pointInfo.push(outInfo);
                }
            }
        }
        pointInfo.sort((a, b) => { return a.t2 - b.t2; });
        return pointInfo;
    }
    /** 获取多边形和线段的交点 */
    getPolyAndLineSegment(poly, line) {
        let pointInfo = [];
        for (let i = 0; i < poly.length; i++) {
            let outInfo = new IntersectionPointInfo();
            let newLine = [poly[i], poly[(i + 1) % poly.length]];
            if (this.getIntersectionPointOfLine(newLine, line, outInfo)) {
                if (outInfo.isInLine1() && outInfo.isInLine2(false, false)) {
                    pointInfo.push(outInfo);
                }
            }
        }
        return pointInfo;
    }
    /** 线段和多边形是否相交，不包含线段两端 */
    lineSegmentAndPolyIntersect(poly, line) {
        for (let i = 0; i < poly.length; i++) {
            let outInfo = new IntersectionPointInfo();
            let newLine = [poly[i], poly[(i + 1) % poly.length]];
            if (this.getIntersectionPointOfLine(newLine, line, outInfo)) {
                //console.log(outInfo);
                if (outInfo.isInLine1() && outInfo.isInLine2(false, false)) {
                    //console.log(outInfo);
                    return true;
                }
            }
        }
        return false;
    }
    getDiagonalPoint(vertex1, vertex2, vertexArr) {
        let maxAngle = 15;
        let angleCoefficient = 1;
        let maxSqrDis = 10000;
        let sqrDisCoefficient = 0.001;
        let index1 = -1;
        let index2 = -1;
        let minAngle1 = 400;
        let minAngle2 = 400;
        let toward1 = this.getVectorCenterline(UtilMath.subtract(vertex1.vertex, vertex1.point1), UtilMath.subtract(vertex1.vertex, vertex1.point2));
        let toward2 = this.getVectorCenterline(UtilMath.subtract(vertex2.vertex, vertex2.point1), UtilMath.subtract(vertex2.vertex, vertex2.point2));
        let point1 = new Point();
        let point2 = new Point();
        for (let i = 0; i < vertexArr.length; i++) {
            let v = vertexArr[i];
            let angle1 = Math.abs(this.getAngle(toward1, UtilMath.subtract(v.vertex, vertex1.vertex)));
            let angle2 = Math.abs(this.getAngle(toward2, UtilMath.subtract(v.vertex, vertex2.vertex)));
            let dis1 = UtilMath.sqrDistance(v.vertex, vertex1.vertex);
            let dis2 = UtilMath.sqrDistance(v.vertex, vertex2.vertex);
            let num1 = angle1 * angleCoefficient + dis1 * sqrDisCoefficient;
            let num2 = angle2 * angleCoefficient + dis2 * sqrDisCoefficient;
            if (num1 < num2 && angle1 <= maxAngle && angle1 < minAngle1) {
                minAngle1 = angle1;
                index1 = i;
            }
            else if (angle2 <= maxAngle && angle2 < minAngle2) {
                minAngle2 = angle2;
                index2 = i;
            }
            else if (angle1 <= maxAngle && angle1 < minAngle1) {
                minAngle1 = angle1;
                index1 = i;
            }
        }
        if (index1 < 0) {
        }
        else {
        }
        if (index2 < 0) {
        }
        else {
        }
        return null;
    }
    //#endregion
    /** 横切线算法
     * @param 分割点
     * @returns 线段的两个端点,没有这个线返回空数组
     */
    getCrosscutLineSegment(point) {
        let index = this.contains(point);
        if (index < 0) {
            console.error("点在外部");
            return null;
        }
        let data = this.getShortestCrosscutOfPoly(this.pointArr[index], point);
        data.index = index;
        return data;
    }
    /** 获取多边形中过指定点的最短横切线 */
    getShortestCrosscutOfPoly(pointArr, point) {
        let sqrDis = Number.MAX_VALUE;
        let rPoints = null;
        let tPoints = null;
        let tSqrDis;
        let dX;
        let dY;
        for (let i = 0; i < pointArr.length; i++) {
            for (let j = i + 1; j < pointArr.length; j++) {
                let line1 = [pointArr[i], pointArr[(i + 1) % pointArr.length]];
                let line2 = [pointArr[j], pointArr[(j + 1) % pointArr.length]];
                let poly = [pointArr[i], pointArr[(i + 1) % pointArr.length], pointArr[j], pointArr[(j + 1) % pointArr.length]];
                if (!OutlineTool.contains(poly, point)) {
                    continue;
                }
                tPoints = this.getShortestCrosscutOfTwoLineSegment(line1, line2, point);
                if (!tPoints) {
                    continue;
                }
                tPoints.lineIndex1 = i;
                tPoints.lineIndex2 = j;
                dX = tPoints.line[1].x - tPoints.line[0].x;
                dY = tPoints.line[1].y - tPoints.line[0].y;
                tSqrDis = dX * dX + dY * dY;
                if (tSqrDis < sqrDis) {
                    rPoints = tPoints;
                    sqrDis = tSqrDis;
                }
            }
        }
        return rPoints;
    }
    /** 获取两个线段之间过点的最小线段 */
    getShortestCrosscutOfTwoLineSegment(line1, line2, point) {
        let rLine = this.getShortestCrosscutOfTwoLine(line1, line2, point);
        if (rLine.isInLine() && rLine.isInLine1() && rLine.isInLine2()) {
            return rLine;
        }
        let sqrDis = Number.MAX_VALUE;
        let rPointInfo = null;
        let rPoint1 = new Point();
        let rPoint2 = new Point();
        let t1 = 0;
        let t2 = 0;
        for (let i = 0; i < line1.length; i++) {
            let outInfo = new IntersectionPointInfo();
            if (this.getIntersectionPointOfLine(line1, [line2[i], point], outInfo)) {
                if (!(outInfo.isInLine1() && outInfo.t2 >= 1)) {
                    continue;
                }
                let len = UtilMath.sqrDistance(outInfo.point, line2[i]);
                if (len == 0) {
                    continue;
                }
                if (len < sqrDis) {
                    sqrDis = len;
                    rPointInfo = outInfo;
                    rPoint1 = outInfo.point;
                    rPoint2 = line2[i];
                    t1 = outInfo.t1;
                    t2 = 0;
                }
            }
        }
        for (let i = 0; i < line2.length; i++) {
            let outInfo = new IntersectionPointInfo();
            if (this.getIntersectionPointOfLine(line2, [line1[i], point], outInfo)) {
                if (!(outInfo.isInLine1() && outInfo.t2 >= 1)) {
                    continue;
                }
                let len = UtilMath.sqrDistance(outInfo.point, line1[i]);
                if (len == 0) {
                    continue;
                }
                if (len < sqrDis) {
                    sqrDis = len;
                    rPointInfo = outInfo;
                    rPoint1 = line1[i];
                    rPoint2 = outInfo.point;
                    t1 = 0;
                    t2 = outInfo.t1;
                }
            }
        }
        if (rPointInfo == null) {
            return null;
        }
        let rInfo = new ShortestCrosscutInfo();
        rInfo.line = [rPoint1, rPoint2];
        rInfo.line1 = [...line1];
        rInfo.line2 = [...line2];
        rInfo.t1 = t1;
        rInfo.t2 = t2;
        let dx1 = rInfo.line[1].x - rInfo.line[0].x;
        let dy1 = rInfo.line[1].y - rInfo.line[0].y;
        let x1 = rInfo.line[0].x;
        let y1 = rInfo.line[0].y;
        //let dx = rInfo.line[1].x - x1;
        let x = point.x;
        let y = point.y;
        if (dx1 !== 0) {
            let tX = (x - x1) / dx1;
            rInfo.t = tX;
            return rInfo;
        }
        let tY = (y - y1) / dy1;
        rInfo.t = tY;
        return rInfo;
    }
    /** 获取 point1点过point点直线和line1的焦点，获取point2点过point点直线和line2的焦点，中最近的一个 */
    getIntersectionPointOfTwoLine(line1, line2, point, point1, point2, out) {
        let rPoint1 = new Point();
        let rPoint2 = new Point();
        let rB1 = this.getRayIntersectionPointOfLineSegment(point1, point, line1[0], line1[1], rPoint1);
        let rB2 = this.getRayIntersectionPointOfLineSegment(point2, point, line2[0], line1[1], rPoint2);
        //console.log(point2, point, line2[0], line1[1]);
        let rLine1 = [rPoint1, point1];
        let rLine2 = [rPoint2, point2];
        if (rB1 && !rB2) {
            if (out.length >= 1) {
                out[0] = rPoint1;
            }
            else {
                out.push(rPoint1);
            }
            if (out.length >= 2) {
                out[1] = point1;
            }
            else {
                out.push(point1);
            }
            //console.log(UtilMath.sqrDistance(out[0], out[1]));
            return true;
        }
        if (!rB1 && rB2) {
            if (out.length >= 1) {
                out[0] = rPoint2;
            }
            else {
                out.push(rPoint2);
            }
            if (out.length >= 2) {
                out[1] = point2;
            }
            else {
                out.push(point2);
            }
            //console.log(UtilMath.sqrDistance(out[0], out[1]));
            return true;
        }
        if (rB1 && rB2) {
            let len1 = UtilMath.sqrMagnitude(UtilMath.subtract(rLine1[1], rLine1[0]));
            let len2 = UtilMath.sqrMagnitude(UtilMath.subtract(rLine2[1], rLine2[0]));
            let rp1;
            let rp2;
            if (len1 > len2) {
                rp1 = rPoint2;
                rp2 = point2;
            }
            else {
                rp1 = rPoint1;
                rp2 = point1;
            }
            if (out.length >= 1) {
                out[0] = rp1;
            }
            else {
                out.push(rp1);
            }
            if (out.length >= 2) {
                out[1] = rp2;
            }
            else {
                out.push(rp2);
            }
            // console.log(UtilMath.sqrDistance(rPoint2, point2), rPoint2, point2, line2[0], line2[1], point);
            // console.log(UtilMath.sqrDistance(rPoint1, point1));
            // console.log(UtilMath.sqrDistance(out[0], out[1]));
            return true;
        }
        return false;
    }
    /** 获取两个向量的中间向量 */
    getVectorCenterline(vector1, vector2) {
        let nV1 = UtilMath.normalize(vector1);
        let nV2 = UtilMath.normalize(vector2);
        //let v3 = new Point(-(nV1.y + nV2.y) * 100, (nV1.x + nV2.x) * 100); //这条切向的朝向
        let v3 = new Point((nV1.x + nV2.x), (nV1.y + nV2.y)); //这条切向的朝向
        if (v3.x == 0 && v3.y == 0) { /** 这表示两个向量为180度 */
            v3 = new Point(-vector1.y, vector1.x);
        }
        return UtilMath.normalize(v3);
    }
    /** 获取两条线之间过点的最小线段 */
    getShortestCrosscutOfTwoLine(line1, line2, point) {
        let v1 = UtilMath.subtract(line1[1], line1[0]);
        let v2 = UtilMath.subtract(line2[1], line2[0]);
        let nV1 = UtilMath.normalize(v1);
        let nV2 = UtilMath.normalize(v2);
        //let v3 = new Point(-(nV1.y + nV2.y) * 100, (nV1.x + nV2.x) * 100); //这条切向的朝向
        let v3 = new Point((nV1.x + nV2.x), (nV1.y + nV2.y)); //这条切向的朝向
        if (v3.x == 0 && v3.y == 0) { /** 这表示两条直线平行，且方向相反 */
            v3 = new Point(-v1.y, v1.x);
        }
        let point3 = UtilMath.add(point, v3);
        //this.dirwLine(0xff0000, [point, point3]);
        let rInfo1 = new IntersectionPointInfo();
        let rInfo2 = new IntersectionPointInfo();
        this.getIntersectionPointOfLine(line1, [point, point3], rInfo1);
        this.getIntersectionPointOfLine(line2, [point, point3], rInfo2);
        let rInfo = new ShortestCrosscutInfo();
        rInfo.line = [rInfo1.point, rInfo2.point];
        rInfo.line1 = [...line1];
        rInfo.line2 = [...line2];
        rInfo.t1 = rInfo1.t1;
        rInfo.t2 = rInfo2.t1;
        let dx1 = rInfo.line[1].x - rInfo.line[0].x;
        let dy1 = rInfo.line[1].y - rInfo.line[0].y;
        let x1 = rInfo.line[0].x;
        let y1 = rInfo.line[0].y;
        let dx = rInfo.line[1].x - x1;
        let x = point.x;
        let y = point.y;
        if (dx !== 0) {
            let tX = (x - x1) / dx1;
            rInfo.t = tX;
            return rInfo;
        }
        let tY = (y - y1) / dy1;
        rInfo.t = tY;
        return rInfo;
    }
    /** 获取点在线段上的投影点 */
    getProjectionPointOfLine(point1, point2, point) {
        let v1 = new Point(point2.x - point1.x, point2.y - point1.y);
        let v2 = new Point(point.x - point1.x, point.y - point1.y);
        let dot = v1.x * v2.x + v1.y * v2.y;
        let v1L = Math.sqrt((v1.x * v1.x) + (v1.y * v1.y));
        let l = dot / v1L;
        let n = l / v1L;
        let rP = new Point(point1.x + v1.x * n, point1.y + v1.y * n);
        return rP;
    }
    /** 横切线算法
     * @param 分割点
     * @returns 线段的两个端点,没有这个线返回空数组
     */
    getCrosscutLineSegment2(point) {
        let index = this.contains(point);
        if (index < 0) {
            console.error("点在外部");
            return [];
        }
        /** 有重叠的时候全算 */
        //let nearestPoint = this.getNearestPoint(point);
        /** 简化的算法 */
        let nearestPoint = this.getNearestPointOfPoly(this.pointArr[index], point);
        nearestPoint.polyIndex = index;
        let disSqr = Number.MAX_VALUE;
        let pointArr = this.pointArr[nearestPoint.polyIndex];
        let startPoint = nearestPoint.point;
        let endPoint = new Point();
        let dX;
        let dY;
        for (let i = 0; i < pointArr.length; i++) {
            if (i == nearestPoint.sideIndex) {
                continue;
            }
            let out = new Point();
            this.getRayIntersectionPointOfLineSegment(startPoint, point, pointArr[i], pointArr[(i + 1) % pointArr.length], out);
            dX = out.x - startPoint.x;
            dY = out.y - startPoint.y;
            let sqr = dX * dX + dY * dY;
            if (sqr < disSqr) {
                disSqr = sqr;
                endPoint = out;
            }
        }
        return [startPoint, endPoint];
    }
    /** 获取两直线的交点
     *  @param line1 直线1 @param line2 直线2 @param out 返回点
     *  @returns 是否存在这个点
     */
    getIntersectionPointOfLine(line1, line2, outInfo) {
        if ((line1[0].x == line1[1].x && line1[0].y == line1[1].y) || (line2[0].x == line2[1].x && line2[0].y == line2[1].y)) {
            console.log("两个点不能在一起");
            return false;
            //throw new Error("两个点不能在一起");
        }
        let dx1 = line1[1].x - line1[0].x;
        let dy1 = line1[1].y - line1[0].y;
        let x1 = line1[0].x;
        let y1 = line1[0].y;
        let dx2 = line2[1].x - line2[0].x;
        let dy2 = line2[1].y - line2[0].y;
        let x2 = line2[0].x;
        let y2 = line2[0].y;
        let aDx1 = Math.abs(dx1);
        let aDy1 = Math.abs(dy1);
        let aDx2 = Math.abs(dx2);
        let aDy2 = Math.abs(dy2);
        if (aDx1 < this.minErrorValue && aDy1 < this.minErrorValue) {
            console.error("线段1太短了");
            return false;
        }
        if (aDx2 < this.minErrorValue && aDy2 < this.minErrorValue) {
            console.error("线段2太短了");
            return false;
        }
        let na = dy2 * dx1 - dx2 * dy1;
        if (na == 0) {
            console.error("两边平行");
            return false;
        }
        let nb = x2 * dy1 - x1 * dy1 - y2 * dx1 + y1 * dx1;
        let t = nb / na;
        let x = x2 + t * dx2;
        let y = y2 + t * dy2;
        outInfo.line1 = [...line1];
        outInfo.line2 = [...line2];
        outInfo.point.x = x;
        outInfo.point.y = y;
        //outInfo.t1 = ((x - x1) + (y - y1)) / (dx1 + dy1);
        outInfo.t2 = t;
        if (aDx1 > aDy1) {
            let dx = x - x1;
            let tX = dx / dx1;
            outInfo.t1 = tX;
            return true;
        }
        else {
            let dy = y - y1;
            let tY = dy / dy1;
            outInfo.t1 = tY;
            return true;
        }
        /**  */
        console.log("理论上不会过来");
        return false;
    }
    /** 获取射线与线段的焦点
     *  @param startPoint 射线起点 @param endPoint 射线朝向点 @param point1 线段点1 @param point2 线段点2 @param out 返回点
     *  @returns 是否存在这个点
     */
    getRayIntersectionPointOfLineSegment(startPoint, endPoint, point1, point2, out) {
        if (startPoint.x == endPoint.x && startPoint.y == endPoint.y) {
            throw new Error("两个点不能在一起");
        }
        let dx1 = point2.x - point1.x;
        let dy1 = point2.y - point1.y;
        let x1 = point1.x;
        let y1 = point1.y;
        let dx2 = endPoint.x - startPoint.x;
        let dy2 = endPoint.y - startPoint.y;
        let x2 = startPoint.x;
        let y2 = startPoint.y;
        let na = dy2 * dx1 - dx2 * dy1;
        //console.log(na);
        if (na == 0) {
            //console.error("两边平行")
            return false;
        }
        let nb = x2 * dy1 - x1 * dy1 - y2 * dx1 + y1 * dx1;
        let t = nb / na;
        if (t <= 0) {
            //console.error("不在射线上")
            return false;
        }
        let u;
        if (dx1 != 0) {
            u = (x2 - x1 + t * dx2) / dx1;
        }
        else {
            u = (y2 - y1 + t * dy2) / dy1;
        }
        if (u <= 1 && u >= 0) {
            let x = x2 + t * dx2;
            let y = y2 + t * dy2;
            out.x = x;
            out.y = y;
            return true;
        }
        //console.error("不在线段上")
        return false;
    }
    /** 包含在内部
     *  @returns 多边型的序号
     */
    contains(point) {
        for (let i = 0; i < this.pointArr.length; i++) {
            let points = this.pointArr[i];
            if (!points || points.length < 3) {
                continue;
            }
            let polybool = new Polygon(points);
            if (polybool.contains(point.x, point.y)) {
                return i;
            }
        }
        return -1;
    }
    /** 获取轮廓中距离点最近的点 */
    getNearestPoint(point) {
        let disSqr;
        let rPointInfo = this.getNearestPointOfPoly(this.pointArr[0], point);
        rPointInfo.polyIndex = 0;
        let dX = rPointInfo.point.x - point.x;
        let dY = rPointInfo.point.y - point.y;
        disSqr = dX * dX + dY * dY;
        for (let i = 1; i < this.pointArr.length; i++) {
            let p = this.getNearestPointOfPoly(this.pointArr[i], point);
            dX = p.point.x - point.x;
            dY = p.point.y - point.y;
            let disSqr2 = dX * dX + dY * dY;
            if (disSqr2 < disSqr) {
                disSqr = disSqr2;
                rPointInfo = p;
                rPointInfo.polyIndex = i;
            }
        }
        return rPointInfo;
    }
    /** 获取多边形中距离点最近的点 */
    getNearestPointOfPoly(pointArr, point) {
        if (pointArr.length == 0) {
            throw new Error("点的个数不能为0");
        }
        if (pointArr.length == 1) {
            let info = new NearestPointInfo(pointArr[0]);
            info.sideIndex = 0;
            return info;
        }
        let disSqr;
        let rPoint = this.getNearestPointOfLineSegment(pointArr[0], pointArr[1], point);
        let index = 0;
        let dX = rPoint.x - point.x;
        let dY = rPoint.y - point.y;
        disSqr = dX * dX + dY * dY;
        for (let i = 1; i < pointArr.length; i++) {
            let p = this.getNearestPointOfLineSegment(pointArr[i], pointArr[(i + 1) % pointArr.length], point);
            dX = p.x - point.x;
            dY = p.y - point.y;
            let disSqr2 = dX * dX + dY * dY;
            if (disSqr2 < disSqr) {
                disSqr = disSqr2;
                rPoint = p;
                index = i;
            }
        }
        let info = new NearestPointInfo(rPoint);
        info.sideIndex = index;
        return info;
    }
    /** 获取线段中距离点最近的点 */
    getNearestPointOfLineSegment(point1, point2, point) {
        let v = new Point(point1.x - point2.x, point1.y - point2.y);
        let v2 = new Point(point.x - point2.x, point.y - point2.y);
        let p1 = v.x * v.x + v.y * v.y;
        let p2 = v.x * v2.x + v.y * v2.y;
        let t = p2 / p1;
        t = Math.max(0, t);
        t = Math.min(1, t);
        let x = point2.x + v.x * t;
        let y = point2.y + v.y * t;
        return new Point(x, y);
    }
    /** 获取距离最近轮廓链 */
    getNearestOutlineArr(pointArr, point) {
        let edge = this.getSortArray(this.getAllPointSqrDistance(pointArr, point));
        console.log(edge);
        /** 突出点列表 */
        let nearPoint = [];
        for (let i = 0; i < edge.length; i++) {
            let lastPoint = edge[(i + edge.length - 1) % edge.length];
            let currPoint = edge[i];
            let nextPoint = edge[(i + 1) % edge.length];
            if (lastPoint.sqrDis > currPoint.sqrDis && nextPoint.sqrDis > currPoint.sqrDis) {
                nearPoint.push(edge[i]);
                continue;
            }
            if (lastPoint.sqrDis == currPoint.sqrDis && nextPoint.sqrDis > currPoint.sqrDis) {
                nearPoint.push(edge[i]);
                continue;
            }
            if (lastPoint.sqrDis > currPoint.sqrDis && nextPoint.sqrDis == currPoint.sqrDis) {
                nearPoint.push(edge[i]);
                continue;
            }
        }
        return nearPoint;
    }
    /** 获取轮廓中所有线段中最近的点到指定点的长度数据 */
    getOutlineSqrDistance(pointArr, point) {
        let sqrDistanceArr = new Array(pointArr.length);
        for (let i = 0; i < pointArr.length; i++) {
            let rPoint = this.getNearestPointOfLineSegment(pointArr[i], pointArr[(i + 1) % pointArr.length], point);
            sqrDistanceArr[i] = new PointSqrDistanceInfo();
            sqrDistanceArr[i].index = i;
            sqrDistanceArr[i].sqrDis = UtilMath.sqrDistance(rPoint, point);
            sqrDistanceArr[i].point = rPoint;
        }
        return sqrDistanceArr;
    }
    /** 获取轮廓点中所有点到指定点的长度数据 */
    getAllPointSqrDistance(pointArr, point) {
        let sqrDistanceArr = new Array(pointArr.length);
        for (let i = 0; i < pointArr.length; i++) {
            let rPoint = pointArr[i].clone();
            sqrDistanceArr[i] = new PointSqrDistanceInfo();
            sqrDistanceArr[i].index = i;
            sqrDistanceArr[i].sqrDis = UtilMath.sqrDistance(rPoint, point);
            sqrDistanceArr[i].point = rPoint;
        }
        return sqrDistanceArr;
    }
    /** 对线的长度进行排名 */
    getSortArray(oArr) {
        let newArr = [...oArr];
        newArr.sort((a, b) => { return a.sqrDis - b.sqrDis; });
        for (let i = 0; i < newArr.length; i++) {
            newArr[i].rank = i;
        }
        return oArr;
    }
    /** 获取多边形里的顶点 */
    getVertexOfPoly(pointArr) {
        let bounds = UtilMath.getPolyBounds(pointArr);
        let dX = bounds.width;
        let dY = bounds.height;
        let d = dX > dY ? dX : dY;
        d = d / 8;
        console.log(d);
        let angleInfo = [];
        let backAngleInfo = null;
        let oneIndex = -1;
        //第一个是否合格
        let isEndToEnd = false;
        for (let i = 0; i < pointArr.length; i++) {
            let outInfo = new AngleInfo();
            // if (this.isVertex(pointArr, i, outInfo)) {
            //     angleInfo.push(outInfo);
            // }
            if (this.isVertex(pointArr, i, d, outInfo)) {
                if (i == 0) {
                    isEndToEnd = true;
                }
                if (!backAngleInfo) {
                    //console.log("开始角", outInfo.absAngle);
                    backAngleInfo = outInfo;
                }
                else {
                    //console.log(outInfo.absAngle);
                    if (backAngleInfo.absAngle > outInfo.absAngle) {
                        backAngleInfo = outInfo;
                        oneIndex = i;
                    }
                }
            }
            else {
                if (backAngleInfo) {
                    //console.log("最小的角", backAngleInfo.absAngle);
                    angleInfo.push(backAngleInfo);
                    backAngleInfo = null;
                }
            }
        }
        if (backAngleInfo) {
            if (isEndToEnd) {
                if (backAngleInfo.absAngle < angleInfo[0].absAngle) {
                    angleInfo[0] = backAngleInfo;
                }
            }
            else {
                angleInfo.push(backAngleInfo);
            }
        }
        return angleInfo;
    }
    /** 获取多边形里的顶点 */
    getVertexOfPoly2(pointArr, indexList, d) {
        let angleInfo = [];
        let backAngleInfo = null;
        let oneIndex = -1;
        //第一个是否合格
        let isEndToEnd = false;
        let indexPos = 0;
        for (let i = 0; i < pointArr.length; i++) {
            let outInfo = new AngleInfo();
            // if (this.isVertex(pointArr, i, outInfo)) {
            //     angleInfo.push(outInfo);
            // }
            if (i == indexList[indexPos]) {
                indexPos++;
            }
            else {
                if (backAngleInfo) {
                    //console.log("最小的角", backAngleInfo.absAngle);
                    angleInfo.push(backAngleInfo);
                    backAngleInfo = null;
                }
                continue;
            }
            if (this.isVertex(pointArr, i, d, outInfo)) {
                if (i == 0) {
                    isEndToEnd = true;
                }
                if (!backAngleInfo) {
                    //console.log("开始角", outInfo.absAngle);
                    backAngleInfo = outInfo;
                }
                else {
                    //console.log(outInfo.absAngle);
                    if (backAngleInfo.absAngle > outInfo.absAngle) {
                        backAngleInfo = outInfo;
                        oneIndex = i;
                    }
                }
            }
            else {
                if (backAngleInfo) {
                    //console.log("最小的角", backAngleInfo.absAngle);
                    angleInfo.push(backAngleInfo);
                    backAngleInfo = null;
                }
            }
        }
        if (backAngleInfo) {
            if (isEndToEnd) {
                if (backAngleInfo.absAngle < angleInfo[0].absAngle) {
                    angleInfo[0] = backAngleInfo;
                }
            }
            else {
                angleInfo.push(backAngleInfo);
            }
        }
        return angleInfo;
    }
    /** 判断这点是不是多边形的尖端 */
    isVertex(pointArr, index, len, outInfo) {
        let edgeMinLen = len;
        let maxAngle = 150;
        let sqrEdgeLen = edgeMinLen * edgeMinLen;
        let point = pointArr[index];
        let tempPoint;
        let tempIndex;
        let tempSqrDis = 0;
        let point1;
        let point1Index = -1;
        /** 检查的点的数量 */
        let testPointCount = 0;
        for (let i = 1; i < pointArr.length; i++) {
            let tempIndex = (pointArr.length + index - i) % pointArr.length;
            tempPoint = pointArr[tempIndex];
            tempSqrDis = UtilMath.sqrDistance(tempPoint, point);
            testPointCount++;
            if (tempSqrDis > sqrEdgeLen) {
                point1 = tempPoint;
                point1Index = tempIndex;
                break;
            }
        }
        if (!point1) {
            console.log("图形太小了，找不到长边");
            return false;
        }
        let point2;
        let point2Index = -1;
        for (let i = 1; i < pointArr.length; i++) {
            tempIndex = (index + i) % pointArr.length;
            tempPoint = pointArr[tempIndex];
            tempSqrDis = UtilMath.sqrDistance(tempPoint, point);
            testPointCount++;
            if (tempSqrDis > sqrEdgeLen) {
                point2 = tempPoint;
                point2Index = tempIndex;
                break;
            }
        }
        if (!point2) {
            console.log("图形太小了，找不到长边");
            return false;
        }
        if (testPointCount >= pointArr.length) {
            console.log("图形太小了");
            return false;
        }
        let angle = this.getAngle(UtilMath.subtract(point1, point), UtilMath.subtract(point2, point));
        tempIndex = (index - 1 + pointArr.length) % pointArr.length;
        let tempIndex2 = (index + 1) % pointArr.length;
        let angle2 = this.getAngle(UtilMath.subtract(pointArr[tempIndex], point), UtilMath.subtract(pointArr[tempIndex2], point));
        if ((angle > 0 && angle < 0) || (angle < 0 && angle > 0)) { //和初始角度不一致
            return false;
        }
        if (Math.abs(angle) > maxAngle) {
            //console.log(angle);
            return false;
        }
        outInfo.angle = angle;
        outInfo.vertex = point;
        outInfo.point1 = point1;
        outInfo.point2 = point2;
        outInfo.vertexIndex = index;
        outInfo.point1Index = point1Index;
        outInfo.point2Index = point2Index;
        return true;
    }
    /** 获取两个向量的角度 */
    getAngle(vector1, vector2) {
        let tc1 = vector1.x / UtilMath.magnitude(vector1);
        let tc2 = vector2.x / UtilMath.magnitude(vector2);
        let angle1 = 180 * Math.acos(tc1) / Math.PI;
        let angle2 = 180 * Math.acos(tc2) / Math.PI;
        angle1 = vector1.y < 0 ? -angle1 : angle1;
        angle2 = vector2.y < 0 ? -angle2 : angle2;
        let angle = angle1 - angle2;
        if (angle <= -180) {
            angle += 360;
        }
        else if (angle > 180) {
            angle -= 360;
        }
        return angle;
    }
}
class NearestPointInfo {
    constructor(point) {
        /** 点所在边的序号 */
        this.sideIndex = -1;
        /** 点所在多边型的序号 */
        this.polyIndex = -1;
        this.point = point;
    }
}
export class ShortestCrosscutInfo {
    constructor() {
        this.index = -1;
        this.lineIndex1 = -1;
        this.lineIndex2 = -1;
        /** 分割线 */
        this.line = [];
        /** 直线1 */
        this.line1 = [];
        /** 直线2 */
        this.line2 = [];
        /** 分割点 */
        this.point = new Point();
        /** 分割点在分割线的大致位置 */
        this._t = 0;
        this._type = IntersectionTyte.none;
        /** 分割线上点1，在直线1上的位置 */
        this._t1 = 0;
        this._type1 = IntersectionTyte.none;
        /** 分割线上点2，在直线2上的位置 */
        this._t2 = 0;
        this._type2 = IntersectionTyte.none;
    }
    /** 分割点在分割线的大致位置 */
    set t(value) {
        this._t = value;
        this._type = IntersectionPointInfo.getIntersectionType(this._t);
    }
    /** 分割点在分割线的大致位置 */
    get t() {
        return this._t;
    }
    /** 分割线上点1，在直线1上的位置 */
    set t1(value) {
        this._t1 = value;
        this._type1 = IntersectionPointInfo.getIntersectionType(this._t1);
    }
    /** 分割线上点1，在直线1上的位置 */
    get t1() {
        return this._t1;
    }
    /** 分割线上点2，在直线2上的位置 */
    set t2(value) {
        this._t2 = value;
        this._type2 = IntersectionPointInfo.getIntersectionType(this._t2);
    }
    /** 分割线上点2，在直线2上的位置 */
    get t2() {
        return this._t2;
    }
    isInLine(includeStart = true, includeEnd = true) {
        return IntersectionPointInfo.isInLine(this._type, includeStart, includeEnd);
    }
    isInLine1(includeStart = true, includeEnd = true) {
        return IntersectionPointInfo.isInLine(this._type1, includeStart, includeEnd);
    }
    isInLine2(includeStart = true, includeEnd = true) {
        return IntersectionPointInfo.isInLine(this._type2, includeStart, includeEnd);
    }
}
/** 两条线的交点数据 */
class IntersectionPointInfo {
    constructor() {
        /** 直线1 */
        this.line1 = [];
        /** 直线2 */
        this.line2 = [];
        /** 交点 */
        this.point = new Point();
        /** 交点在直线1上的位置 */
        this._t1 = 0;
        this._type1 = IntersectionTyte.none;
        /** 交点在直线2上的位置 */
        this._t2 = 0;
        this._type2 = IntersectionTyte.none;
    }
    static getIntersectionType(t) {
        if (!Number.isFinite(t)) {
            return IntersectionTyte.parallel;
        }
        else if (Math.abs(t) < IntersectionPointInfo.minValue) {
            return IntersectionTyte.headIntersect;
        }
        else if (Math.abs(t - 1) < IntersectionPointInfo.minValue) {
            return IntersectionTyte.tailIntersect;
        }
        else if (t > 1 || t < 0) {
            return IntersectionTyte.noIntersect;
        }
        else {
            return IntersectionTyte.intersect;
        }
    }
    static isInLine(type, includeStart = true, includeEnd = true) {
        switch (type) {
            case IntersectionTyte.parallel:
            case IntersectionTyte.noIntersect:
                return false;
            case IntersectionTyte.headIntersect:
                return includeStart;
            case IntersectionTyte.tailIntersect:
                return includeEnd;
            case IntersectionTyte.intersect:
                return true;
        }
        return false;
    }
    /** 交点在直线1上的位置 */
    set t1(value) {
        this._t1 = value;
        this._type1 = IntersectionPointInfo.getIntersectionType(this._t1);
    }
    /** 交点在直线1上的位置 */
    get t1() {
        return this._t1;
    }
    /** 交点在直线2上的位置 */
    set t2(value) {
        this._t2 = value;
        this._type2 = IntersectionPointInfo.getIntersectionType(this._t2);
    }
    /** 交点在直线2上的位置 */
    get t2() {
        return this._t2;
    }
    /**
     * 交点是否在线1内
     */
    isInLine1(includeStart = true, includeEnd = true) {
        return IntersectionPointInfo.isInLine(this._type1, includeStart, includeEnd);
    }
    /**  */
    isInLine2(includeStart = true, includeEnd = true) {
        return IntersectionPointInfo.isInLine(this._type2, includeStart, includeEnd);
    }
}
IntersectionPointInfo.minValue = 0.00001;
/** 两条线的相交状态 */
var IntersectionTyte;
(function (IntersectionTyte) {
    /** 空 */
    IntersectionTyte[IntersectionTyte["none"] = -1] = "none";
    /** 平行 */
    IntersectionTyte[IntersectionTyte["parallel"] = -2] = "parallel";
    /** 未相交 */
    IntersectionTyte[IntersectionTyte["noIntersect"] = 0] = "noIntersect";
    /** 头部相交 */
    IntersectionTyte[IntersectionTyte["headIntersect"] = 1] = "headIntersect";
    /** 尾部相交 */
    IntersectionTyte[IntersectionTyte["tailIntersect"] = 2] = "tailIntersect";
    /** 相交 */
    IntersectionTyte[IntersectionTyte["intersect"] = 3] = "intersect";
})(IntersectionTyte || (IntersectionTyte = {}));
/** 线的长度数据 */
class PointSqrDistanceInfo {
    constructor() {
        /** 平方距离 */
        this.sqrDis = -1;
        /** 线的位置序号 */
        this.index = -1;
        /** 位置在的距离排行 */
        this.rank = -1;
        /** 最近点 */
        this.point = null;
    }
}
/** 尖角数据 */
export class AngleInfo {
    constructor() {
        /** 顶点 */
        this.vertex = new Point();
        /** 点1 */
        this.point1 = new Point();
        /** 点2 */
        this.point2 = new Point();
        /** 顶点位置序号 */
        this.vertexIndex = 0;
        /** 点1位置序号 */
        this.point1Index = 0;
        /** 点2位置序号 */
        this.point2Index = 0;
        /** 角度 */
        this.angle = 0;
        this._toward = null;
    }
    get absAngle() {
        return Math.abs(this.angle);
    }
    /** 朝向 */
    get toward() {
        if (!this._toward) {
            let v1 = UtilMath.subtract(this.vertex, this.point1);
            let v2 = UtilMath.subtract(this.vertex, this.point2);
            v1 = UtilMath.normalize(v1);
            v2 = UtilMath.normalize(v2);
            //let v3 = new Point(-(nV1.y + nV2.y) * 100, (nV1.x + nV2.x) * 100); //这条切向的朝向
            let v3 = new Point((v1.x + v2.x), (v1.y + v2.y)); //这条切向的朝向
            if (v3.x == 0 && v3.y == 0) { /** 这表示两个向量为180度 */
                v3 = new Point(-v1.y, v2.x);
            }
            this._toward = UtilMath.normalize(v3);
        }
        return this._toward;
    }
}
export class OverlappingAreaInfo {
    constructor() {
        this.isSquare = false;
        this.pointList = [];
    }
}
