import * as PIXI from "pixi.js";
import MarkArrowEditor from "../Core/Tools/Editor/Arrow/MarkArrowEditor";
import MaskTextEditor from "../Core/Tools/Editor/Text/MarkTextEditor";
import StyleEditorBox from "../Core/Tools/Editor/StyleEditorBox";
import { MARK_STYLE_CHANGED, SYSTEM_OPERATION_STEP_PUSH } from "../Core/Event/SystemEventName";
import MemoData from "../Core/Data/MemoData";
import MarkData from "../Core/Data/MarkData";
import { OperationType } from "../Core/OperationStepSystem";
export default class MarkEditorCanvas extends PIXI.Container {
    constructor() {
        super(...arguments);
        this.editorArr = [new MaskTextEditor(), new MarkArrowEditor()];
        /** 场景中的Items */
        this.editorItem = [];
        /** 选中的Item */
        this.selectIndex = -1;
        this.currEditor = this.editorArr[1];
        this.isMouseIn = false;
        this.mode = MarkMode.None;
        this.drawScale = 1;
        this.sceneWidth = 1;
        this.sceneHeight = 1;
        this.office = new PIXI.Point(1, 1);
        // /** 主图的显示比例 */
        // protected textureScale: number = 1;
        // /** 主图的显示偏移 */
        // protected textureOffice: PIXI.Point = new PIXI.Point(0, 0);
        // /** 主图的显示宽度 */
        // protected textureWidth: number = 0;
        // /** 主图的显示高度 */
        // protected textureHeight: number = 0;
        this.beforeData = undefined;
        this.bg = new PIXI.Graphics();
    }
    init(stage) {
        this.stage = stage;
        //this.boundsArea = new PIXI.Rectangle(0, 0, width / this.drawScale, height / this.drawScale);
        for (let i = 0; i < this.editorArr.length; i++) {
            this.editorArr[i].setParent(this);
        }
        this.currEditor = this.editorArr[0];
        this.on("click", (event) => {
        });
        this.on("mousedown", this.onMousedown);
        this.on("mousemove", this.onMousemove);
        this.on("mouseup", this.onMouseup);
        this.on("mouseout", this.onMouseup);
        this.on("wheel", this.onScaleChange);
        this.addChild(this.bg);
        //this.cursor = "crosshair";
        this.canvas = stage.parent;
        this.styleEditor = new StyleEditorBox();
        this.canvas.addChild(this.styleEditor);
        this.styleEditor.setStyleChangedEvent(this.onStyleChanged.bind(this));
        this.interactive = true;
        this.resize(this.stage.width, this.stage.height);
        //this.setData([{"type":1,"data":{"size":0,"startPoint":{"x":569.1097756410257,"y":100.36934094551282},"endPoint":{"x":633.0769230769232,"y":143.8933794070513},"color":16711680}},{"type":1,"data":{"size":0,"startPoint":{"x":557.2395833333334,"y":97.07206530448718},"endPoint":{"x":480.7427884615385,"y":144.5528345352564},"color":0}},{"type":0,"data":{"pos":{"x":538.7748397435898,"y":54.86693709935898},"text":"ddddddddd","fontSize":10,"color":16711680}}]);
    }
    resize(width, height) {
        this.boundsArea = new PIXI.Rectangle(0, 0, width, height);
    }
    setData(dataArr) {
        for (let i = 0; i < this.editorItem.length; i++) {
            this.editorItem[i].destroy(true);
        }
        this.selectIndex = -1;
        this.editorItem.length = 0;
        for (let i = 0; i < dataArr.length; i++) {
            let data = dataArr[i];
            this.editorArr[data.type].createItem(data);
        }
        this.styleEditor.setItem([]);
        this.refreshStyleEdtiorPos();
    }
    refreshCanvas(isMainCanvas, scale, office, sceneWidth, sceneHeight) {
        this.scale = scale;
        this.drawScale = scale;
        this.sceneWidth = sceneWidth;
        this.sceneHeight = sceneHeight;
        this.office = office;
        this.x = this.office.x;
        this.y = this.office.y;
        if (isMainCanvas) {
            if (this.hitArea) {
                this.hitArea = new PIXI.Rectangle(-this.x / this.drawScale, -this.y / this.drawScale, this.sceneWidth / this.drawScale, this.sceneHeight / this.drawScale);
            }
        }
        else {
            this.hitArea = null;
        }
    }
    onStyleChanged(type, data) {
        if (this.selectIndex >= 0) {
            this.operationStart();
            this.editorItem[this.selectIndex].setStyle(type, data);
            this.operationEnd();
        }
        this.stage.canvas.canvasMain.emit(MARK_STYLE_CHANGED, null);
    }
    setSelectItem(item) {
        if (this.selectIndex >= 0) {
            let selectIndex = this.selectIndex;
            this.selectIndex = -1;
            this.editorItem[selectIndex].setSelect(false);
        }
        if (item) {
            this.selectIndex = this.editorItem.indexOf(item);
            this.styleEditor.setItem(item.editor.getStyleItem());
            this.refreshStyleEdtiorPos();
        }
        else {
            this.selectIndex = -1;
            this.styleEditor.setItem([]);
            this.refreshStyleEdtiorPos();
        }
        if (this.selectIndex >= 0) {
            this.editorItem[this.selectIndex].setSelect(true);
        }
    }
    addItem(item) {
        if (item) {
            this.editorItem.push(item);
            item.init(this);
            this.styleEditor.setItem(item.editor.getStyleItem());
            this.refreshStyleEdtiorPos();
            let data = this.styleEditor.getStyleData();
            for (let key in data) {
                item.setStyle(key, data[key]);
            }
        }
    }
    removeItem(item) {
        if (item) {
            let index = this.editorItem.indexOf(item);
            if (index >= 0) {
                if (index == this.selectIndex) {
                    this.setSelectItem(null);
                }
                this.editorItem.splice(index, 1);
            }
            item.destroy(true);
        }
        else if (this.selectIndex >= 0) {
            this.operationStart();
            this.removeItem(this.editorItem[this.selectIndex]);
            this.operationEnd();
        }
    }
    getData() {
        let value = [];
        for (let i = 0; i < this.editorItem.length; i++) {
            let data = this.editorItem[i].getItemData();
            value.push(data);
        }
        return value;
    }
    operationStart() {
        this.beforeData = this.getData();
    }
    operationEnd() {
        if (!this.beforeData) {
            return;
        }
        let afterData = this.getData();
        let beforeData = this.beforeData;
        this.beforeData = undefined;
        let isChange = afterData.length != beforeData.length;
        if (!isChange) {
            for (let i = 0; i < afterData.length; i++) {
                if (afterData[i].type != beforeData[i].type) {
                    isChange = true;
                    break;
                }
                if (afterData[i].type == MarkMode.Text) {
                    if (afterData[i].data.pos.x != beforeData[i].data.pos.x || afterData[i].data.pos.y != beforeData[i].data.pos.y || afterData[i].data.text != beforeData[i].data.text || afterData[i].data.fontSize != beforeData[i].data.fontSize || afterData[i].data.color != beforeData[i].data.color) {
                        isChange = true;
                        break;
                    }
                }
                else if (afterData[i].type == MarkMode.Arrow) {
                    if (afterData[i].data.startPoint.x != beforeData[i].data.startPoint.x || afterData[i].data.startPoint.y != beforeData[i].data.startPoint.y || afterData[i].data.endPoint.x != beforeData[i].data.endPoint.x || afterData[i].data.endPoint.y != beforeData[i].data.endPoint.y || afterData[i].data.color != beforeData[i].data.color) {
                        isChange = true;
                        break;
                    }
                }
            }
        }
        if (isChange) {
            let before = new MarkData(this.stage.canvas.canvasType, beforeData);
            let after = new MarkData(this.stage.canvas.canvasType, afterData);
            let memo = new MemoData(JSON.stringify(before), JSON.stringify(after), OperationType.Annotation, "0000000000000001");
            this.stage.canvas.canvasMain.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
        }
    }
    onMousedown(event) {
        var _a;
        if (this.currEditor) {
            (_a = this.currEditor) === null || _a === void 0 ? void 0 : _a.onMousedown(event);
        }
        else {
            this.setSelectItem(null);
        }
    }
    onMousemove(event) {
        var _a;
        (_a = this.currEditor) === null || _a === void 0 ? void 0 : _a.onMousemove(event);
    }
    onMouseup(event) {
        var _a;
        (_a = this.currEditor) === null || _a === void 0 ? void 0 : _a.onMouseup(event);
    }
    onScaleChange() {
        for (let i = 0; i < this.editorItem.length; i++) {
            this.editorItem[i].refresh();
        }
    }
    // public setScale(scale: number){
    //     this.scale = new PIXI.Point(scale, scale);
    //     this.hitArea
    //     console.log(this.scale);
    // }
    refreshStyleEdtiorPos() {
        if (!this.styleEditor.parent) {
            return;
        }
        this.styleEditor.pivot = new PIXI.Point(this.styleEditor.width, this.styleEditor.height);
        this.styleEditor.position = new PIXI.Point(this.styleEditor.parent.width, this.styleEditor.parent.height);
    }
    setMode(mode) {
        this.mode = mode;
        switch (mode) {
            case MarkMode.None:
                this.currEditor = undefined;
                this.hitArea = null;
                //this.interactive = true;
                this.setSelectItem(null);
                break;
            case MarkMode.Text:
                this.hitArea = new PIXI.Rectangle(-this.x, -this.y, this.sceneWidth / this.drawScale, this.sceneHeight / this.drawScale);
                //this.interactive = true;
                this.currEditor = this.editorArr[0];
                break;
            case MarkMode.Arrow:
                this.hitArea = new PIXI.Rectangle(-this.x, -this.y, this.sceneWidth / this.drawScale, this.sceneHeight / this.drawScale);
                //this.interactive = true;
                this.currEditor = this.editorArr[1];
                break;
        }
    }
    getMode() {
        return this.mode;
    }
    antoSwitchMode(mode) {
        this.mode = mode;
        switch (mode) {
            case MarkMode.None:
                this.currEditor = undefined;
                this.hitArea = null;
                //this.interactive = true;
                this.setSelectItem(null);
                break;
            case MarkMode.Text:
                this.hitArea = new PIXI.Rectangle(-this.x, -this.y, this.sceneWidth / this.drawScale, this.sceneHeight / this.drawScale);
                //this.interactive = true;
                this.currEditor = this.editorArr[0];
                break;
            case MarkMode.Arrow:
                this.hitArea = new PIXI.Rectangle(-this.x, -this.y, this.sceneWidth / this.drawScale, this.sceneHeight / this.drawScale);
                //this.interactive = true;
                this.currEditor = this.editorArr[1];
                break;
        }
        this.stage.canvas.canvasMain.emit("autoMarkMode", this.mode);
    }
    pointToImage(x, y) {
        x = x / this.drawScale;
        y = y / this.drawScale;
        return { x, y };
    }
    /** 获取标记的设置数据 */
    getMarkSettingData() {
        let data = [];
        for (let i = 0; i < this.editorArr.length; i++) {
            data.push(this.editorArr[i].getSettingData());
        }
        return data;
    }
    setMarkSettingData(data) {
        for (let i = 0; i < this.editorArr.length; i++) {
            for (let j = 0; j < data.length; j++) {
                this.editorArr[i].setSettingData(data[j]);
            }
        }
    }
}
export var MarkMode;
(function (MarkMode) {
    /** 无编辑 */
    MarkMode[MarkMode["None"] = -1] = "None";
    /** 文件编辑 */
    MarkMode[MarkMode["Text"] = 0] = "Text";
    /** 箭头编辑 */
    MarkMode[MarkMode["Arrow"] = 1] = "Arrow";
})(MarkMode || (MarkMode = {}));
