import * as PIXI from "pixi.js";
import CountData from "../Core/Data/CountData";
import CountItem from "./CountItem";
import MemoData from "../Core/Data/MemoData";
import { OperationType } from "../Core/OperationStepSystem";
import { SYSTEM_OPERATION_STEP_PUSH } from "../Core/Event/SystemEventName";
import { COUNT_CHANGED } from "../Event/ItemEventName";
class CountGroup extends PIXI.Container {
    constructor(width, height, canvas) {
        super();
        this.dataScale = 1;
        this.movedItemId = -1;
        this.isSendEvent = false;
        this.boundsArea = new PIXI.Rectangle(0, 0, width, height);
        this.hitArea = this.boundsArea; //CountGroup.ZREO_RECT;
        this.canvas = canvas;
        //this.cursor = "crosshair";
        this.on("mousedown", (event) => {
            this.operationStart();
            let temp = this.toLocal(event.global);
            //temp.x = (event.screenX - this.canvas.stage.x) / this.canvas.stage.scale.x;
            //temp.y = (event.screenY - this.canvas.stage.y) / this.canvas.stage.scale.y;
            temp.x = (temp.x - this.offset.x) / this.dataScale;
            temp.y = (temp.y - this.offset.y) / this.dataScale;
            this.drawItem(this.data.points.length, temp, true);
            this.operationEnd();
        }, this);
        this.interactive = true;
        this.data = new CountData();
    }
    switchEditMode(value) {
        if (value) {
            this.hitArea = this.boundsArea;
        }
        else {
            this.hitArea = CountGroup.ZREO_RECT;
        }
    }
    setData(data) {
        this.data = data;
        for (let i = this.children.length - 1; i >= 0; i--) {
            this.children[i].destroy(true);
        }
        for (let i = 0; i < data.points.length; i++) {
            this.drawItem(i, data.points[i], false);
        }
        if (this.isSendEvent) {
            this.canvas.canvasMain.emit(COUNT_CHANGED, this.data.points.length);
        }
    }
    getData() {
        return JSON.stringify(this.data);
    }
    onResize(width, height) {
        this.boundsArea = new PIXI.Rectangle(0, 0, width, height);
        // if(this.canvas.isMainCanvas) { this.dataScale = 1; }
        // else { this.dataScale = this.canvas.canvasMain.subCanvasWidth / this.canvas.canvasMain.mainCanvasWidth; }
        this.setData(this.data);
    }
    setOffset(offset, scale) {
        this.offset = offset;
        this.dataScale = scale;
        this.setData(this.data);
    }
    itemMoveStart(id) {
        this.operationStart();
        for (let i = 0; i < this.children.length; i++) {
            this.children[i].interactive = false;
        }
        this.movedItemId = id;
    }
    itemMoveEnd() {
        for (let i = 0; i < this.children.length; i++) {
            this.children[i].interactive = true;
        }
        let child = this.children[this.movedItemId];
        let point = this.data.points[this.movedItemId];
        point.x = child.x;
        point.y = child.y;
        this.operationEnd();
    }
    removeItem(item) {
        this.operationStart();
        let index = this.data.points.findIndex((v) => { return v.x == item.x && v.y == item.y; });
        this.data.points.splice(index, 1);
        //this.removeChild(item);
        this.operationEnd();
    }
    operationStart() {
        this.beforeData = this.data.clone();
    }
    operationEnd() {
        var _a, _b;
        let isChanged = false;
        if (((_a = this.beforeData) === null || _a === void 0 ? void 0 : _a.points.length) != ((_b = this.data) === null || _b === void 0 ? void 0 : _b.points.length)) {
            isChanged = true;
        }
        if (!isChanged) {
            for (let i = 0; i < this.beforeData.points.length; i++) {
                if (this.beforeData.points[i].x != this.data.points[i].x || this.beforeData.points[i].y != this.data.points[i].y) {
                    isChanged = true;
                    break;
                }
            }
        }
        if (isChanged) {
            let memo = new MemoData(JSON.stringify(this.beforeData), JSON.stringify(this.data), OperationType.Count, "0000000000001000");
            this.canvas.canvasMain.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
            this.canvas.canvasMain.emit(COUNT_CHANGED, this.data.points.length);
        }
    }
    drawItem(id, point, addData) {
        const RECT_SIZE = CountGroup.RECT_SIZE;
        const LINE_WIDTH = CountGroup.LINE_WIDTH;
        const SCALE = this.dataScale;
        let item = new CountItem(id, this, this.canvas);
        this.addChild(item);
        item.setStrokeStyle({ color: 0xff0000, width: LINE_WIDTH * SCALE });
        item.moveTo(-RECT_SIZE * SCALE / 2, -RECT_SIZE * SCALE / 2);
        item.lineTo(RECT_SIZE * SCALE / 2, RECT_SIZE * SCALE / 2);
        item.moveTo(RECT_SIZE * SCALE / 2, -RECT_SIZE * SCALE / 2);
        item.lineTo(-RECT_SIZE * SCALE / 2, RECT_SIZE * SCALE / 2);
        item.stroke();
        item.x = this.offset.x + point.x * SCALE;
        item.y = this.offset.y + point.y * SCALE;
        item.interactive = true;
        if (addData) {
            this.data.points.push(point);
        }
    }
}
CountGroup.ZREO_RECT = new PIXI.Rectangle(0, 0, 0, 0);
CountGroup.RECT_SIZE = 6;
CountGroup.LINE_WIDTH = 3;
export default CountGroup;
