import * as PIXI from "pixi.js";
export default class TipsLab extends PIXI.Container {
    constructor(value, size) {
        super();
        let bg = new PIXI.Graphics();
        this.bg = bg;
        this.addChild(bg);
        let text = new PIXI.Text({
            text: value,
            style: {
                fontFamily: 'Arial',
                fontSize: size,
                fill: 0xffffff,
                align: 'center',
            }
        });
        this.text = text;
        this.addChild(text);
        bg.boundsArea = new PIXI.Rectangle(0, 0, text.width + 10, text.height + 10);
        bg.filletRect(0, 0, bg.boundsArea.width, bg.boundsArea.height, 5).fill({ color: 0x000000, alpha: 0.5 });
        this.boundsArea = bg.boundsArea;
        text.x = 5;
        text.y = 5;
    }
    setText(value) {
        let text = this.text;
        let bg = this.bg;
        text.text = value;
        bg.boundsArea = new PIXI.Rectangle(0, 0, text.width + 10, text.height + 10);
        bg.clear();
        bg.filletRect(0, 0, bg.boundsArea.width, bg.boundsArea.height, 5).fill({ color: 0x000000, alpha: 0.5 });
        this.boundsArea = bg.boundsArea;
        text.x = 5;
        text.y = 5;
    }
}
