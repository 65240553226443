/** 拖拽开始 */
export const ITEM_MOVE_DRAG_START = "ITEM_MOVE_DRAG_START"; //拖拽开始
/** 拖拽结束 */
export const ITEM_MOVE_DRAG_END = "ITEM_MOVE_DRAG_END"; //拖拽结束
/** 拖拽进新组 */
export const ITEM_MOVE_IN = "ITEM_MOVE_IN"; //拖拽进新组
/** 拖拽离新组 */
export const ITEM_MOVE_OUT = "ITEM_MOVE_OUT"; //拖拽离新组
/** 对象选择 */
export const ITEM_SELECTED = "ITEM_SELECTED"; //对象选择
/** 对象触发重新布局 */
export const ITEM_REORDER = "ITEM_REORDER"; //对象触发重新布局
/** 移除染色体 */
export const ITEM_REMOVED = "ITEM_REMOVED"; //移除染色体
/** 添加染色体 */
export const ITEM_ADDED = "ITEM_ADDED"; //添加染色体
/**单条染色体图像加载完成 */
export const ITEM_IMAGE_LOAD_COMPLETE = "ITEM_IMAGE_LOAD_COMPLETE"; //单条染色体图像加载完成
/** 对象变更，刷新显示 */
export const ITEM_CHANGED = "ITEM_CHANGED"; //对象变更，刷新显示
/** 对象在核型分析页面被编辑，重新计算轮廓及生成对象图片数据 */
export const ITEM_EDITED_IN_KARYOTYPE_PAGE = "ITEM_EDITED_IN_KARYOTYPE_PAGE"; //对象在核型分析页面被编辑，重新计算轮廓及生成对象图片数据
/** 对象在核型分析页面切换染色体组 */
export const ITEM_GROUP_CHANGED_IN_KARYOTYPE_PAGE = "ITEM_GROUP_CHANGED_IN_KARYOTYPE_PAGE"; //对象在核型分析页面切换染色体组
/** 染色体分割 */
export const ITEM_CROSSCUT_LINESEGMENT = "ITEM_CROSSCUT_LINESEGMENT";
/** 对象在原型页面被编辑，重新计算轮廓及生成对象图片数据 */
export const ITEM_EDITED_IN_PROTOTYPE_PAGE = "ITEM_EDITED_IN_PROTOTYPE_PAGE"; //对象在原型页面被编辑，重新计算轮廓及生成对象图片数据
/** 计数变更 */
export const COUNT_CHANGED = "COUNT_CHANGED"; //计数变更
