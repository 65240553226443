import { ConvolutionFilter } from "pixi-filters";
class SharpnessFilter extends ConvolutionFilter {
    constructor() {
        super({ width: 200, height: 200, matrix: SharpnessFilter.sharpenMatrix });
    }
}
//#endregion
SharpnessFilter.sharpenMatrix = [
    0, -1, 0,
    -1, 5, -1,
    0, -1, 0
];
export default SharpnessFilter;
