import PIXICanvas, { CanvasMode } from "../Core/Canvas/PIXICanvas";
import BoundsData from "../Core/Data/BoundsData";
import MemoData from "../Core/Data/MemoData";
import * as PIXI from "pixi.js";
import UtilMath from "../Core/Tools/UtilMath";
import OutlineData from "../Core/Tools/OutlineData";
import MarkEditorCanvas, { MarkMode } from "./MarkEditorCanvas";
import { OperationType } from "../Core/OperationStepSystem";
import { ITEM_CROSSCUT_LINESEGMENT, ITEM_EDITED_IN_PROTOTYPE_PAGE, ITEM_REMOVED } from "../Event/ItemEventName";
import { SYSTEM_ITEM_SELECTED_IN_KARYOTYPE, SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE, SYSTEM_OPERATION_STEP_PUSH } from "../Core/Event/SystemEventName";
import ColorMapFilter from "../Core/Tools/Filter/ColorMapFilter";
import CountGroup from "../Component/CountGroup";
import CountData from "../Core/Data/CountData";
import SharpnessFilter from "../Core/Tools/Filter/SharpnessFilter";
/**原始图canvas */
export default class OriginalImageCanvas extends PIXICanvas {
    constructor() {
        super(...arguments);
        /** 是否显示主图 */
        this.isShowMain = true;
        /** 主图的显示比例 */
        this.textureScale = 1;
        /** 主图的显示偏移 */
        this.textureOffset = new PIXI.Point(0, 0);
        /** 主图的显示宽度 */
        this.textureWidth = 0;
        /** 主图的显示高度 */
        this.textureHeight = 0;
        this.width = 0;
        this.height = 0;
        this.currMode = CanvasMode.None;
        this.fill = new PIXI.FillGradient(0, 0, 0, 36 * 1.7 * 7);
        this.textStyle = new PIXI.TextStyle({
            fontFamily: 'Arial',
            fontSize: 16,
            //fontStyle: 'italic',
            //fontWeight: 'bold',
            //fill: { fill: this.fill },
            //stroke: { color: '#4a1850', width: 5, join: 'round' },
            // dropShadow: {
            //     color: '#000000',
            //     blur: 4,
            //     angle: Math.PI / 6,
            //     distance: 6,
            // },
            wordWrap: false,
            //wordWrapWidth: 440,
        });
        //#endregion
        //#region 染色体分割(重叠区域)
        this.isSegmentationMousedown = false;
        this.decorateOutlineIndex = -1;
        this.isStartDecorateOutline = false;
        this.drawOutlineIndex = -1;
        /** 是否擦除 */
        this.isErase = false;
        this.karyotypeSelectOutlineIndex = -1;
        this.selectEffTime = 0;
        this.selectEffFill = true;
        this.selectEff = (d) => {
            let deltaTime = d.deltaTime;
            this.selectEffTime += deltaTime;
            if (this.selectEffTime >= 60) {
                this.selectEffTime = 0;
                this.selectEffFill = !this.selectEffFill;
                let data = this._outlineData.getOutlineData(this.karyotypeSelectOutlineIndex);
                this.drawSelectOutline(data, this.selectEffFill);
            }
        };
    }
    //protected onClickEvent?: Function;
    onInit(width, height) {
        this.width = width;
        this.height = height;
        //显示层
        this._mainGraphic = new PIXI.Graphics();
        this._outlineGraphic = new PIXI.Graphics();
        this._outlineSuspensionGraphic = new PIXI.Graphics();
        this._outlineSelectGraphic = new PIXI.Graphics();
        this._numberContainer = new PIXI.Container();
        this._editorMarkCanvas = new MarkEditorCanvas();
        this.countGroup = new CountGroup(width, height, this);
        this._outlineSegmentGraphic = new PIXI.Graphics();
        this._outlineSegmentGraphic.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
        this.stage.addChild(this._mainGraphic);
        this.stage.addChild(this._outlineGraphic);
        this.stage.addChild(this._outlineSuspensionGraphic);
        this.stage.addChild(this._outlineSelectGraphic);
        this.stage.addChild(this._outlineSegmentGraphic);
        this.stage.addChild(this._numberContainer);
        this.stage.addChild(this._editorMarkCanvas);
        this.stage.addChild(this.countGroup);
        //控制层
        this._outlineTopLayer = new PIXI.Container();
        this.stage.addChild(this._outlineTopLayer);
        this.countGroup.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
        this.width = width;
        this.height = height;
        this._editorMarkCanvas.init(this.stage);
        this.outlineSelectGraphicSizeChange();
        this.colorMapFilter = new ColorMapFilter();
        this.sharpnessFilter = new SharpnessFilter();
        //this._mainGraphic.filters = [this.colorMapFilter!];
        this.maskText.text = "暂无数据";
        this.showMask(true);
        //悬浮轮廓显示
        this.stage.on("mousemove", this.onStageMousemove, this);
        this.stage.on("mousedown", this.onStageMousedown, this);
        this.canvasMain.off(SYSTEM_ITEM_SELECTED_IN_KARYOTYPE, this.keryotypeSelect, this);
        this.canvasMain.off(SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE, this.keryotypeSelect, this);
        this.canvasMain.on(SYSTEM_ITEM_SELECTED_IN_KARYOTYPE, this.keryotypeSelect, this);
        this.canvasMain.on(SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE, this.keryotypeSelect, this);
    }
    onDestroy() {
        var _a, _b;
        (_a = this.stage) === null || _a === void 0 ? void 0 : _a.off("mousemove", this.onStageMousemove, this);
        this._mainGraphic.destroy();
        (_b = this.mainTexture) === null || _b === void 0 ? void 0 : _b.destroy();
    }
    onResize(width, height) {
        var _a;
        this.width = width;
        this.height = height;
        if (this.karyotypeSelectOutlineIndex >= 0) {
            this.drawSelectOutline(this._outlineData.getOutlineData(this.karyotypeSelectOutlineIndex), false);
        }
        this.showChange();
        this.outlineSelectGraphicSizeChange();
        if (this.countGroup.hitArea) {
            let rect = (_a = this.countGroup) === null || _a === void 0 ? void 0 : _a.hitArea;
            if (rect && rect.width != 0 && rect.width != 0)
                this.countGroup.hitArea = new PIXI.Rectangle(0, 0, this.stage.width, this.stage.height);
        }
        this._editorMarkCanvas.resize(this.stage.width, this.stage.height);
        //this.countGroup?.onResize(width, height);
        // this.sharpnessFilter.width = width * this.testValue;;
        // this.sharpnessFilter.height = height * this.testValue;;
    }
    /** 显示改变了 */
    showChange() {
        var _a;
        if (!this.mainTexture) {
            return;
        }
        let tW = this.mainTexture.width;
        let tH = this.mainTexture.height;
        let sW;
        let sH;
        if (tW / tH < this.width / this.height) {
            sW = this.width;
            sH = sW * tH / tW;
            this.textureScale = sW / tW;
        }
        else {
            sH = this.height;
            sW = sH * tW / tH;
            this.textureScale = sH / tH;
        }
        //console.log("图的比", this.textureScale, this.width, this.height);
        //this.textureOffset = new PIXI.Point((this.width - sW) / 2, (this.height - sH) / 2);
        this.textureOffset = new PIXI.Point(0, 0);
        this.textureWidth = sW;
        this.textureHeight = sH;
        this.stage.boundsArea = new PIXI.Rectangle(0, 0, sW, sH);
        this.stage.hitArea = new PIXI.Rectangle(0, 0, sW, sH);
        //this.stage.checkPosition2();
        this.drawCanvas();
        let editorWidth = sW;
        let editorHeight = sH;
        let scale = 1;
        if (!this.isMainCanvas) {
            if (tW / tH < this.canvasMain.mainCanvasWidth / this.canvasMain.mainCanvasHeight) {
                sW = this.canvasMain.mainCanvasWidth;
                sH = sW * tH / tW;
                scale = this.textureScale / (sW / tW);
            }
            else {
                sH = this.canvasMain.mainCanvasHeight;
                sW = sH * tW / tH;
                scale = this.textureScale / (sH / tH);
            }
            //console.log("大图的比", scale, this.canvasMain.mainCanvasWidth, this.canvasMain.mainCanvasHeight);
        }
        this._editorMarkCanvas.refreshCanvas(this.isMainCanvas, scale, this.textureOffset, editorWidth, editorHeight);
        (_a = this.countGroup) === null || _a === void 0 ? void 0 : _a.setOffset(this.textureOffset, this.textureScale);
    }
    isSendCountEvent(bo) {
        this.countGroup.isSendEvent = bo;
    }
    /** 显示轮廓 */
    showOutline(bo) {
        this._outlineGraphic.visible = bo;
    }
    /** 显示编号 */
    showNumber(bo) {
        this._numberContainer.visible = bo;
    }
    /** 显示标注 */
    showMark(bo) {
        this._editorMarkCanvas.visible = bo;
    }
    setTexture(texture) {
        this.mainTexture = texture;
        this.drawMainTexture();
    }
    getTexture() {
        return this.mainTexture;
    }
    /** 重制主图 */
    resetMainTexture() {
        if (this.originalTexture) {
            console.log("resetMainTexture");
            this.mainTexture = this.originalTexture;
            this.drawMainTexture();
        }
    }
    /** 获取图片 */
    getTextureBase64(func) {
        let graphic = new PIXI.Graphics();
        graphic.x = this.textureOffset.x;
        graphic.y = this.textureOffset.y;
        graphic.scale = this.textureScale * 2;
        graphic.width = this.mainTexture.width / 2;
        graphic.height = this.mainTexture.height / 2;
        graphic.boundsArea = new PIXI.Rectangle(0, 0, this.mainTexture.width / 2, this.mainTexture.height / 2);
        graphic.texture(this.mainTexture, 0xffffff, 0, 0, this.mainTexture.width / 2, this.mainTexture.height / 2);
        graphic.filters = [this.colorMapFilter];
        //this.pixi.renderer.extract.download(graphic)
        func(this.extractToBase64(graphic));
        return;
        this.pixi.renderer.extract.base64(graphic).then((base64) => {
            graphic.destroy();
            this.drawMainTexture();
            func(base64);
        });
    }
    /** 移除染色体 */
    removeItem(boundsData) {
        //let index = this.boundsDataArr.findIndex((v) => { return boundsData.id == v.id && boundsData.index == boundsData.index; })
        this.karyotypeSelectOutlineIndex = -1;
        this.decorateOutlineIndex = -1;
        this.drawSelectOutline(undefined, true);
        this.setData(this.boundsDataArr);
    }
    onClear() {
    }
    /** 设置轮廓数据 */
    setData(boundsDataArr, mainTexture = this.mainTexture, noBGImgTexture = this.noBGImgTexture) {
        console.log(boundsDataArr);
        //this.showMask(false);
        this.boundsDataArr = boundsDataArr;
        this.originalTexture = mainTexture;
        this.mainTexture = mainTexture;
        this.noBGImgTexture = noBGImgTexture;
        let pointArr = [];
        for (let i = 0; i < this.boundsDataArr.length; i++) {
            pointArr.push(this.boundsDataArr[i].points);
        }
        if (this._outlineData) {
            this._outlineData.setData(pointArr);
        }
        else {
            this._outlineData = new OutlineData(pointArr);
        }
        this.showChange();
        if (!this.isMainCanvas && this.karyotypeSelectOutlineIndex >= 0) {
            let data = this._outlineData.getOutlineData(this.karyotypeSelectOutlineIndex);
            this.drawSelectOutline(data, this.selectEffFill);
        }
        //this.canvasMain.emit(SYSTEM_OUTLINE_IMAGE_READY, null);
        this.saveOutlineImg();
    }
    /** 设置标注模式 */
    setMarkMode(value) {
        this._editorMarkCanvas.setMode(value);
    }
    /** 删除标注 */
    deleteMark() {
        this._editorMarkCanvas.removeItem(undefined);
    }
    /** 获取标注数据 */
    getMarkData() {
        return JSON.stringify(this._editorMarkCanvas.getData());
    }
    /** 设置标注数据 */
    setMarkData(data) {
        this._editorMarkCanvas.setData(JSON.parse(data));
    }
    /** 获取计数数据 */
    getCountData() {
        return this.countGroup.getData();
    }
    /** 设置计数数据 */
    setCountData(data) {
        this.countGroup.setData(CountData.createFromString(data));
    }
    /** 撤销 */
    undo(data) {
        var _a;
        if (data.type == OperationType.Transform) {
            let boundsData = JSON.parse(data.afterData);
            this.setData(boundsData);
        }
        else if (data.type == OperationType.Annotation) {
            let markData = JSON.parse(data.beforeData);
            if (markData.canvasType != this.canvasType) {
                return;
            }
            this._editorMarkCanvas.setData(markData.data);
        }
        else if (data.type == OperationType.Decoration) {
            this.setData(this.boundsDataArr);
            if (this.decorateOutlineIndex >= 0) {
                console.log("?????????????????????????????");
                this.drawSelectOutline(this._outlineData.getOutlineData(this.decorateOutlineIndex));
            }
        }
        else if (data.type == OperationType.Count) {
            (_a = this.countGroup) === null || _a === void 0 ? void 0 : _a.setData(CountData.createFromString(data.beforeData));
        }
    }
    /** 重做 */
    redo(data) {
        var _a;
        if (data.type == OperationType.Transform) {
            let boundsData = JSON.parse(data.beforeData);
            this.setData(boundsData);
        }
        else if (data.type == OperationType.Annotation) {
            let markData = JSON.parse(data.afterData);
            if (markData.canvasType != this.canvasType) {
                return;
            }
            this._editorMarkCanvas.setData(markData.data);
        }
        else if (data.type == OperationType.Count) {
            (_a = this.countGroup) === null || _a === void 0 ? void 0 : _a.setData(CountData.createFromString(data.afterData));
        }
        else if (data.type == OperationType.Decoration) {
            this.setData(this.boundsDataArr);
            if (this.decorateOutlineIndex >= 0) {
                this.drawSelectOutline(this._outlineData.getOutlineData(this.decorateOutlineIndex));
            }
        }
    }
    /** 设置Canvas模式 */
    setMode(value) {
        this.removeSelectEff();
        switch (this.currMode) {
            case CanvasMode.OutlineDecorate:
                this.stopOutlineDecorate();
                break;
            case CanvasMode.Segmentation:
                this.stopOverlappingArea();
                break;
            case CanvasMode.ArrowEditor:
                this.stopArrowEditor();
                break;
            case CanvasMode.TextEditor:
                this.stopTextEditor();
                break;
            case CanvasMode.Count:
                this.countGroup.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
                break;
            case CanvasMode.OverlappingArea:
                this.stopOverlappingArea();
                break;
            case CanvasMode.ImageProcessing:
                this._mainGraphic.filters = [];
                break;
        }
        this.currMode = value;
        switch (this.currMode) {
            case CanvasMode.OutlineDecorate:
                this.startOutlineDecorate();
                break;
            case CanvasMode.Segmentation:
                //this.startSegmentation();
                this.startOverlappingArea();
                break;
            case CanvasMode.ArrowEditor:
                this.startArrowEditor();
                break;
            case CanvasMode.TextEditor:
                this.startTextEditor();
                break;
            case CanvasMode.Count:
                this.countGroup.hitArea = new PIXI.Rectangle(0, 0, this.stage.width, this.stage.height);
                break;
            case CanvasMode.OverlappingArea:
                this.startOverlappingArea();
                break;
            case CanvasMode.ImageProcessing:
                this._mainGraphic.filters = [this.colorMapFilter];
                this.colorMapFilter.resetMap();
                break;
        }
    }
    /** 将本地的点，转到图片的像素点上 */
    pointToImage(x, y) {
        x = (x - this.textureOffset.x) / this.textureScale;
        y = (y - this.textureOffset.y) / this.textureScale;
        return { x, y };
    }
    onStageMousedown(event) {
        let pos = this.stage.toLocal(event.global);
        pos = this.pointToImage(pos.x, pos.y);
        let index = this._outlineData.contains(pos);
        this.canvasMain.emit(SYSTEM_ITEM_SELECTED_IN_KARYOTYPE, this.boundsDataArr[index]);
        this.canvasMain.emit(SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE, this.boundsDataArr[index]);
    }
    onStageMousemove(event) {
        this._outlineSuspensionGraphic.clear();
        if (!event)
            return;
        if (!this._outlineData) {
            return;
        }
        let pos = this.stage.toLocal(event.global);
        pos = this.pointToImage(pos.x, pos.y);
        let index = this._outlineData.contains(pos);
        if (index < 0) {
            return;
        }
        let pointArr = this._outlineData.getOutlineData(index);
        if (pointArr == null || pointArr.length == 0) {
            return;
        }
        this._outlineSuspensionGraphic.beginPath();
        this._outlineSuspensionGraphic.moveTo(pointArr[0].x * this.textureScale + this.textureOffset.x, pointArr[0].y * this.textureScale + this.textureOffset.y);
        for (let j = 1; j < pointArr.length; j++) {
            this._outlineSuspensionGraphic.lineTo(pointArr[j].x * this.textureScale + this.textureOffset.x, pointArr[j].y * this.textureScale + this.textureOffset.y);
        }
        this._outlineSuspensionGraphic.closePath();
        this._outlineSuspensionGraphic.stroke(0x00ff00);
    }
    //#region 显示
    /** 是否显示主图 */
    showMainTexture(isShowMain) {
        this.isShowMain = isShowMain;
        this.drawMainTexture();
    }
    saveOutlineImg() {
        let graphic = new PIXI.Graphics();
        let scale = 0.5;
        graphic.boundsArea = new PIXI.Rectangle(0, 0, this.mainTexture.width * scale, this.mainTexture.height * scale);
        graphic.texture(this.originalTexture, 0xffffff, 0, 0, this.mainTexture.width * scale, this.mainTexture.height * scale);
        this._outlineData.dirwOutline(graphic, 0xff0000, new PIXI.Point(0, 0), scale, false);
        let base64 = this.extractToBase64(graphic);
        //this.pixi.renderer.extract.download(graphic);
        graphic.destroy(true);
        return base64;
    }
    /** 遮挡性染色提 @param isOutline 是否要轮廓线 */
    saveShelterOutlineImg(isOutline) {
        let graphic = new PIXI.Graphics();
        let scale = 0.5;
        graphic.boundsArea = new PIXI.Rectangle(0, 0, this.mainTexture.width * scale, this.mainTexture.height * scale);
        graphic.texture(this.originalTexture, 0xffffff, 0, 0, this.mainTexture.width * scale, this.mainTexture.height * scale);
        if (isOutline) {
            this._outlineData.dirwOutline(graphic, 0xff0000, new PIXI.Point(0, 0), scale, false);
        }
        for (let i = 0; i < this.boundsDataArr.length; i++) {
            let id = this.boundsDataArr[i].id;
            console.log(id);
            if (id == 23 || id == 24) {
                let rect = this.getBounds(this.boundsDataArr[i].points);
                console.log(rect);
                graphic.rect(rect.x * scale, rect.y * scale, rect.width * scale, rect.height * scale);
                graphic.fill(0xA0A0A0);
                graphic.stroke(0xA0A0A0);
            }
        }
        let base64 = this.extractToBase64(graphic);
        //this.pixi.renderer.extract.download(graphic);
        graphic.destroy(true);
        return base64;
    }
    getBounds(points) {
        let bounds = {};
        if (points.length < 2) {
            bounds.x = 0;
            bounds.y = 0;
            bounds.width = 0;
            bounds.height = 0;
            return bounds;
        }
        let xMin = points[0].x;
        let xMax = points[0].x;
        let yMin = points[0].y;
        let yMax = points[0].y;
        for (let i = 0; i < points.length; i++) {
            let x = points[i].x;
            let y = points[i].y;
            if (xMin > x) {
                xMin = x;
            }
            if (xMax < x) {
                xMax = x;
            }
            if (yMin > y) {
                yMin = y;
            }
            if (yMax < y) {
                yMax = y;
            }
        }
        bounds.x = xMin;
        bounds.y = yMin;
        bounds.width = xMax - xMin;
        bounds.height = yMax - yMin;
        return bounds;
    }
    /** 重新渲染Canvas */
    drawCanvas() {
        this.drawMainTexture();
        this.drawOutline();
        this.drawNumber();
    }
    /** 渲染主图 */
    drawMainTexture(isShowMain = this.isShowMain) {
        let texture = isShowMain || !this.noBGImgTexture ? this.mainTexture : this.noBGImgTexture;
        this._mainGraphic.clear();
        if (!texture) {
            console.log("图片未准备好");
            return;
        }
        this._mainGraphic.x = this.textureOffset.x;
        this._mainGraphic.y = this.textureOffset.y;
        this.sharpnessFilter.width = texture.width;
        this.sharpnessFilter.height = texture.height;
        this._mainGraphic.scale = this.textureScale;
        this._mainGraphic.texture(texture, 0xffffff, 0, 0, texture.width, texture.height);
    }
    /** 渲染轮廓 */
    drawOutline() {
        this._outlineGraphic.clear();
        let points = this._outlineData.getOutlineArray();
        for (let i = 0; i < points.length; i++) {
            let data = points[i];
            this.drawSingleOutline(data);
        }
    }
    /** 渲染选择轮廓 */
    drawSingleOutline(pointArr, color = 0xff0000) {
        if (pointArr == null || pointArr.length == 0) {
            return;
        }
        this._outlineGraphic.beginPath();
        this._outlineGraphic.moveTo(pointArr[0].x * this.textureScale + this.textureOffset.x, pointArr[0].y * this.textureScale + this.textureOffset.y);
        for (let j = 1; j < pointArr.length; j++) {
            this._outlineGraphic.lineTo(pointArr[j].x * this.textureScale + this.textureOffset.x, pointArr[j].y * this.textureScale + this.textureOffset.y);
        }
        this._outlineGraphic.closePath();
        this._outlineGraphic.stroke(color);
    }
    /** 渲染编号 */
    drawNumber() {
        this._numberContainer.removeChildren();
        let tW = this.mainTexture.width;
        let tH = this.mainTexture.height;
        let sW;
        let sH;
        let scale = 1;
        if (!this.isMainCanvas) {
            if (tW / tH > this.canvasMain.mainCanvasWidth / this.canvasMain.mainCanvasHeight) {
                sW = this.canvasMain.mainCanvasWidth;
                sH = sW * tH / tW;
                scale = this.textureScale / (sW / tW);
            }
            else {
                sH = this.canvasMain.mainCanvasHeight;
                sW = sH * tW / tH;
                scale = this.textureScale / (sH / tH);
            }
            //console.log("大图的比", scale, this.canvasMain.mainCanvasWidth, this.canvasMain.mainCanvasHeight);
        }
        this.textStyle.fontSize = 16 * scale;
        for (let i = 0; i < this.boundsDataArr.length; i++) {
            let boundsData = this.boundsDataArr[i];
            if (boundsData.points == null || boundsData.points.length == 0) {
                continue;
            }
            let point = this.calcTextPos(boundsData.points, scale);
            let text = new PIXI.Text({ text: boundsData.id.toString(), style: this.textStyle });
            this._numberContainer.addChild(text);
            text.anchor = 0.5;
            text.position = point;
        }
    }
    /** 计算编号位置 */
    calcTextPos(pointArr, scale) {
        let width = this.width;
        let height = this.height;
        let top = new PIXI.Point(0, Number.MIN_VALUE);
        let bottom = new PIXI.Point(0, Number.MAX_VALUE);
        let right = new PIXI.Point(Number.MIN_VALUE, 0);
        let left = new PIXI.Point(Number.MAX_VALUE, 0);
        let margins = 20 * scale;
        let office = 10 * scale;
        for (let i = 0; i < pointArr.length; i++) {
            let point = pointArr[i];
            if (point.x > right.x) {
                right = point;
            }
            if (point.x < left.x) {
                left = point;
            }
            if (point.y > top.y) {
                top = point;
            }
            if (point.y < bottom.y) {
                bottom = point;
            }
        }
        //top
        if (top.y < height - margins && top.x > margins && top.x < width - margins) {
            top = new PIXI.Point(top.x * this.textureScale + this.textureOffset.x, top.y * this.textureScale + this.textureOffset.y);
            //console.log(1);
            return UtilMath.add(top, new PIXI.Point(0, office));
        }
        //right
        if (right.x < width - margins && right.y > margins && right.y < height - margins) {
            //console.log(2);
            right = new PIXI.Point(right.x * this.textureScale + this.textureOffset.x, right.y * this.textureScale + this.textureOffset.y);
            return UtilMath.add(right, new PIXI.Point(office, 0));
        }
        //bottom
        if (bottom.y > margins && bottom.x > margins && bottom.x < width - margins) {
            //console.log(3);
            bottom = new PIXI.Point(bottom.x * this.textureScale + this.textureOffset.x, bottom.y * this.textureScale + this.textureOffset.y);
            return UtilMath.add(bottom, new PIXI.Point(0, -office));
        }
        //left
        if (left.x > margins && left.y > margins && left.y < height - margins) {
            //console.log(4);
            left = new PIXI.Point(left.x * this.textureScale + this.textureOffset.x, left.y * this.textureScale + this.textureOffset.y);
            return UtilMath.add(left, new PIXI.Point(-office, 0));
        }
        let point = new PIXI.Point(pointArr[0].x * this.textureScale + this.textureOffset.x, pointArr[0].y * this.textureScale + this.textureOffset.y);
        return point;
    }
    //#endregion
    //#region 计数
    //#endregion
    //#region 染色体分割（粘连）（这个功能整合到了重叠区域一起）
    /** 开始染色体分割模式（粘连） */
    startSegmentation() {
        console.log("开始染色体分割");
        //this.onClickEvent = this.crosscutLineSegment;
    }
    /** 结束染色体分割模式（粘连） */
    stopSegmentation() {
        console.log("结束染色体分割");
        // if (this.onClickEvent == this.crosscutLineSegment) {
        //     this.onClickEvent = undefined;
        // }
    }
    /** 切割染色体 */
    crosscutLineSegment(pos) {
        // let pos: any = this.stage.toLocal(event.global);
        // pos = this.pointToImage(pos.x, pos.y);
        let points = this._outlineData.getCrosscutLineSegment(new PIXI.Point(pos.x, pos.y));
        if (!points) {
            return;
        }
        let boundsData = this.boundsDataArr[points.index];
        let originalBoundsData = boundsData.clone();
        // let p1 = boundsData.transformedPoints[points.lineIndex1];
        // let p2 = boundsData.transformedPoints[points.lineIndex1 + 1];
        // let p3 = UtilMath.add(p1, UtilMath.multiply(UtilMath.subtract(p2, p1), points.t1));
        // p1 = boundsData.transformedPoints[points.lineIndex2];
        // p2 = boundsData.transformedPoints[(points.lineIndex2 + 1) % boundsData.points.length];
        // let p4 = UtilMath.add(p1, UtilMath.multiply(UtilMath.subtract(p2, p1), points.t2));
        let point1 = [];
        //let pointTrans1: PIXI.PointData[] = [];
        point1.push({ x: points.line[0].x, y: points.line[0].y });
        //pointTrans1.push({ x: p3.x, y: p3.y });
        for (let i = points.lineIndex1; i < points.lineIndex2; i++) {
            let index = i + 1;
            point1.push(boundsData.points[index]);
            //pointTrans1.push(boundsData.transformedPoints[index]);
        }
        point1.push({ x: points.line[1].x, y: points.line[1].y });
        //pointTrans1.push({ x: p4.x, y: p4.y });
        let point2 = [];
        //let pointTrans2: PIXI.PointData[] = [];
        let len = boundsData.points.length - (points.lineIndex2 - points.lineIndex1);
        point2.push({ x: points.line[1].x, y: points.line[1].y });
        //pointTrans2.push({ x: p4.x, y: p4.y });
        for (let i = 0; i < len; i++) {
            let index = (i + points.lineIndex2 + 1) % boundsData.points.length;
            point2.push(boundsData.points[index]);
            //pointTrans2.push(boundsData.transformedPoints[index]);
        }
        point2.push({ x: points.line[0].x, y: points.line[0].y });
        //pointTrans2.push({ x: p3.x, y: p3.y });
        if (UtilMath.sqrDistance(point1[0], point1[1]) < 0.1) {
            point1.splice(0, 1);
        }
        if (UtilMath.sqrDistance(point1[point1.length - 2], point1[point1.length - 1]) < 0.1) {
            point1.pop();
        }
        if (UtilMath.sqrDistance(point2[0], point2[1]) < 0.1) {
            point2.splice(0, 1);
        }
        if (UtilMath.sqrDistance(point2[point2.length - 2], point2[point2.length - 1]) < 0.1) {
            point2.pop();
        }
        let boundsData2 = new BoundsData();
        boundsData2.id = 25;
        boundsData2.index = 0;
        boundsData2.points = point2;
        //boundsData2.transformedPoints = pointTrans2;
        let boundsData1 = new BoundsData();
        this.boundsDataArr[points.index].copyTo(boundsData1);
        boundsData1.points = point1;
        //boundsData1.transformedPoints = pointTrans1;
        //this.boundsDataArr.push(newBoundsData);
        //this.setData(this.boundsDataArr);
        this.canvasMain.emit(ITEM_CROSSCUT_LINESEGMENT, [points.index, originalBoundsData, boundsData1, boundsData2]);
        //this.drawLine(this._outlineGraphic, points.line, 0xff0000);
    }
    /** 开始染色体分割模式(重叠区域) */
    startOverlappingArea() {
        console.log("开始染色体分割重叠");
        this._outlineGraphic.off("mousedown", this.segmentationMousedown, this);
        this._outlineGraphic.off("mousemove", this.segmentationMousemove, this);
        this._outlineGraphic.off("mouseup", this.segmentationMouseup, this);
        this._outlineGraphic.on("mousedown", this.segmentationMousedown, this);
        this._outlineGraphic.on("mousemove", this.segmentationMousemove, this);
        this._outlineGraphic.hitArea = new PIXI.Rectangle(0, 0, this.stage.width, this.stage.height);
        this._outlineGraphic.interactive = true;
        //this.onClickEvent = this.crosscutOverlappingArea;
    }
    /** 结束染色体分割模式(重叠区域) */
    stopOverlappingArea() {
        console.log("结束染色体分割重叠");
        this._outlineSegmentGraphic.clear();
        this._outlineGraphic.off("mousedown", this.segmentationMousedown, this);
        this._outlineGraphic.off("mousemove", this.segmentationMousemove, this);
        this._outlineGraphic.off("mouseup", this.segmentationMouseup, this);
        this._outlineGraphic.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
        this._outlineGraphic.interactive = false;
    }
    segmentationMousedown(event) {
        this.canvasMain.stopSaveTimer();
        this.isSegmentationMousedown = true;
        //this._outlineGraphic.off("mousemove", this.segmentationMousemove, this);
        this._outlineGraphic.off("mouseup", this.segmentationMouseup, this);
        //this._outlineGraphic.on("mousemove", this.segmentationMousemove, this);
        this._outlineGraphic.on("mouseup", this.segmentationMouseup, this);
        let pos = this.stage.toLocal(event.global);
        pos = this.pointToImage(pos.x, pos.y);
        this.segmentationArea = [pos];
        event.stopPropagation();
        let index = this._outlineData.contains(pos);
        this.canvasMain.emit(SYSTEM_ITEM_SELECTED_IN_KARYOTYPE, this.boundsDataArr[index]);
        this.canvasMain.emit(SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE, this.boundsDataArr[index]);
    }
    segmentationMousemove(event) {
        let pos = this.stage.toLocal(event.global);
        pos = this.pointToImage(pos.x, pos.y);
        this._outlineSegmentGraphic.clear();
        if (this.isSegmentationMousedown) {
            this.segmentationArea.push(pos);
            let start = this.segmentationArea[this.segmentationArea.length - 2];
            let end = this.segmentationArea[this.segmentationArea.length - 1];
            this._outlineGraphic.moveTo(start.x * this.textureScale + this.textureOffset.x, start.y * this.textureScale + this.textureOffset.y);
            this._outlineGraphic.lineTo(end.x * this.textureScale + this.textureOffset.x, end.y * this.textureScale + this.textureOffset.y);
            this._outlineGraphic.stroke(0x00ff00);
            event.stopPropagation();
        }
        else {
            //let bounds = UtilMath.getPolyBounds(pos);
            //let point = new PIXI.Point((bounds.maxX - bounds.minX) / 2, (bounds.maxY - bounds.minY) / 2);
            let points = this._outlineData.getCrosscutLineSegment(new PIXI.Point(pos.x, pos.y));
            if (!points) {
                return;
            }
            let line = points.line;
            let start = line[0];
            let end = line[1];
            this._outlineSegmentGraphic.moveTo(start.x * this.textureScale + this.textureOffset.x, start.y * this.textureScale + this.textureOffset.y);
            this._outlineSegmentGraphic.lineTo(end.x * this.textureScale + this.textureOffset.x, end.y * this.textureScale + this.textureOffset.y);
            this._outlineSegmentGraphic.stroke({ color: 0x00ff00 });
        }
    }
    segmentationMouseup(event) {
        //this._outlineGraphic.off("mousemove", this.segmentationMousemove, this);
        this.isSegmentationMousedown = false;
        this._outlineGraphic.off("mouseup", this.segmentationMouseup, this);
        this.drawOutline();
        if (this.segmentationArea.length < 1) {
            return;
        }
        if (this.segmentationArea.length < 3) {
            this.crosscutLineSegment(this.segmentationArea[0]);
        }
        else {
            this.crosscutOverlappingArea(this.segmentationArea);
        }
        event.stopPropagation();
    }
    get testGraphics() {
        if (!this._testGraphics) {
            this._testGraphics = new PIXI.Graphics();
            this.stage.addChild(this._testGraphics);
        }
        return this._testGraphics;
    }
    ;
    /** 切割染色体 */
    crosscutOverlappingArea(pos) {
        let bounds = UtilMath.getPolyBounds(pos);
        let point = new PIXI.Point((bounds.maxX - bounds.minX) / 2, (bounds.maxY - bounds.minY) / 2);
        // this._outlineGraphic.circle(point.x * this.textureScale + this.textureOffset.x, point.y * this.textureScale + this.textureOffset.y, 3);
        // this._outlineGraphic.fill(0x00ff00);
        // this.testGraphics.clear();
        // let points =  this._outlineData.getOverlappingAreaOfAreaTest(pos, this.testGraphics, this.textureOffset, this.textureScale);
        let points = this._outlineData.getOverlappingAreaOfArea(pos);
        if (!points) {
            console.log("没有重叠区域");
            //this.crosscutLineSegment(event);
            return;
        }
        let boundsData = this.boundsDataArr[points.index];
        let originalBoundsData = boundsData.clone();
        // let p1 = boundsData.transformedPoints[points.lineIndex1];
        // let p2 = boundsData.transformedPoints[points.lineIndex1 + 1];
        // let p3 = UtilMath.add(p1, UtilMath.multiply(UtilMath.subtract(p2, p1), points.t1));
        // p1 = boundsData.transformedPoints[points.lineIndex2];
        // p2 = boundsData.transformedPoints[(points.lineIndex2 + 1) % boundsData.points.length];
        // let p4 = UtilMath.add(p1, UtilMath.multiply(UtilMath.subtract(p2, p1), points.t2));
        points.pointList.sort((v1, v2) => { return v1.index - v2.index; });
        console.log(points.pointList);
        let pointList = [[], [], [], [], []];
        let index = 0;
        for (let i = 0; i < boundsData.points.length; i++) {
            let index1;
            if (index < points.pointList.length) {
                index1 = points.pointList[index].index;
            }
            else {
                index1 = 100000000000000000000;
            }
            let p = boundsData.points[i];
            pointList[index].push({ x: p.x, y: p.y });
            if (index1 == i) {
                index++;
            }
        }
        for (let i = 1; i < pointList.length; i++) {
            let p = points.pointList[i - 1];
            console.log(p.index, pointList[i - 1].length);
            pointList[i - 1].push({ x: p.point.x, y: p.point.y });
            pointList[i].unshift({ x: p.point.x, y: p.point.y });
        }
        for (let i = 0; i < pointList.length; i++) {
            let pointArr = pointList[i];
            if (pointArr.length < 2) {
                continue;
            }
            if (UtilMath.sqrDistance(pointArr[0], pointArr[1]) < 0.01) {
                pointArr.splice(1, 1);
            }
            if (pointArr.length < 2) {
                continue;
            }
            if (UtilMath.sqrDistance(pointArr[pointArr.length - 2], pointArr[pointArr.length - 1]) < 0.01) {
                pointArr.splice(pointArr.length - 2, 1);
            }
        }
        let outline1 = [...pointList[0], ...pointList[2], ...pointList[4]];
        let outline2 = [...pointList[1], ...pointList[3]];
        // let point1: PIXI.PointData[] = [];
        // //let pointTrans1: PIXI.PointData[] = [];
        // point1.push({ x: points.line[0].x, y: points.line[0].y });
        // //pointTrans1.push({ x: p3.x, y: p3.y });
        // for (let i = points.lineIndex1; i < points.lineIndex2; i++) {
        //     let index = i + 1;
        //     point1.push(boundsData.points[index]);
        // }
        // point1.push({ x: points.line[1].x, y: points.line[1].y });
        // let point2: PIXI.PointData[] = [];
        // let len = boundsData.points.length - (points.lineIndex2 - points.lineIndex1);
        // point2.push({ x: points.line[1].x, y: points.line[1].y });
        // for (let i = 0; i < len; i++) {
        //     let index = (i + points.lineIndex2 + 1) % boundsData.points.length;
        //     point2.push(boundsData.points[index]);
        // }
        // point2.push({ x: points.line[0].x, y: points.line[0].y });
        let boundsData2 = new BoundsData();
        boundsData2.id = 25;
        boundsData2.index = 0;
        boundsData2.points = outline2;
        //boundsData2.transformedPoints = pointTrans2;
        let boundsData1 = new BoundsData();
        this.boundsDataArr[points.index].copyTo(boundsData1);
        boundsData1.points = outline1;
        //boundsData1.transformedPoints = pointTrans1;
        //this.boundsDataArr.push(newBoundsData);
        //this.setData(this.boundsDataArr);
        this.canvasMain.emit(ITEM_CROSSCUT_LINESEGMENT, [points.index, originalBoundsData, boundsData1, boundsData2]);
        //this.drawLine(this._outlineGraphic, points.line, 0xff0000);
    }
    //#endregion
    //#region 轮廓修饰
    outlineSelectGraphicSizeChange() {
        if (this._outlineGraphic.hitArea) {
            this._outlineGraphic.hitArea = new PIXI.Rectangle(0, 0, this.stage.width, this.stage.height);
        }
    }
    startOutlineDecorate() {
        console.log("开始轮廓修饰");
        this._outlineGraphic.interactive = true;
        this._outlineGraphic.off("mousemove", this.outlineLayerMove, this);
        this._outlineGraphic.off("mousedown", this.selectOutline, this);
        this._outlineTopLayer.off("mousemove", this.decorateOutline, this);
        this._outlineTopLayer.off("mouseup", this.decorateOutlineEnd, this);
        this._outlineTopLayer.off("mouseout", this.decorateOutlineEnd, this);
        this._outlineGraphic.on("mousemove", this.outlineLayerMove, this);
        this._outlineGraphic.on("mousedown", this.selectOutline, this);
        this._outlineTopLayer.on("mousemove", this.decorateOutline, this);
        this._outlineTopLayer.on("mouseup", this.decorateOutlineEnd, this);
        this._outlineTopLayer.on("mouseout", this.decorateOutlineEnd, this);
        this._outlineGraphic.hitArea = new PIXI.Rectangle(0, 0, this.stage.width, this.stage.height);
    }
    stopOutlineDecorate() {
        console.log("关闭轮廓修饰");
        this._outlineGraphic.off("mousemove", this.outlineLayerMove, this);
        this._outlineGraphic.off("mousedown", this.selectOutline, this);
        this._outlineTopLayer.off("mousemove", this.decorateOutline, this);
        this._outlineTopLayer.off("mouseup", this.decorateOutlineEnd, this);
        this._outlineTopLayer.off("mouseout", this.decorateOutlineEnd, this);
        this._outlineGraphic.interactive = false;
        this._outlineGraphic.hitArea = null;
        this._outlineTopLayer.interactive = false;
        this._outlineTopLayer.hitArea = null;
        this.decorateOutlineIndex = -1;
        this.drawSelectOutline(undefined);
        this.canvasMain.emit(SYSTEM_ITEM_SELECTED_IN_KARYOTYPE, undefined);
        this.canvasMain.emit(SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE, undefined);
    }
    outlineLayerMove(event) {
        if (!this._outlineData) {
            return;
        }
        if (this.decorateOutlineIndex >= 0) {
            return;
        }
        let pos = this.stage.toLocal(event.global);
        pos = this.pointToImage(pos.x, pos.y);
        let index = this._outlineData.contains(pos);
        if (index >= 0) {
            this.drawSelectOutline(this._outlineData.getOutlineData(index));
        }
        else {
            this.drawSelectOutline(undefined);
        }
    }
    drawSelectOutline(points, isFill = false) {
        this._outlineSelectGraphic.clear();
        if (points == null || points.length == 0) {
            return;
        }
        this._outlineSelectGraphic.beginPath();
        this._outlineSelectGraphic.moveTo(points[0].x * this.textureScale + this.textureOffset.x, points[0].y * this.textureScale + this.textureOffset.y);
        for (let j = 1; j < points.length; j++) {
            this._outlineSelectGraphic.lineTo(points[j].x * this.textureScale + this.textureOffset.x, points[j].y * this.textureScale + this.textureOffset.y);
        }
        this._outlineSelectGraphic.closePath();
        this._outlineSelectGraphic.stroke(0x00ff00);
        if (isFill) {
            this._outlineSelectGraphic.fill(0x00ff00);
        }
    }
    selectOutline(event) {
        this.canvasMain.stopSaveTimer();
        let pos = this.stage.toLocal(event.global);
        pos = this.pointToImage(pos.x, pos.y);
        let index = this._outlineData.contains(pos);
        if (index >= 0) {
            if (this.decorateOutlineIndex == index) {
                //添加
                this.isErase = false;
                let radius = 3 / this.stage.scale.x;
                this._outlineData.setEditorOutline(this.decorateOutlineIndex, pos, radius, this.isErase);
                let data = this._outlineData.getOutlineData(this.decorateOutlineIndex);
                this.isStartDecorateOutline = true;
                this.drawOutline();
                this.drawSelectOutline(data, false);
                this._outlineTopLayer.interactive = true;
                this._outlineTopLayer.hitArea = new PIXI.Rectangle(0, 0, this.stage.width, this.stage.height);
                event.stopPropagation();
                this.onStageMousemove(undefined);
            }
            else {
                //重新选择
                this.decorateOutlineIndex = index;
                if (this.decorateOutlineIndex >= 0) {
                    let data = this._outlineData.getOutlineData(this.decorateOutlineIndex);
                    this.drawSelectOutline(data, false);
                    this.canvasMain.emit(SYSTEM_ITEM_SELECTED_IN_KARYOTYPE, this.boundsDataArr[this.decorateOutlineIndex]);
                    this.canvasMain.emit(SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE, this.boundsDataArr[this.decorateOutlineIndex]);
                    event.stopPropagation();
                    this.onStageMousemove(undefined);
                }
                else {
                    this.drawSelectOutline(undefined, false);
                }
            }
        }
        else {
            if (this.decorateOutlineIndex >= 0) {
                //消除
                this.isErase = true;
                let radius = 3 / this.stage.scale.x;
                this._outlineData.setEditorOutline(this.decorateOutlineIndex, pos, radius, this.isErase);
                let data = this._outlineData.getOutlineData(this.decorateOutlineIndex);
                this.isStartDecorateOutline = true;
                this.drawOutline();
                this.drawSelectOutline(data, false);
                this._outlineTopLayer.interactive = true;
                this._outlineTopLayer.hitArea = new PIXI.Rectangle(0, 0, this.stage.width, this.stage.height);
                event.stopPropagation();
                this.onStageMousemove(undefined);
            }
        }
        // if (this.decorateOutlineIndex < 0) {    //选择轮廓
        //     this.decorateOutlineIndex = index;
        //     if (this.decorateOutlineIndex >= 0) {
        //         let data = this._outlineData.getOutlineData(this.decorateOutlineIndex);
        //         this.drawSelectOutline(data, false);
        //         this.canvasMain.emit(SYSTEM_ITEM_SELECTED_IN_KARYOTYPE, this.boundsDataArr[this.decorateOutlineIndex]);
        //         this.canvasMain.emit(SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE, this.boundsDataArr[this.decorateOutlineIndex]);
        //         event.stopPropagation();
        //     } else {
        //         this.drawSelectOutline(undefined, false);
        //     }
        // } else {
        //     let bo = OutlineTool.contains(this._outlineData.getOutlineData(this.decorateOutlineIndex), pos);
        //     this.isErase = !bo;
        //     this._outlineData.setEditorOutline(this.decorateOutlineIndex, pos, 3, this.isErase);
        //     let data = this._outlineData.getOutlineData(this.decorateOutlineIndex);
        //     //this.boundsDataArr![this.decorateOutlineIndex].points = data;
        //     this.isStartDecorateOutline = true;
        //     this.drawOutline();
        //     this.drawSelectOutline(data, false);
        //     this._outlineTopLayer.interactive = true;
        //     this._outlineTopLayer.hitArea = new PIXI.Rectangle(0, 0, this.stage.width, this.stage.height);
        //     //this.
        //     event.stopPropagation();
        // }
    }
    /** 修饰轮廓 */
    decorateOutline(event) {
        if (!(this.decorateOutlineIndex < 0) && this.isStartDecorateOutline) {
            let pos = this.stage.toLocal(event.global);
            pos = this.pointToImage(pos.x, pos.y);
            let data = this._outlineData.getOutlineData(this.decorateOutlineIndex);
            if (!data || data.length < 0) {
                event.stopPropagation();
                return;
            }
            let radius = 3 / this.stage.scale.x;
            this._outlineData.setEditorOutline(this.decorateOutlineIndex, pos, radius, this.isErase);
            data = this._outlineData.getOutlineData(this.decorateOutlineIndex);
            //this.boundsDataArr![this.decorateOutlineIndex].points = data;
            this.drawOutline();
            this.drawSelectOutline(data, false);
            event.stopPropagation();
        }
    }
    /** 修饰轮廓结束 */
    decorateOutlineEnd(event) {
        if (!(this.decorateOutlineIndex < 0)) {
            if (this.isStartDecorateOutline) {
                let data = this._outlineData.getOutlineData(this.decorateOutlineIndex);
                console.log(this.boundsDataArr.length, this.decorateOutlineIndex);
                let beforeData = this.boundsDataArr[this.decorateOutlineIndex].serialize();
                if (!data || data.length < 3) {
                    let afterData = "";
                    this.canvasMain.emit(ITEM_REMOVED, this.boundsDataArr[this.decorateOutlineIndex]);
                    let memo = new MemoData(beforeData, afterData, OperationType.Delete, "1111111111000000");
                    this.canvasMain.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
                }
                else {
                    this.boundsDataArr[this.decorateOutlineIndex].points = this._outlineData.getOutlineData(this.decorateOutlineIndex);
                    let afterData = this.boundsDataArr[this.decorateOutlineIndex].serialize();
                    if (afterData != beforeData) {
                        this.canvasMain.emit(ITEM_EDITED_IN_PROTOTYPE_PAGE, this.boundsDataArr[this.decorateOutlineIndex]);
                        afterData = this.boundsDataArr[this.decorateOutlineIndex].serialize();
                        let memo = new MemoData(beforeData, afterData, OperationType.Decoration, "0010101111000000");
                        this.canvasMain.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
                    }
                }
            }
            event.stopPropagation();
        }
        this._outlineTopLayer.interactive = false;
        this._outlineTopLayer.hitArea = null;
        this.isStartDecorateOutline = false;
    }
    keryotypeSelect(boundsData) {
        if (this.isMainCanvas) {
            return;
        }
        if (!boundsData) {
            this.removeSelectEff();
            return;
        }
        let index = this.boundsDataArr.findIndex((v) => { return boundsData.id == v.id && boundsData.index == v.index; });
        this.karyotypeSelectOutlineIndex = index;
        if (this.karyotypeSelectOutlineIndex >= 0) {
            let bounds = UtilMath.getPolyBounds(this.boundsDataArr[this.karyotypeSelectOutlineIndex].points);
            let x = (bounds.minX + bounds.maxX) / 2;
            let y = (bounds.minY + bounds.maxY) / 2;
            this.showSelectEff();
            this.stage.focusIn(this._outlineGraphic.toGlobal(new PIXI.Point(this.textureOffset.x + x * this.textureScale, this.textureOffset.y + y * this.textureScale)));
            //this.stage.
        }
    }
    showSelectEff() {
        this.selectEffFill = false;
        this.selectEffTime = 0;
        let data = this._outlineData.getOutlineData(this.karyotypeSelectOutlineIndex);
        this.drawSelectOutline(data, this.selectEffFill);
        // this.pixi.ticker.remove(this.selectEff);
        // this.pixi.ticker.add(this.selectEff);
    }
    removeSelectEff() {
        this.selectEffFill = false;
        this.karyotypeSelectOutlineIndex = -1;
        this.pixi.ticker.remove(this.selectEff);
        this.drawSelectOutline(undefined, false);
    }
    //#endregion
    //#region 图像处理
    /** 设置曲线 */
    setColorCurve(curve) {
        if (this.curve) {
            this.curve.off("curveChange", this.onCurveChanged, this);
        }
        this.curve = curve;
        this.curve.on("curveChange", this.onCurveChanged, this);
    }
    onCurveChanged(curve) {
        this.colorMapFilter.setColorMap(curve.getRGBCurve(), curve.getRCurve(), curve.getGCurve(), curve.getBCurve());
    }
    /** 锐化 */
    sharpness() {
        this.drawMainTexture(true);
        this._mainGraphic.filters = [this.sharpnessFilter];
        this.mainTexture = this.pixi.renderer.extract.texture(this._mainGraphic);
        this._mainGraphic.filters = [this.colorMapFilter];
        this.drawMainTexture(true);
    }
    /** 设置颜色映射 */
    setColorMap(rgbCurve, rCurve, gCurve, bCurve) {
        var _a;
        (_a = this.colorMapFilter) === null || _a === void 0 ? void 0 : _a.setColorMap(rgbCurve, rCurve, gCurve, bCurve);
        this.drawMainTexture(true);
    }
    /** 重制曲线 */
    resetColorMap() {
        var _a;
        (_a = this.colorMapFilter) === null || _a === void 0 ? void 0 : _a.resetMap();
    }
    //#endregion
    //#region 添加箭头
    startArrowEditor() {
        this._editorMarkCanvas.setMode(MarkMode.Arrow);
        this._editorMarkCanvas.resize(this.stage.width, this.stage.height);
    }
    stopArrowEditor() {
        this._editorMarkCanvas.setMode(MarkMode.None);
    }
    //#endregion
    //#region 添加文本框
    startTextEditor() {
        this._editorMarkCanvas.setMode(MarkMode.Text);
        this._editorMarkCanvas.resize(this.stage.width, this.stage.height);
    }
    stopTextEditor() {
        this._editorMarkCanvas.setMode(MarkMode.None);
    }
    //#endregion
    setTestData(jsonStr) {
        let json = JSON.parse(jsonStr);
        let item = json.item;
        let pointList = [];
        for (let i = 0; i < item.length; i++) {
            pointList.push(item[i].point);
        }
        // let data = OutlineTool.union([pointList[5]], false, [pointList[11]], false).points[0];
        // data = OutlineTool.union([data], false, [pointList[21]], false).points[0];
        // console.log(data);
        // pointList.push(data);
        // pointList[5] = pointList[0];
        // pointList[11] = pointList[0]; 
        // pointList[21] = pointList[0];
        console.log(pointList.length);
        let outline = new OutlineData(pointList);
        outline.dirwOutline(this._outlineGraphic, 0xff0000);
        this._numberContainer.removeChildren();
        // let tW = this.mainTexture!.width;
        // let tH = this.mainTexture!.height;
        // let sW;
        // let sH;
        let scale = 1;
        this.textStyle.fontSize = 16 * scale;
        for (let i = 0; i < pointList.length; i++) {
            let points = pointList[i];
            let point = this.calcTextPos(points, scale);
            let text = new PIXI.Text({ text: i.toString(), style: this.textStyle });
            this._numberContainer.addChild(text);
            text.anchor = 0.5;
            text.position = point;
        }
        let d = new OutlineData([pointList[1]]);
        let c = new OutlineData([pointList[3]]);
    }
    getMarkStyleData() {
        return this._editorMarkCanvas.getMarkSettingData();
    }
    setMarkStyleData(data) {
        this._editorMarkCanvas.setMarkSettingData(data);
    }
}
