import { CanvasMode } from "../Core/Canvas/PIXICanvas";
import OriginalImageCanvas from "./OriginalImageCanvas";
/**图像增强canvas */
export default class EnhancedImageCanvas extends OriginalImageCanvas {
    /** 设置Canvas模式 */
    setMode(value) {
        this.removeSelectEff();
        switch (this.currMode) {
            case CanvasMode.OutlineDecorate:
                this.stopOutlineDecorate();
                break;
            case CanvasMode.Segmentation:
                this.stopOverlappingArea();
                break;
            case CanvasMode.ArrowEditor:
                this.stopArrowEditor();
                break;
            case CanvasMode.TextEditor:
                this.stopTextEditor();
                break;
            case CanvasMode.OverlappingArea:
                this.stopOverlappingArea();
                break;
            case CanvasMode.ImageProcessing:
                this._mainGraphic.filters = [];
                break;
        }
        this.currMode = value;
        switch (this.currMode) {
            case CanvasMode.OutlineDecorate:
                this.startOutlineDecorate();
                break;
            case CanvasMode.Segmentation:
                //this.startSegmentation();
                this.startOverlappingArea();
                break;
            case CanvasMode.ArrowEditor:
                this.startArrowEditor();
                break;
            case CanvasMode.TextEditor:
                this.startTextEditor();
                break;
            case CanvasMode.OverlappingArea:
                this.startOverlappingArea();
                break;
            case CanvasMode.ImageProcessing:
                this._mainGraphic.filters = [this.colorMapFilter];
                this.colorMapFilter.resetMap();
                break;
        }
    }
}
