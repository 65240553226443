import ClipCanvas from "./Core/Canvas/ClipCanvas";
import OperationStepSystem, { OperationType } from "./Core/OperationStepSystem";
import { CanvasMode, CanvasType } from "./Core/Canvas/PIXICanvas";
import * as PIXI from "pixi.js";
import KaryotypeCanvas from "./Canvas/KaryotypeCanvas";
import OriginalImageCanvas from "./Canvas/OriginalImageCanvas";
import EnhancedImageCanvas from "./Canvas/EnhancedImageCanvas";
import BoundsData from "./Core/Data/BoundsData";
import { COUNT_CHANGED, ITEM_ADDED, ITEM_CHANGED, ITEM_CROSSCUT_LINESEGMENT, ITEM_EDITED_IN_KARYOTYPE_PAGE, ITEM_EDITED_IN_PROTOTYPE_PAGE, ITEM_GROUP_CHANGED_IN_KARYOTYPE_PAGE, ITEM_REMOVED } from "./Event/ItemEventName";
import { MARK_STYLE_CHANGED, SYSTEM_CANVAS_LOAD_COMPLETE, SYSTEM_CHANGE_CHROMOSOME_COUNT, SYSTEM_CHANGE_OPERATION_WINDOW, SYSTEM_IMAGE_LOAD_COMPLETE, SYSTEM_ITEM_LOAD_COMPLETE, SYSTEM_ITEM_REMOVED, SYSTEM_NEED_SAVE, SYSTEM_OPERATION_STEP_PUSH, SYSTEM_USER_CONFIG_SAVE as SYSTEM_USER_CONFIG_SAVE, SYSTEM_WINDOW_RESIZE } from "./Core/Event/SystemEventName";
import MemoData from "./Core/Data/MemoData";
import { MarkMode } from "./Canvas/MarkEditorCanvas";
import { CurveCanvas } from "./CurveCanvas";
import ColorCurve from "./Core/Tools/Curve/ColorCurve";
import MainData from "./Core/Data/MainData";
import { WindowGloablEvent } from "./Event/WindowGloablEvent";
import Toolbar from "./Component/Toolbar";
export class CanvasMain {
    static getCanvas() {
        return new CanvasMain();
    }
    constructor() {
        this.loadCount = 0;
        this.saveTimer = -1;
        this.boundsDatas = [];
        this.currIndex = 0;
        this._idiogram = 0;
        this.isSaveKaryotypeImg = false;
        this.isSaveOutlineImg = false;
        this.needRecoverSaveTimer = false;
        this._isImageProcessing = false;
        WindowGloablEvent.getSingleton().removeAllEventListener();
        this.mainCanvas = document.getElementById("mainCanvas");
        this.subCanvas1 = document.getElementById("subCanvas1");
        this.subCanvas2 = document.getElementById("subCanvas2");
        // this.subCanvas1.style.height = this.subCanvas1.clientWidth * 0.9 + "px";
        // this.subCanvas2.style.height = this.subCanvas2.clientWidth * 0.9 + "px";
        this.karyotypeCanvas = new KaryotypeCanvas(CanvasType.KaryotypeCanvas, this, true, this.mainCanvas, 0xefefef, () => {
            console.error("karyotypeCanvas create complete.");
            this._mainCanvasWidth = this.karyotypeCanvas.pixi.screen.width;
            this._mainCanvasHeight = this.karyotypeCanvas.pixi.screen.height;
            this.loadComplete();
        }, (e) => { console.error("karyotypeCanvas create failed."); }, this);
        this.originalImageCanvas = new OriginalImageCanvas(CanvasType.OriginalImageCanvas, this, false, this.subCanvas1, 0xefefef, () => {
            console.error("originalImageCanvas create complete.");
            this._subCanvasWidth = this.originalImageCanvas.pixi.screen.width;
            this._subCanvasHeight = this.originalImageCanvas.pixi.screen.height;
            this.originalImageCanvas.isSendCountEvent(true);
            this.loadComplete();
        }, (e) => { console.error("originalImageCanvas create failed."); }, this);
        this.enhancedImageCanvas = new EnhancedImageCanvas(CanvasType.EnhancedImageCanvas, this, false, this.subCanvas2, 0xefefef, () => {
            console.error("enhancedImageCanvas create complete.");
            this.originalImageCanvas.isSendCountEvent(false);
            this.loadComplete();
        }, (e) => { console.error("enhancedImageCanvas create failed."); }, this);
        this.clipCanvas = new ClipCanvas();
        this.operationStepSystem = new OperationStepSystem();
        this.events = new PIXI.EventEmitter();
        this.editorCanvas = this.karyotypeCanvas;
        this.canvasArr = [this.mainCanvas, this.subCanvas1, this.subCanvas2];
        this.pixiCanvasArr = [this.karyotypeCanvas, this.originalImageCanvas, this.enhancedImageCanvas];
        this.canvasArrMap = new Array(this.pixiCanvasArr.length).fill(0).map((_, i) => { return i; });
        this.on(ITEM_EDITED_IN_KARYOTYPE_PAGE, (data) => {
            this.clipCanvas.refreshData(data);
            this.originalImageCanvas.setData(this.boundsDatas);
            this.enhancedImageCanvas.setData(this.boundsDatas);
            this.emit(ITEM_CHANGED, data);
        }, this);
        this.on(ITEM_EDITED_IN_PROTOTYPE_PAGE, (data) => {
            let len = this.clipCanvas.resetCanvasSize(this.img);
            let angle = this.clipCanvas.calculateAngle(data.points);
            data.rotateAngle = angle;
            let dataUrl = this.clipCanvas.getDataURL(len, angle, data.points, this.img, data);
            data.textureData = dataUrl;
            this.originalImageCanvas.setData(this.boundsDatas);
            this.enhancedImageCanvas.setData(this.boundsDatas);
            this.emit(ITEM_CHANGED, data);
        });
        /** 染色体分割 index为分割的染色体的序号 data1为分割后的源染色体，data2为分割出来的染色体 */ //切割时，先改后加，还原时，先删后改。顺序与不能乱
        this.on(ITEM_CROSSCUT_LINESEGMENT, (data) => {
            let index = data[0]; //分割的染色体在数据中的位置
            let original = data[1]; //原数据
            let data1 = data[2]; //切割后的原数据
            let data2 = data[3]; //切割后的新增数据
            let datas = [data1, data2];
            let len = this.clipCanvas.resetCanvasSize(this.img);
            for (let i = 0; i < datas.length; i++) {
                let data = datas[i];
                let angle = this.clipCanvas.calculateAngle(data.points);
                data.rotateAngle = angle;
                let dataUrl = this.clipCanvas.getDataURL(len, angle, data.points, this.img, data);
                data.textureData = dataUrl;
            }
            let beforeData = original.serialize();
            this.boundsDatas[index].copyFrom(data1);
            this.boundsDatas.push(data2);
            this.emit(ITEM_CHANGED, this.boundsDatas[index]); //先改
            this.karyotypeCanvas.setData([data2]); //后加 //顺序逻辑在karyotypeCanvas里先处理
            this.originalImageCanvas.setData(this.boundsDatas);
            this.enhancedImageCanvas.setData(this.boundsDatas);
            let afterData = JSON.stringify([datas[0].serialize(), datas[1].serialize()]);
            let changeData = "001011111101110";
            let memo = new MemoData(beforeData, afterData, OperationType.Segmentation, changeData);
            this.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
        });
        this.on(ITEM_GROUP_CHANGED_IN_KARYOTYPE_PAGE, (data) => {
            this.originalImageCanvas.setData(this.boundsDatas);
            this.enhancedImageCanvas.setData(this.boundsDatas);
        });
        this.on(SYSTEM_OPERATION_STEP_PUSH, (memo) => {
            console.log(memo);
            this.operationStepSystem.addStep(memo);
            let isSaveImg = memo.type == OperationType.Transform || memo.type == OperationType.Decoration || memo.type == OperationType.Segmentation;
            this.saveData(isSaveImg, isSaveImg);
        }, this);
        this.on(SYSTEM_WINDOW_RESIZE, (canvas) => {
            if (canvas.isMainCanvas) {
                this._mainCanvasWidth = canvas.pixi.screen.width;
                this._mainCanvasHeight = canvas.pixi.screen.height;
            }
            else {
                this._subCanvasWidth = canvas.pixi.screen.width;
                this._subCanvasHeight = canvas.pixi.screen.height;
            }
        });
        this.on(COUNT_CHANGED, (count) => {
            this.emit(SYSTEM_CHANGE_CHROMOSOME_COUNT, this.boundsDatas.length + count);
        });
        this.on(ITEM_REMOVED, (data) => { this.removeBoundsData(data); }, this);
        this.on(ITEM_ADDED, (data) => { this.addBoundsData(data); }, this);
        WindowGloablEvent.getSingleton().addEventListener("keydown", ((event) => {
            if (event.ctrlKey) {
                let memo = undefined;
                let isUndo = false;
                if (event.key === "z") {
                    memo = this.operationStepSystem.undo();
                    isUndo = true;
                }
                else if (event.key === "y") {
                    memo = this.operationStepSystem.redo();
                }
                if (!memo) {
                    return;
                }
                console.log(memo);
                if (memo.type == OperationType.Transform) {
                    if (isUndo) {
                        this.karyotypeCanvas.undo(memo);
                    }
                    else {
                        this.karyotypeCanvas.redo(memo);
                    }
                }
                else if (memo.type == OperationType.Decoration || memo.type == OperationType.Annotation) {
                    if (isUndo) {
                        this.karyotypeCanvas.undo(memo);
                        this.originalImageCanvas.undo(memo);
                        this.enhancedImageCanvas.undo(memo);
                    }
                    else {
                        this.karyotypeCanvas.redo(memo);
                        this.originalImageCanvas.redo(memo);
                        this.enhancedImageCanvas.redo(memo);
                    }
                }
                else if (memo.type == OperationType.ImageProcessing) {
                    if (isUndo) {
                        //this.curveUI.setCurveData(memo.beforeData);
                        this.beforeCurveData = memo.beforeData;
                        if (memo.beforeData) {
                            let data = JSON.parse(memo.beforeData);
                            let image = new Image();
                            image.src = data.texture;
                            this.enhancedImageCanvas.textureBase64 = data.texture;
                            image.onload = () => {
                                this.enhancedImageCanvas.setTexture(PIXI.Texture.from(image));
                            };
                            if (data.curve && data.curve != "") {
                                // let colorCurve = new ColorCurve();
                                // colorCurve.setCurveData(data.curve);
                                // console.log(this);
                                // this.enhancedImageCanvas.setColorMap(colorCurve.getRGBCurve(), colorCurve.getRCurve(), colorCurve.getGCurve(), colorCurve.getBCurve());
                            }
                            else {
                                this.enhancedImageCanvas.resetColorMap();
                            }
                        }
                        else {
                            this.enhancedImageCanvas.resetMainTexture();
                            this.enhancedImageCanvas.resetColorMap();
                        }
                    }
                    else {
                        this.beforeCurveData = memo.afterData;
                        if (memo.afterData) {
                            let data = JSON.parse(memo.afterData);
                            let image = new Image();
                            image.src = data.texture;
                            this.enhancedImageCanvas.textureBase64 = data.texture;
                            image.onload = () => {
                                this.enhancedImageCanvas.setTexture(PIXI.Texture.from(image));
                            };
                            if (data.curve && data.curve != "") {
                                // let colorCurve = new ColorCurve();
                                // colorCurve.setCurveData(data.curve);
                                // console.log(this);
                                // this.enhancedImageCanvas.setColorMap(colorCurve.getRGBCurve(), colorCurve.getRCurve(), colorCurve.getGCurve(), colorCurve.getBCurve());
                            }
                            else {
                                this.enhancedImageCanvas.resetColorMap();
                            }
                        }
                    }
                }
                else if (memo.type == OperationType.Count) {
                    if (isUndo) {
                        this.originalImageCanvas.undo(memo);
                    }
                    else {
                        this.originalImageCanvas.redo(memo);
                    }
                }
                else if (memo.type == OperationType.Segmentation) {
                    if (isUndo) {
                        this.karyotypeCanvas.undo(memo);
                    }
                    else {
                        this.karyotypeCanvas.redo(memo);
                    }
                }
                else if (memo.type == OperationType.Delete) {
                    let data = JSON.parse(memo.beforeData);
                    let bData = new BoundsData();
                    bData.copyFrom(data);
                    if (isUndo) {
                        this.addBoundsData(bData);
                    }
                    else {
                        this.removeBoundsData(bData);
                    }
                }
                else {
                    return;
                }
                let isSaveImage = memo.type == OperationType.Transform || memo.type == OperationType.Decoration || memo.type == OperationType.Segmentation;
                this.saveData(isSaveImage, isSaveImage);
            }
            else {
                if (event.key === "Delete") {
                    this.editorCanvas.deleteMark();
                }
                if (event.key === "+") {
                    this.karyotypeCanvas.increasePenSize();
                }
                if (event.key === "-") {
                    this.karyotypeCanvas.decreasePenSize();
                }
            }
        }).bind(this));
        WindowGloablEvent.getSingleton().addEventListener("createCurveCanvas", (event) => {
            console.log("接收到曲线", event.curveCanvas);
            if (!event.curveCanvas) {
                return;
            }
            if (this._curveUI) {
                //this._curveUI.off("editorEnd", this.onEditorEnd, this);
                this._curveUI.off("curveChange", this.onCurveChanged, this);
                this._curveUI.off("curveConfirm", this.onCurveConfirm, this);
                this._curveUI.off("curveCancel", this.onCurveCancel, this);
                this._curveUI.off("sharpness", this.onSharpness, this);
            }
            this._curveUI = event.curveCanvas;
            //this._curveUI.on("editorEnd", this.onEditorEnd, this);
            this._curveUI.on("curveChange", this.onCurveChanged, this);
            this._curveUI.on("curveConfirm", this.onCurveConfirm, this);
            this._curveUI.on("curveCancel", this.onCurveCancel, this);
            this._curveUI.on("sharpness", this.onSharpness, this);
        });
        WindowGloablEvent.getSingleton().addEventListener("switchToCanvas", (event) => {
            this.switchToCanvas(event.data);
        });
        WindowGloablEvent.getSingleton().addEventListener("noneMode", (event) => {
            this.noneMode();
        });
        this.on("autoMarkMode", this.onAutoMarkMode, this);
        for (let i = 0; i < Toolbar.TOOLBAR_IMGS.length; i++) {
            let path = Toolbar.TOOLBAR_IMGS[i];
            let img = new Image();
            img.onload = () => {
                Toolbar.imgElementMap.set(path, img);
            };
            img.src = path;
        }
        this.on(MARK_STYLE_CHANGED, this.markStyleChange, this);
        this.on(SYSTEM_ITEM_LOAD_COMPLETE, this.karyotypeLoadCompete, this);
    }
    isImageProcessing() {
        return this._isImageProcessing;
    }
    get mainCanvasWidth() { return this._mainCanvasWidth; }
    get mainCanvasHeight() { return this._mainCanvasHeight; }
    get subCanvasWidth() { return this._subCanvasWidth; }
    get subCanvasHeight() { return this._subCanvasHeight; }
    get idiogram() { return this._idiogram; }
    get curveUI() {
        return this._curveUI;
    }
    loadComplete() {
        this.loadCount++;
        if (this.loadCount == 3) {
            for (let i = 0; i < this.pixiCanvasArr.length; i++) {
                let canvas = this.pixiCanvasArr[i];
                let index = i;
                canvas.on("click", () => { this.onClickCanvas(index); }, this);
            }
            this.emit(SYSTEM_CANVAS_LOAD_COMPLETE, undefined);
            setInterval((() => {
                if (this.data) {
                    console.log("this.data: ", this.data);
                    this.setDataInternal(this.data);
                    this.data = undefined;
                }
            }).bind(this), 1000);
        }
    }
    saveData(isSaveKaryotypeImg, isSaveOutlineImg, isdelayUpdate = true) {
        if (this.saveTimer != -1) {
            window.clearTimeout(this.saveTimer);
            this.saveTimer = -1;
        }
        this.needRecoverSaveTimer = false;
        this.isSaveKaryotypeImg = isSaveKaryotypeImg;
        this.isSaveOutlineImg = isSaveOutlineImg;
        if (isdelayUpdate) {
            this.saveTimer = window.setTimeout((() => {
                this.emit(SYSTEM_NEED_SAVE, undefined);
                this.saveTimer = -1;
            }).bind(this), 10000);
        }
        else {
            window.clearTimeout(this.saveTimer);
            this.saveTimer = -1;
            this.emit(SYSTEM_NEED_SAVE, undefined);
        }
    }
    onClickCanvas(index, isClear = true) {
        //console.log(this.currIndex, index);
        if (this.currIndex == index) {
            return;
        }
        let j = 1;
        for (let i = 0; i < this.pixiCanvasArr.length; i++) {
            if (i == index) {
                this.pixiCanvasArr[index].changeResizeTo(this.mainCanvas, true);
                this.editorCanvas = this.pixiCanvasArr[index];
            }
            else {
                let subCanvas = this.canvasArr[j++];
                this.pixiCanvasArr[i].changeResizeTo(subCanvas, false);
                if (isClear) {
                    this.pixiCanvasArr[i].setMode(CanvasMode.None);
                }
            }
        }
        this.emit(SYSTEM_CHANGE_OPERATION_WINDOW, this.editorCanvas.canvasType);
        this.currIndex = index;
    }
    onEditorEnd(beforeData, afterData) {
        let changeData = "0000000000000010";
        let memo = new MemoData(beforeData, afterData, OperationType.ImageProcessing, changeData);
        this.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
    }
    stopSaveTimer() {
        if (this.saveTimer != -1) {
            window.clearTimeout(this.saveTimer);
            this.saveTimer = -1;
            this.needRecoverSaveTimer = true;
        }
    }
    recoverSaveTimer() {
        if (this.needRecoverSaveTimer) {
            this.saveData(this.isSaveKaryotypeImg, this.isSaveOutlineImg);
        }
    }
    setData(oData) {
        this.data = oData;
        let boundsList = oData.singleData;
        this.data.singleData = [];
        if (oData && boundsList) {
            for (let i = 0; i < boundsList.length; i++) {
                if (boundsList[i] && boundsList[i].points && boundsList[i].points.length != 0) {
                    this.data.singleData.push(boundsList[i]);
                }
            }
        }
        this._isImageProcessing = false;
        if (oData.curveData && oData.curveData != "") {
            let curveData = JSON.parse(oData.curveData);
            if (curveData.isImageProcessing) {
                this._isImageProcessing = true;
            }
        }
    }
    setTestData() {
        let request = new XMLHttpRequest();
        request.onload = ((ev) => {
            console.log(request.responseText);
            this.originalImageCanvas.setTestData(request.responseText);
        });
        request.open("GET", "./assets/FPB210307-005.A~A.36.BMP.json", true);
        request.send();
    }
    setDataInternal(oData) {
        PIXI.Assets.reset();
        let dataStr = JSON.stringify(oData);
        let dataObj = JSON.parse(dataStr);
        let data = MainData.getDataFromJson(dataObj);
        this.mainData = data;
        this.karyotypeCanvas.clear();
        this.originalImageCanvas.clear();
        this.enhancedImageCanvas.clear();
        this.operationStepSystem.clear();
        this.boundsDatas = [];
        for (let i = 0; i < this.pixiCanvasArr.length; i++) {
            let canvas = this.pixiCanvasArr[i];
            canvas.on("click", () => { this.onClickCanvas(i); }, this);
        }
        let completeCount = 0;
        let mainImg = new Image();
        let enhancedImg;
        let noBGImg;
        let loadComplete = (() => {
            completeCount++;
            if (completeCount != 3) {
                return;
            }
            let texture = PIXI.Texture.from(mainImg);
            let noBGImgTexture = undefined;
            let enhancedTexture = enhancedImg ? PIXI.Texture.from(enhancedImg) : texture;
            if (noBGImg) {
                noBGImgTexture = PIXI.Texture.from(noBGImg);
            }
            let datas = [];
            for (let i = 0; i < data.singleData.length; i++) {
                datas.push(data.singleData[i].clone());
            }
            let len = this.clipCanvas.resetCanvasSize(mainImg);
            for (let i = 0; i < datas.length; i++) {
                let data = datas[i];
                let angle = data.rotateAngle;
                if (angle == undefined || angle == 0) {
                    angle = this.clipCanvas.calculateAngle(data.points);
                }
                data.rotateAngle = angle;
                let dataUrl = this.clipCanvas.getDataURL(len, angle, data.points, mainImg, data);
                data.textureData = dataUrl;
                this.boundsDatas.push(data);
            }
            console.log(datas);
            this.isSaveOutlineImg = !data.outlineImgUrl;
            let isSaveOutlineImg = this.isSaveOutlineImg && (data.karyotypeImgUrl || datas.length == 0);
            if (datas.length == 0) {
                this.isSaveOutlineImg = isSaveOutlineImg = false;
            }
            this.karyotypeCanvas.setData(datas);
            this.originalImageCanvas.setData(this.boundsDatas, texture, noBGImgTexture);
            this.enhancedImageCanvas.setData(this.boundsDatas, enhancedTexture, noBGImgTexture);
            if (isSaveOutlineImg) {
                this.saveData(false, true, false);
            }
            this.texture = texture;
            this.img = mainImg;
            if (data.curveData && data.curveData != "") {
                this.setCurveData(data.curveData);
            }
            if (data.markData && data.markData[0] && data.markData[0] != "") {
                this.karyotypeCanvas.setMarkData(data.markData[0]);
            }
            if (data.markData && data.markData[1] && data.markData[1] != "") {
                this.originalImageCanvas.setMarkData(data.markData[1]);
            }
            if (data.markData && data.markData[2] && data.markData[2] != "") {
                this.enhancedImageCanvas.setMarkData(data.markData[2]);
            }
            if (data.countData && data.countData != "") {
                this.originalImageCanvas.setCountData(data.countData);
            }
            // let t = this.originalImageCanvas.extractToBase64(texture);
            // this.beforeCurveData = JSON.stringify({ texture: t, curve: data.curveData });
            this.originalImageCanvas.pixi.renderer.extract.base64(texture).then((t) => {
                this.beforeCurveData = JSON.stringify({ texture: t, curve: data.curveData });
            });
            this.emit(SYSTEM_IMAGE_LOAD_COMPLETE, undefined);
        }).bind(this);
        mainImg.onload = (() => {
            loadComplete();
        }).bind(this);
        mainImg.onerror = ((e) => {
            console.error("原始图加载错误，流程终止", e);
        }).bind(this);
        mainImg.src = data.mainImgUrl;
        mainImg.setAttribute('crossOrigin', '');
        if (data.noBGImgUrl && data.noBGImgUrl != "") {
            noBGImg = new Image();
            noBGImg.onload = (() => {
                loadComplete();
            }).bind(this);
            noBGImg.onerror = ((e) => {
                console.error("去背景图加载错误", e);
                loadComplete();
            }).bind(this);
            noBGImg.src = data.noBGImgUrl;
            noBGImg.setAttribute('crossOrigin', '');
        }
        else {
            loadComplete();
        }
        if (this.mainData.enhancedImgUrl && this.mainData.enhancedImgUrl != "") {
            enhancedImg = new Image();
            enhancedImg.onload = (() => {
                loadComplete();
            }).bind(this);
            enhancedImg.onerror = ((e) => {
                console.error("增强图加载错误", e);
                loadComplete();
            }).bind(this);
            enhancedImg.src = data.enhancedImgUrl;
            enhancedImg.setAttribute('crossOrigin', '');
        }
        else {
            loadComplete();
        }
    }
    getData() {
        // let nowCanvas = this.editorCanvas;
        if (this.isSaveOutlineImg) {
            // this.switchToCanvas(1, false);
            this.mainData.outlineImgUrl = this.originalImageCanvas.saveOutlineImg();
            this.mainData.outlineImgUrl1 = this.originalImageCanvas.saveShelterOutlineImg(true);
            this.mainData.outlineImgUrl2 = this.originalImageCanvas.saveShelterOutlineImg(false);
        }
        if (this.isSaveKaryotypeImg) {
            // this.switchToCanvas(0, false);
            // this.mainData.karyotypeImgUrl = this.karyotypeCanvas.saveImage();
            // this.mainData.karyotypeNoXYImgUrl = this.karyotypeCanvas.saveImageNoXY();
            let canvas = this.karyotypeCanvas.rootCanvas;
            if (canvas == this.mainCanvas) {
                this.mainData.karyotypeImgUrl = this.karyotypeCanvas.saveImage();
                this.mainData.karyotypeNoXYImgUrl = this.karyotypeCanvas.saveImageNoXY();
            }
            else {
                this.karyotypeCanvas.changeResizeTo(this.mainCanvas, true);
                this.mainData.karyotypeImgUrl = this.karyotypeCanvas.saveImage();
                this.mainData.karyotypeNoXYImgUrl = this.karyotypeCanvas.saveImageNoXY();
                this.karyotypeCanvas.changeResizeTo(canvas, true);
            }
        }
        // if(nowCanvas != this.editorCanvas) {
        //     if(nowCanvas == this.karyotypeCanvas) { this.switchToCanvas(0, false); }
        //     else if(nowCanvas == this.originalImageCanvas) { this.switchToCanvas(1, false); }
        //     else if(nowCanvas == this.enhancedImageCanvas) { this.switchToCanvas(2, false); }
        // }
        this.mainData.curveData = this.getCurveData();
        this.mainData.countData = this.originalImageCanvas.getCountData();
        this.mainData.markData = [this.karyotypeCanvas.getMarkData(), this.originalImageCanvas.getMarkData(), this.enhancedImageCanvas.getMarkData()];
        this.mainData.curveData = this.getCurveData();
        this.mainData.singleData = [];
        for (let i = 0; i < this.boundsDatas.length; i++) {
            this.mainData.singleData.push(this.boundsDatas[i].clone());
        }
        if (this.enhancedImageCanvas.textureBase64 && this.enhancedImageCanvas.textureBase64 != "") {
            this.mainData.enhancedImgUrl = this.enhancedImageCanvas.textureBase64;
        }
        console.log(this.mainData);
        return this.mainData;
    }
    getMainData() {
        this.mainData.countData = this.originalImageCanvas.getCountData();
        this.mainData.markData = [this.karyotypeCanvas.getMarkData(), this.originalImageCanvas.getMarkData()];
        return this.mainData;
    }
    removeBoundsData(value) {
        console.log("移除Item", value);
        let data = this.boundsDatas.find((item) => { return value.index == item.index && value.id == item.id; });
        if (!data) {
            console.log("data not found");
            return;
        }
        let index = this.boundsDatas.indexOf(data);
        if (index != -1) {
            this.boundsDatas.splice(index, 1);
            this.emit(SYSTEM_ITEM_REMOVED, value);
        }
        this.originalImageCanvas.removeItem(value);
        this.enhancedImageCanvas.removeItem(value);
    }
    addBoundsData(value) {
        this.boundsDatas.push(value);
        this.originalImageCanvas.setData(this.boundsDatas);
        this.enhancedImageCanvas.setData(this.boundsDatas);
        this.karyotypeCanvas.setData([value]);
    }
    setShowBounds(value) {
        console.log("setShowBounds", value);
        this.originalImageCanvas.showOutline(value);
        this.enhancedImageCanvas.showOutline(value);
    }
    setShowId(value) {
        console.log("setShowId", value);
        this.originalImageCanvas.showNumber(value);
        this.enhancedImageCanvas.showNumber(value);
    }
    setShowMark(value) {
        console.log("setShowMark", value);
        this.originalImageCanvas.showMark(value);
        this.enhancedImageCanvas.showMark(value);
        this.karyotypeCanvas.isShowMark = value;
    }
    setNoBGImg(value) {
        console.log("setNoBGImg", value);
        this.originalImageCanvas.showMainTexture(value);
    }
    undo() {
        let memo = this.operationStepSystem.undo();
        if (!memo) {
            return;
        }
        console.log(memo);
        if (memo.type == OperationType.Transform) {
            this.karyotypeCanvas.undo(memo);
        }
        else if (memo.type == OperationType.Decoration || memo.type == OperationType.Annotation) {
            this.karyotypeCanvas.undo(memo);
            this.originalImageCanvas.undo(memo);
            this.enhancedImageCanvas.undo(memo);
        }
        else if (memo.type == OperationType.ImageProcessing) {
            this.beforeCurveData = memo.beforeData;
            if (memo.beforeData) {
                let data = JSON.parse(memo.beforeData);
                let image = new Image();
                image.src = data.texture;
                this.enhancedImageCanvas.textureBase64 = data.texture;
                image.onload = () => {
                    this.enhancedImageCanvas.setTexture(PIXI.Texture.from(image));
                };
                if (data.curve && data.curve != "") {
                    // let colorCurve = new ColorCurve();
                    // colorCurve.setCurveData(data.curve);
                    // console.log(this);
                    // this.enhancedImageCanvas.setColorMap(colorCurve.getRGBCurve(), colorCurve.getRCurve(), colorCurve.getGCurve(), colorCurve.getBCurve());
                }
                else {
                    this.enhancedImageCanvas.resetColorMap();
                }
            }
            else {
                this.enhancedImageCanvas.resetMainTexture();
                this.enhancedImageCanvas.resetColorMap();
            }
        }
        else if (memo.type == OperationType.Count) {
            this.originalImageCanvas.undo(memo);
        }
        else if (memo.type == OperationType.Segmentation) {
            this.karyotypeCanvas.undo(memo);
        }
        else {
            return;
        }
        let isSaveImg = memo.type == OperationType.Transform || memo.type == OperationType.Decoration || memo.type == OperationType.Segmentation;
        this.saveData(isSaveImg, isSaveImg);
    }
    redo() {
        let memo = this.operationStepSystem.redo();
        if (!memo) {
            return;
        }
        console.log(memo);
        if (memo.type == OperationType.Transform) {
            this.karyotypeCanvas.redo(memo);
        }
        else if (memo.type == OperationType.Decoration || memo.type == OperationType.Annotation) {
            this.karyotypeCanvas.redo(memo);
            this.originalImageCanvas.redo(memo);
            this.enhancedImageCanvas.redo(memo);
        }
        else if (memo.type == OperationType.ImageProcessing) {
            this.beforeCurveData = memo.afterData;
            if (memo.afterData) {
                let data = JSON.parse(memo.afterData);
                let image = new Image();
                image.src = data.texture;
                this.enhancedImageCanvas.textureBase64 = data.texture;
                image.onload = () => {
                    this.enhancedImageCanvas.setTexture(PIXI.Texture.from(image));
                };
                if (data.curve && data.curve != "") {
                    // let colorCurve = new ColorCurve();
                    // colorCurve.setCurveData(data.curve);
                    // console.log(this);
                    // this.enhancedImageCanvas.setColorMap(colorCurve.getRGBCurve(), colorCurve.getRCurve(), colorCurve.getGCurve(), colorCurve.getBCurve());
                }
                else {
                    this.enhancedImageCanvas.resetColorMap();
                }
            }
        }
        else if (memo.type == OperationType.Count) {
            this.originalImageCanvas.redo(memo);
        }
        else if (memo.type == OperationType.Segmentation) {
            this.karyotypeCanvas.redo(memo);
        }
        else {
            return;
        }
        let isSaveImg = memo.type == OperationType.Transform || memo.type == OperationType.Decoration || memo.type == OperationType.Segmentation;
        this.saveData(isSaveImg, isSaveImg);
    }
    outlineDecorate() {
        this.editorCanvas.setMode(CanvasMode.OutlineDecorate);
    }
    segmentation() {
        this.editorCanvas.setMode(CanvasMode.Segmentation);
    }
    countMode() {
        this.editorCanvas.setMode(CanvasMode.Count);
    }
    centromere() {
        this.editorCanvas.setMode(CanvasMode.Centromere);
    }
    arrowMode() {
        this.editorCanvas.setMode(CanvasMode.ArrowEditor);
    }
    textMode() {
        this.editorCanvas.setMode(CanvasMode.TextEditor);
    }
    noneMode() {
        this.editorCanvas.setMode(CanvasMode.None);
    }
    imageProcessing() {
        this.editorCanvas.setMode(CanvasMode.ImageProcessing);
    }
    overlappingArea() {
        this.editorCanvas.setMode(CanvasMode.OverlappingArea);
    }
    saveImg() {
        this.originalImageCanvas.downloadImage(this.mainCanvas);
        this.enhancedImageCanvas.downloadImage(this.mainCanvas);
        this.karyotypeCanvas.downloadImage(this.mainCanvas);
    }
    /** 切换到图， 0为核型分析图， 1为原型图， 2为图像处理图 */
    switchToCanvas(index, isClear = true) {
        this.onClickCanvas(index, isClear);
    }
    /** 切换到核型图 */
    switchToKaryotype() {
        this.onClickCanvas(0);
    }
    /** 切换到原型图 */
    switchToOriginal() {
        this.onClickCanvas(1);
    }
    /** 切换到图像处理图 */
    switchToEnhanced() {
        this.onClickCanvas(2);
    }
    setIdiogram(value) {
        this._idiogram = value;
    }
    onAutoMarkMode(mode) {
        switch (mode) {
            case MarkMode.Arrow:
                this.editorCanvas.setMode(CanvasMode.ArrowEditor);
                this.emit("autoSwitchMode", CanvasMode.ArrowEditor);
                break;
            case MarkMode.Text:
                this.editorCanvas.setMode(CanvasMode.TextEditor);
                this.emit("autoSwitchMode", CanvasMode.TextEditor);
                break;
        }
    }
    on(event, fn, context) {
        this.events.on(event, fn, context);
    }
    off(event, fn, context, once) {
        this.events.off(event, fn, context, once);
    }
    once(event, fn, context) {
        this.events.once(event, fn, context);
    }
    emit(event, args) {
        this.events.emit(event, args);
    }
    onCurveChanged(curve) {
        if (this.editorCanvas && this.editorCanvas.setColorMap) {
            this.editorCanvas.setColorMap(curve.getRGBCurve(), curve.getRCurve(), curve.getGCurve(), curve.getBCurve());
        }
    }
    onSharpness() {
        if (this.editorCanvas && this.editorCanvas.sharpness) {
            this.editorCanvas.sharpness();
        }
    }
    // public initCurve() {
    //     if (this.editorCanvas == this.originalImageCanvas) {
    //         this.enhancedImageCanvas.getTextureBase64((t) => {
    //             let image = new Image();
    //             image.src = t;
    //             image.onload = () => {
    //                 this.originalImageCanvas.setTexture(PIXI.Texture.from(image));
    //                 //this.enhancedImageCanvas.pixi.renderer.extract.download(this.enhancedImageCanvas.getTexture() as PIXI.Texture);
    //             }
    //         })
    //     }
    // }
    /** set */
    setImageProcessingCurve(jsonStr) {
        let curve = new ColorCurve();
        curve.setCurveData(JSON.parse(jsonStr));
        let canvas = this.originalImageCanvas;
        this.curveData = curve.getCurveData();
        this._isImageProcessing = true;
        canvas.setColorMap(curve.getRGBCurve(), curve.getRCurve(), curve.getGCurve(), curve.getBCurve());
        canvas.getTextureBase64((t) => {
            let afterData = JSON.stringify({ texture: t, curve: this.curveData });
            let changeData = "0000000000000010";
            let memo = new MemoData(this.beforeCurveData, afterData, OperationType.ImageProcessing, changeData);
            this.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
            //this.enhancedImageCanvas.setColorMap(curve.getRGBCurve(), curve.getRCurve(), curve.getGCurve(), curve.getBCurve());
            this.enhancedImageCanvas.textureBase64 = t;
            let image = new Image();
            image.src = t;
            image.onload = () => {
                this.enhancedImageCanvas.setTexture(PIXI.Texture.from(image));
                //this.enhancedImageCanvas.pixi.renderer.extract.download(this.enhancedImageCanvas.getTexture() as PIXI.Texture);
            };
            this.originalImageCanvas.resetColorMap();
            this.originalImageCanvas.resetMainTexture();
            if (this.editorCanvas != this.enhancedImageCanvas) {
                this.onClickCanvas(2);
            }
            this.beforeCurveData = afterData;
            this.enhancedImageCanvas.showMask(false);
            canvas.setMode(CanvasMode.None);
        });
    }
    /** 曲线确认 */
    onCurveConfirm() {
        let curve = this._curveUI.curve;
        let canvas = this.editorCanvas;
        this.curveData = curve.getCurveData();
        this._isImageProcessing = true;
        canvas.getTextureBase64((t) => {
            let afterData = JSON.stringify({ texture: t, curve: this.curveData });
            let changeData = "0000000000000010";
            let memo = new MemoData(this.beforeCurveData, afterData, OperationType.ImageProcessing, changeData);
            this.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
            //this.enhancedImageCanvas.setColorMap(curve.getRGBCurve(), curve.getRCurve(), curve.getGCurve(), curve.getBCurve());
            this.enhancedImageCanvas.textureBase64 = t;
            let image = new Image();
            image.src = t;
            image.onload = () => {
                this.enhancedImageCanvas.setTexture(PIXI.Texture.from(image));
                //this.enhancedImageCanvas.pixi.renderer.extract.download(this.enhancedImageCanvas.getTexture() as PIXI.Texture);
            };
            this.originalImageCanvas.resetColorMap();
            this.originalImageCanvas.resetMainTexture();
            if (this.editorCanvas != this.enhancedImageCanvas) {
                this.onClickCanvas(2);
            }
            this.beforeCurveData = afterData;
            this.enhancedImageCanvas.showMask(false);
            canvas.setMode(CanvasMode.None);
        });
    }
    /** 曲线取消 */
    onCurveCancel() {
        if (!this.beforeCurveData) {
            let canvas = this.editorCanvas;
            if (canvas.setMode) {
                canvas.setMode(CanvasMode.None);
            }
            return;
        }
        let data = JSON.parse(this.beforeCurveData);
        console.log(data);
        if (data.curve && data.curve != "") {
            // this._curveUI.setCurveData(data.curve);
            // let colorCurve = new ColorCurve();
            // colorCurve.setCurveData(data.curve);
            // this.enhancedImageCanvas.setColorMap(colorCurve.getRGBCurve(), colorCurve.getRCurve(), colorCurve.getGCurve(), colorCurve.getBCurve());
        }
        else {
            this.enhancedImageCanvas.resetColorMap();
        }
        this.originalImageCanvas.resetColorMap();
        this.originalImageCanvas.resetMainTexture();
        if (data.texture) {
            let image = new Image();
            image.src = data.texture;
            image.onload = () => {
                this.enhancedImageCanvas.setTexture(PIXI.Texture.from(image));
                //this.enhancedImageCanvas.pixi.renderer.extract.download(this.enhancedImageCanvas.getTexture() as PIXI.Texture);
            };
        }
        else {
            this.enhancedImageCanvas.resetMainTexture();
        }
        let canvas = this.editorCanvas;
        if (canvas.setMode) {
            canvas.setMode(CanvasMode.None);
        }
    }
    /** 获取曲线数据 */
    getCurveData() {
        return JSON.stringify({ isImageProcessing: this._isImageProcessing, curve: this.curveData, });
        // if (!this.curveUI) {
        //     return ColorCurve.getDefaultData();
        // }
        // return this._curveUI.getCurveData();
    }
    /** 设置曲线数据 */
    setCurveData(data) {
        var _a;
        console.log("设置曲线");
        let dataJson = JSON.parse(data);
        data = dataJson.curve;
        if (data && data != "") {
            this.enhancedImageCanvas.showMask(false);
        }
        (_a = this._curveUI) === null || _a === void 0 ? void 0 : _a.setCurveData(data);
        this.curveData = data;
        // let colorCurve = new ColorCurve();
        // colorCurve.setCurveData(this.curveData);
        // this.enhancedImageCanvas.setColorMap(colorCurve.getRGBCurve(), colorCurve.getRCurve(), colorCurve.getGCurve(), colorCurve.getBCurve());
    }
    destroy() {
        WindowGloablEvent.getSingleton().removeAllEventListener();
    }
    markStyleChange() {
        console.log("标记设置保存");
        let data = this.getMarkStyleData();
        this.setMarkStyleData(data);
        this.emit(SYSTEM_USER_CONFIG_SAVE, JSON.stringify(data));
    }
    setUserConfig(dataStr) {
        if (!dataStr) {
            return;
        }
        this.setMarkStyleData(JSON.parse(dataStr));
    }
    getUserConfig() {
        return JSON.stringify(this.getMarkStyleData());
    }
    getMarkStyleData() {
        let data = {};
        data.markStyle = this.editorCanvas.getMarkStyleData();
        return data;
    }
    setMarkStyleData(data) {
        if (data && data.markStyle) {
            data = data.markStyle;
            this.originalImageCanvas.setMarkStyleData(data);
            this.enhancedImageCanvas.setMarkStyleData(data);
            this.karyotypeCanvas.setMarkStyleData(data);
        }
    }
    karyotypeLoadCompete() {
        if (!this.mainData.karyotypeImgUrl) {
            this.saveData(true, this.isSaveOutlineImg, false);
        }
    }
}
window.CanvasMain = CanvasMain;
window.CurveCanvas = CurveCanvas;
