import * as PIXI from "pixi.js";
import ChromosomeItem from "./ChromosomeItem";
import { ITEM_MOVE_DRAG_START, ITEM_MOVE_DRAG_END, ITEM_SELECTED, ITEM_MOVE_IN, ITEM_MOVE_OUT } from "../Event/ItemEventName";
import UtilMath from "../Core/Tools/UtilMath";
export default class ChromosomeGroup extends PIXI.Container {
    constructor(id, width, height, row, canvas) {
        super();
        this.items = [];
        this.itemMargin = 0;
        this.color = 0x000000;
        this.isMar = false;
        this.isXY = false;
        this._id = id;
        this.row = row;
        this.canvas = canvas;
        let idStr = id < 23 ? id.toString() : id === 23 ? 'X' : id === 24 ? 'Y' : 'mar';
        this.idText = new PIXI.Text({
            text: idStr,
            style: {
                fontFamily: 'Arial',
                fontSize: Math.round(height / 20),
                fill: 0x000000,
                align: 'center',
            }
        });
        if (id == 23 || id == 24) {
            this.isXY = true;
        }
        else if (id >= 25) {
            this.isMar = true;
        }
        this.addChild(this.idText);
        this.lineGraphics = new PIXI.Graphics();
        this.addChild(this.lineGraphics);
        this.chromosomeContainer = new PIXI.Container();
        this.addChild(this.chromosomeContainer);
        this.boundsArea = new PIXI.Rectangle(0, 0, width, height);
        this.chromosomeContainer.boundsArea = new PIXI.Rectangle(0, 0, width, height);
        this.canvas.on(ITEM_SELECTED, (item) => {
            if (item.id == this._id) {
                this.parent.setChildIndex(this, this.parent.children.length - 1);
                this.chromosomeContainer.setChildIndex(item, this.chromosomeContainer.children.length - 1);
                this.parent.parent.setChildIndex(this.parent, this.parent.parent.children.length - 1);
            }
        }, this);
        this.canvas.on(ITEM_MOVE_DRAG_START, () => {
            this.chromosomeContainer.interactive = true;
            this.chromosomeContainer.hitArea = this.chromosomeContainer.boundsArea;
        }, this);
        this.canvas.on(ITEM_MOVE_DRAG_END, () => {
            this.chromosomeContainer.interactive = false;
            this.chromosomeContainer.hitArea = undefined;
        }, this);
        this.chromosomeContainer.on("mouseenter", (e) => {
            this.canvas.emit(ITEM_MOVE_IN, this.id);
        }, this);
        this.chromosomeContainer.on("mouseleave", (e) => {
            this.canvas.emit(ITEM_MOVE_OUT, this.id);
        }, this);
        this.rechangeSize(true);
    }
    get id() { return this._id; }
    get minWidth() { return (this.row.itemOriginalWidth - this.itemMargin * 2) / 2 - this.itemMargin; }
    get itemCount() { return this.items.length; }
    rechangeSize(isNeedResetItemSize) {
        let items = this.items;
        let textHeight = Math.round(this.boundsArea.height / 20);
        this.itemMargin = textHeight;
        let contentHeight = this.boundsArea.height - textHeight * 2;
        let minContentWidth = this.row.itemOriginalWidth - textHeight * 2;
        let contentWidth = textHeight * (items.length - 1);
        for (let item of items) {
            if (isNeedResetItemSize) {
                item.resetSize(this.minWidth, contentHeight);
            }
            else {
                item.setPosY();
            }
            contentWidth += UtilMath.calculateBoundWidth(item.width, item.height, item.rotation);
        }
        contentWidth = Math.max(contentWidth, minContentWidth);
        this.boundsArea.width = contentWidth + textHeight * 2;
        this.idText.style.fontSize = textHeight;
        this.idText.style.fill = this.color;
        this.idText.x = (this.boundsArea.width - this.idText.width) / 2;
        this.idText.y = this.boundsArea.height - this.idText.height;
        this.lineGraphics.clear();
        this.lineGraphics.setStrokeStyle({ color: this.color, width: 1 });
        this.lineGraphics.moveTo(0, contentHeight);
        this.lineGraphics.lineTo(0, this.boundsArea.height - textHeight * 1.5);
        this.lineGraphics.lineTo(this.boundsArea.width, this.boundsArea.height - textHeight * 1.5);
        this.lineGraphics.lineTo(this.boundsArea.width, contentHeight);
        this.lineGraphics.stroke();
        this.chromosomeContainer.boundsArea.width = contentWidth;
        this.chromosomeContainer.boundsArea.height = contentHeight;
        this.chromosomeContainer.x = (this.boundsArea.width - contentWidth) / 2;
        this.chromosomeContainer.y = 0;
        //this.resetItemIndex();
        if (items.length == 1) {
            items[0].x = this.chromosomeContainer.width / 2;
            return;
        }
        else if (items.length == 0) {
            if (this.isMar) {
                this.showBG(false);
            }
            else {
                this.setColor(0xff0000);
            }
            return;
        }
        else if (this.isMar) {
            this.showBG(true);
        }
        let sumWidth = 0;
        for (let item of items) {
            sumWidth += UtilMath.calculateBoundWidth(item.width, item.height, item.rotation);
        }
        let margin = (contentWidth - sumWidth) / (items.length - 1);
        let nowItemWidth = UtilMath.calculateBoundWidth(items[0].width, items[0].height, items[0].rotation);
        let tempPosX = nowItemWidth / 2;
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            item.x = tempPosX;
            tempPosX += nowItemWidth / 2;
            if (i < items.length - 1) {
                nowItemWidth = UtilMath.calculateBoundWidth(items[i + 1].width, items[i + 1].height, items[i + 1].rotation);
                tempPosX += (margin + nowItemWidth / 2);
            }
        }
    }
    createItem(paths) {
        let indexList = [];
        let newData = [...paths];
        newData.sort((a, b) => {
            if (!a) {
                return 1;
            }
            if (!b) {
                return -1;
            }
            return a.index - b.index;
        });
        let childItemList = this.chromosomeContainer.children;
        for (let i = 0; i < newData.length; i++) {
            let j = 0;
            if (!newData[i]) {
                indexList.push(i + childItemList.length);
                continue;
            }
            for (; j < childItemList.length; j++) {
                let item = childItemList[j];
                if (newData[i].index <= item.data.index) {
                    break;
                }
            }
            indexList.push(j + i);
        }
        for (let i = 0; i < newData.length; i++) {
            if (!newData[i]) {
                continue;
            }
            let item = new ChromosomeItem(newData[i], this.minWidth, this.chromosomeContainer.height, this.canvas);
            this.chromosomeContainer.addChildAt(item, indexList[i]);
        }
        for (let i = 0; i < this.chromosomeContainer.children.length; i++) {
            this.chromosomeContainer.getChildAt(i).x = i * 10;
        }
        this.resetItemIndex();
    }
    setItem(item) {
        let globalPos = item.getGlobalPosition();
        let localPos = this.chromosomeContainer.toLocal(globalPos);
        this.chromosomeContainer.addChild(item);
        item.x = localPos.x;
        item.y = localPos.y;
        item.setId(this._id);
        this.resetItemIndex();
        this.rechangeSize(false);
    }
    setItemShow(value) {
        for (let i = 0; i < this.items.length; i++) {
            this.items[i].visible = value;
        }
    }
    recoverItem(item, from, to, isChangeGroup) {
        this.chromosomeContainer.addChild(item);
        if (this.items.length != 0) {
            let index = 0;
            let addNum = -1;
            if (to.index > 0) {
                if (!isChangeGroup) {
                    index = from.index < to.index ? to.index : to.index - 1;
                }
                else {
                    index = to.index - 1;
                }
                addNum = 1;
            }
            let nowItem = this.getItem(index);
            item.x = nowItem.x + addNum;
        }
        item.setId(this._id);
        this.resetItemIndex();
        this.rechangeSize(false);
    }
    removeItem(item) {
        if (this.chromosomeContainer.children.indexOf(item) != -1) {
            let itemIndex = this.items.indexOf(item);
            if (itemIndex != -1) {
                this.items.splice(itemIndex, 1);
            }
            item.destroy(true);
            this.canvas.changeWidth(false);
            console.trace();
            this.resetItemIndex();
        }
    }
    resetItemIndex() {
        this.items = [];
        for (let i = 0; i < this.chromosomeContainer.children.length; i++) {
            this.items.push(this.chromosomeContainer.children[i]);
        }
        for (let i = 0; i < this.items.length; i++) {
            for (let j = i + 1; j < this.items.length; j++) {
                if (this.items[i].x > this.items[j].x) {
                    let temp = this.items[i];
                    this.items[i] = this.items[j];
                    this.items[j] = temp;
                }
            }
        }
        for (let i = 0; i < this.items.length; i++) {
            this.items[i].setIndex(i);
        }
        if (this.isMar) {
            this.showBG(this.items.length != 0);
        }
        else {
            if ((this.isXY && this.items.length == 1) || (!this.isXY && this.items.length == 2)) {
                this.setColor(0x000000);
            }
            else {
                this.setColor(0xff0000);
            }
        }
    }
    getItem(index) {
        return this.items.find(item => item.index === index);
    }
    setIdiogram(value) {
        for (let i = 0; i < this.items.length; i++) {
            this.items[i].setIdiogram(value);
        }
    }
    showBG(isShow) {
        this.idText.visible = isShow;
        this.lineGraphics.visible = isShow;
    }
    setColor(color) {
        if (this.color == color) {
            return;
        }
        this.color = color;
        this.idText.style.fill = this.color;
        let textHeight = Math.round(this.boundsArea.height / 20);
        let contentHeight = this.boundsArea.height - textHeight * 2;
        this.lineGraphics.clear();
        this.lineGraphics.setStrokeStyle({ color: color, width: 1 });
        this.lineGraphics.moveTo(0, contentHeight);
        this.lineGraphics.lineTo(0, this.boundsArea.height - textHeight * 1.5);
        this.lineGraphics.lineTo(this.boundsArea.width, this.boundsArea.height - textHeight * 1.5);
        this.lineGraphics.lineTo(this.boundsArea.width, contentHeight);
        this.lineGraphics.stroke();
    }
}
