import * as PIXI from "pixi.js";
import ToolbarBtn from "./ToolbarBtn";
import Container from "../Core/Component/Container";
class Toolbar extends Container {
    constructor(canvas) {
        super();
        this.imgLoadCount = 0;
        this.toolbarImgs = new Map();
        this.isMouseDown = false;
        this.mousePos = new PIXI.Point(0, 0);
        this.canvas = canvas;
        this.checkImageLoadCount();
    }
    onResize() {
        this.boundsArea = new PIXI.Rectangle(0, 0, this.canvas.pixi.stage.width, this.canvas.pixi.stage.height);
        if (this.toolbar) {
            this.toolbar.position = new PIXI.Point(Toolbar.sharePosition.x, Toolbar.sharePosition.y);
            this.checkPosition();
            this.resetBtnState();
        }
    }
    onDestroy() {
        var _a;
        this.toolbarImgs.clear();
        (_a = this.toolbar) === null || _a === void 0 ? void 0 : _a.off("mousedown", this.onMouseDown, this);
        this.off("mouseup", this.onMouseUp, this);
        this.canvas.pixi.renderer.canvas.removeEventListener("mouseout", this.onMouseUpFuc);
        this.off("mousemove", this.onMouseMove, this);
    }
    checkImageLoadCount() {
        if (Toolbar.imgElementMap.size != Toolbar.TOOLBAR_IMGS.length) {
            setTimeout((() => {
                this.checkImageLoadCount();
            }).bind(this), 100);
            return;
        }
        if (this.destroyed) {
            return;
        }
        //let values = Object.values(Object.fromEntries(Toolbar.imgElementMap));
        //for(let i = 0; i < values.length; i++) { PIXI.Assets.cache.remove(values[i]); }
        //let keys = Toolbar.imgElementMap.keys();
        //for(let key in keys) { PIXI.Assets.cache.remove(key); }
        //PIXI.Assets.cache.reset();
        for (let i = 0; i < Toolbar.TOOLBAR_IMGS.length; i++) {
            let path = Toolbar.TOOLBAR_IMGS[i];
            let name = path.substring(path.lastIndexOf("/") + 1, path.lastIndexOf("."));
            this.loadImg(name, Toolbar.imgElementMap.get(path));
        }
    }
    loadImg(imgNmae, img) {
        PIXI.Assets.load(img).then((item) => {
            this.imgLoadCount++;
            this.toolbarImgs.set(imgNmae, item);
            if (this.imgLoadCount == Toolbar.TOOLBAR_IMGS.length) {
                this.loadComplete();
            }
        });
    }
    loadComplete() {
        if (this.destroyed) {
            return;
        }
        let toolbar = new PIXI.Container();
        this.toolbar = toolbar;
        this.addChild(toolbar);
        this.boundsArea = new PIXI.Rectangle(0, 0, this.canvas.pixi.stage.width, this.canvas.pixi.stage.height);
        this.hitArea = undefined;
        let bg = new PIXI.Sprite(this.toolbarImgs.get("bg"));
        toolbar.addChild(bg);
        toolbar.boundsArea = new PIXI.Rectangle(0, 0, bg.width, bg.height);
        let container = new PIXI.Container();
        toolbar.addChild(container);
        container.boundsArea = new PIXI.Rectangle(0, 0, 300, 38);
        container.x = 50;
        container.y = 15;
        let lines = new PIXI.Graphics();
        container.addChild(lines);
        lines.setStrokeStyle({ color: 0xE5E6EB, width: 2.5 });
        lines.moveTo(72, 7);
        lines.lineTo(72, 31);
        lines.moveTo(228, 7);
        lines.lineTo(228, 31);
        lines.stroke();
        lines.interactive = true;
        this.undoBtn = new ToolbarBtn(this.toolbarImgs.get("undo_0"), this.toolbarImgs.get("undo_1"), "撤销", this.onUndoBtnClick.bind(this));
        this.redoBtn = new ToolbarBtn(this.toolbarImgs.get("redo_0"), this.toolbarImgs.get("redo_1"), "还原", this.onRedoBtnClick.bind(this));
        this.outlineBtn = new ToolbarBtn(this.toolbarImgs.get("outline_1"), this.toolbarImgs.get("outline_0"), "显示边线", this.onOutlineBtnClick.bind(this));
        this.markBtn = new ToolbarBtn(this.toolbarImgs.get("mark_1"), this.toolbarImgs.get("mark_0"), "显示标注", this.onMarkBtnClick.bind(this));
        this.idBtn = new ToolbarBtn(this.toolbarImgs.get("id_1"), this.toolbarImgs.get("id_0"), "显示编号", this.onIDBtnClick.bind(this));
        this.noBGImgBtn = new ToolbarBtn(this.toolbarImgs.get("noBGImg_0"), this.toolbarImgs.get("noBGImg_1"), "显示去背景图", this.onNoBGImgBtnClick.bind(this));
        this.zoomInBtn = new ToolbarBtn(this.toolbarImgs.get("zoomIn_0"), this.toolbarImgs.get("zoomIn_1"), "放大", this.onZoomInBtnClick.bind(this));
        this.zoomOutBtn = new ToolbarBtn(this.toolbarImgs.get("zoomOut_0"), this.toolbarImgs.get("zoomOut_1"), "缩小", this.onZoomOutBtnClick.bind(this));
        let btnArr = [this.undoBtn, this.redoBtn, this.outlineBtn, this.markBtn, this.idBtn, this.noBGImgBtn, this.zoomInBtn, this.zoomOutBtn];
        let lineCount = 0;
        for (let i = 0; i < btnArr.length; i++) {
            if (i == 2 || i == 6) {
                lineCount++;
            }
            let btn = btnArr[i];
            btn.scale = 0.5;
            container.addChild(btn);
            btn.position = new PIXI.Point(24 * i + 12 * (i + lineCount), 7);
            btn.interactive = true;
        }
        this.interactive = true;
        toolbar.interactive = true;
        toolbar.on("mousedown", this.onMouseDown, this);
        this.on("mouseup", this.onMouseUp, this);
        this.onMouseUpFuc = this.onMouseUp.bind(this);
        this.canvas.pixi.renderer.canvas.addEventListener("mouseout", this.onMouseUpFuc);
        this.on("mousemove", this.onMouseMove, this);
        this.toolbar.position = new PIXI.Point(this.width - this.toolbar.width, 0);
        this.resetSharePosition();
    }
    checkPosition() {
        if (this.toolbar.position.x < 0) {
            this.toolbar.position.x = 0;
        }
        if (this.toolbar.position.y < 0) {
            this.toolbar.position.y = 0;
        }
        if (this.toolbar.position.x + this.toolbar.width > this.boundsArea.width) {
            this.toolbar.position.x = this.boundsArea.width - this.toolbar.width;
        }
        if (this.toolbar.position.y + this.toolbar.height > this.boundsArea.height) {
            this.toolbar.position.y = this.boundsArea.height - this.toolbar.height;
        }
        this.resetSharePosition();
    }
    resetSharePosition() {
        if (!this.canvas.isMainCanvas) {
            return;
        }
        Toolbar.sharePosition.x = this.toolbar.position.x;
        Toolbar.sharePosition.y = this.toolbar.position.y;
    }
    resetBtnState() {
        this.outlineBtn.isActive = Toolbar.isShowOutline;
        this.markBtn.isActive = Toolbar.isShowMark;
        this.idBtn.isActive = Toolbar.isShowID;
        this.noBGImgBtn.isActive = Toolbar.isShowNoBGImg;
    }
    onMouseDown(event) {
        this.isMouseDown = true;
        this.interactiveChildren = false;
        this.mousePos.x = event.clientX;
        this.mousePos.y = event.clientY;
        this.hitArea = this.boundsArea;
    }
    onMouseMove(event) {
        if (!this.isMouseDown) {
            return;
        }
        this.toolbar.position.x += event.clientX - this.mousePos.x;
        this.toolbar.position.y += event.clientY - this.mousePos.y;
        this.mousePos.x = event.clientX;
        this.mousePos.y = event.clientY;
        this.checkPosition();
    }
    onMouseUp(event) {
        this.isMouseDown = false;
        this.interactiveChildren = true;
        this.hitArea = undefined;
    }
    onUndoBtnClick() {
        this.canvas.canvasMain.undo();
    }
    onRedoBtnClick() {
        this.canvas.canvasMain.redo();
    }
    onOutlineBtnClick() {
        Toolbar.isShowOutline = !Toolbar.isShowOutline;
        this.outlineBtn.isActive = Toolbar.isShowOutline;
        this.canvas.canvasMain.setShowBounds(!Toolbar.isShowOutline);
    }
    onMarkBtnClick() {
        Toolbar.isShowMark = !Toolbar.isShowMark;
        this.markBtn.isActive = Toolbar.isShowMark;
        this.canvas.canvasMain.setShowMark(!Toolbar.isShowMark);
    }
    onIDBtnClick() {
        Toolbar.isShowID = !Toolbar.isShowID;
        this.idBtn.isActive = Toolbar.isShowID;
        this.canvas.canvasMain.setShowId(!Toolbar.isShowID);
    }
    onNoBGImgBtnClick() {
        Toolbar.isShowNoBGImg = !Toolbar.isShowNoBGImg;
        this.noBGImgBtn.isActive = Toolbar.isShowNoBGImg;
        this.canvas.canvasMain.setNoBGImg(!Toolbar.isShowNoBGImg);
    }
    onZoomInBtnClick() {
        this.canvas.stage.zoomIn();
    }
    onZoomOutBtnClick() {
        this.canvas.stage.zoomOut();
    }
}
Toolbar.TOOLBAR_IMGS = [
    "./assets/toolbar/bg.png",
    "./assets/toolbar/id_0.png",
    "./assets/toolbar/id_1.png",
    "./assets/toolbar/mark_0.png",
    "./assets/toolbar/mark_1.png",
    "./assets/toolbar/noBGImg_0.png",
    "./assets/toolbar/noBGImg_1.png",
    "./assets/toolbar/outline_0.png",
    "./assets/toolbar/outline_1.png",
    "./assets/toolbar/redo_0.png",
    "./assets/toolbar/redo_1.png",
    "./assets/toolbar/undo_0.png",
    "./assets/toolbar/undo_1.png",
    "./assets/toolbar/zoomIn_0.png",
    "./assets/toolbar/zoomIn_1.png",
    "./assets/toolbar/zoomOut_0.png",
    "./assets/toolbar/zoomOut_1.png"
];
Toolbar.imgElementMap = new Map();
Toolbar.ZREO_RECT = new PIXI.Rectangle(0, 0, 0, 0);
Toolbar.sharePosition = new PIXI.Point(0, 0);
Toolbar.isShowOutline = false;
Toolbar.isShowMark = false;
Toolbar.isShowID = false;
Toolbar.isShowNoBGImg = false;
export default Toolbar;
