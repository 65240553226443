/*let img: HTMLImageElement = new Image();
img.onload = (() => {
    let points = [
        {"x": 190, "y": 268},
        {"x": 194, "y": 230},
        {"x": 206, "y": 207},
        {"x": 215, "y": 195},
        {"x": 225, "y": 180},
        {"x": 240, "y": 152},
        {"x": 235, "y": 138},
        {"x": 225, "y": 121},
        {"x": 230, "y": 101},
        {"x": 230, "y": 101},
        {"x": 235, "y": 85},
        {"x": 255, "y": 65},
        {"x": 265, "y": 55},
        {"x": 278, "y": 44},
        {"x": 292, "y": 48},
        {"x": 296, "y": 63},
        {"x": 288, "y": 75},
        {"x": 272, "y": 95},
        {"x": 262, "y": 115},
        {"x": 272, "y": 145},
        {"x": 275, "y": 170},
        {"x": 269, "y": 180},
        {"x": 265, "y": 190},
        {"x": 260, "y": 200},
        {"x": 250, "y": 228},
        {"x": 255, "y": 239},
        {"x": 242, "y": 255},
        {"x": 220, "y": 280}
    ];
    let angle: number = this.clipCanvas.calculateAngle(points);
    let len: number = this.clipCanvas.resetCanvasSize(img);
    let dataUrl: string = this.clipCanvas.getDataURL(len, angle, points, img);
    this.karyotypeCanvas.loadImg([dataUrl], (textures: Array<PIXI.Texture | undefined>) => {
        let sprite: PIXI.Sprite = new PIXI.Sprite(textures[0]);
        sprite.width = textures[0]!.width;
        sprite.height = textures[0]!.height;
        this.karyotypeCanvas.stage.addChild(sprite);
        setTimeout((() => {
            sprite.destroy();
            this.karyotypeCanvas.unloadAllImg();
        }).bind(this), 5000);
    });
}).bind(this);
img.src = "../assets/OIP.jpeg";*/
export default class ClipCanvas {
    constructor() {
        this.canvasClip = document.createElement("canvas");
        this.contextClip = this.canvasClip.getContext("2d");
        this.canvasDraw = document.createElement("canvas");
    }
    calculateAngle(points) {
        let xMin = points[0];
        let xMax = xMin;
        let yMin = points[0];
        let yMax = yMin;
        for (let i = 1; i < points.length; i++) {
            this.contextClip.lineTo(points[i].x, points[i].y);
            if (points[i].x < xMin.x) {
                xMin = points[i];
            }
            if (points[i].x > xMax.x) {
                xMax = points[i];
            }
            if (points[i].y < yMin.y) {
                yMin = points[i];
            }
            if (points[i].y > yMax.y) {
                yMax = points[i];
            }
        }
        let distance1 = Math.pow(xMin.x - points[0].x, 2) + Math.pow(xMin.y - points[0].y, 2);
        let distance2 = Math.pow(xMax.x - points[0].x, 2) + Math.pow(xMax.y - points[0].y, 2);
        let distance3 = Math.pow(yMin.x - points[0].x, 2) + Math.pow(yMin.y - points[0].y, 2);
        let distance4 = Math.pow(yMax.x - points[0].x, 2) + Math.pow(yMax.y - points[0].y, 2);
        let maxDistance = distance1;
        maxDistance = maxDistance < distance2 ? distance2 : maxDistance;
        maxDistance = maxDistance < distance3 ? distance3 : maxDistance;
        maxDistance = maxDistance < distance4 ? distance4 : maxDistance;
        let maxPoint;
        if (maxDistance == distance1) {
            maxPoint = xMin;
        }
        else if (maxDistance == distance2) {
            maxPoint = xMax;
        }
        else if (maxDistance == distance3) {
            maxPoint = yMin;
        }
        else {
            maxPoint = yMax;
        }
        let angle = Math.atan((maxPoint.x - points[0].x) / (maxPoint.y - points[0].y));
        return angle;
    }
    resetCanvasSize(image) {
        let len = Math.sqrt(Math.pow(image.width, 2) + Math.pow(image.height, 2));
        this.canvasClip.width = len;
        this.canvasClip.height = len;
        this.contextClip = this.canvasClip.getContext("2d");
        return len;
    }
    getDataURL(canvasWidth, angle, points, img, boundsData) {
        this.img = img;
        this.contextClip.clearRect(0, 0, canvasWidth, canvasWidth);
        this.contextClip.save();
        this.contextClip.setTransform(1, 0, 0, 1, 0, 0);
        let tPoints = [];
        for (let i = 0; i < points.length; i++) {
            let point = { x: points[i].x, y: points[i].y };
            point.x -= img.width / 2;
            point.y -= img.height / 2;
            let tempX = point.x * Math.cos(-angle) + point.y * Math.sin(-angle);
            let tempY = point.y * Math.cos(-angle) - point.x * Math.sin(-angle);
            point.x = tempX;
            point.y = tempY;
            tPoints[i] = point;
        }
        boundsData.transformedPoints = tPoints;
        this.contextClip.translate(canvasWidth / 2, canvasWidth / 2);
        this.contextClip.beginPath();
        this.contextClip.moveTo(tPoints[0].x, tPoints[0].y);
        for (let i = 1; i < tPoints.length; i++) {
            this.contextClip.lineTo(tPoints[i].x, tPoints[i].y);
        }
        this.contextClip.lineTo(tPoints[0].x, tPoints[0].y);
        this.contextClip.closePath();
        this.contextClip.clip();
        this.contextClip.rotate(angle);
        this.contextClip.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height);
        this.contextClip.rotate(-angle);
        let xMin = 999999999;
        let xMax = -999999999;
        let yMin = 999999999;
        let yMax = -999999999;
        for (let i = 0; i < tPoints.length; i++) {
            if (tPoints[i].x < xMin) {
                xMin = tPoints[i].x;
            }
            if (tPoints[i].x > xMax) {
                xMax = tPoints[i].x;
            }
            if (tPoints[i].y < yMin) {
                yMin = tPoints[i].y;
            }
            if (tPoints[i].y > yMax) {
                yMax = tPoints[i].y;
            }
        }
        let width = xMax - xMin;
        let height = yMax - yMin;
        boundsData.afterBounds.x = xMin;
        boundsData.afterBounds.y = yMin;
        boundsData.afterBounds.width = width;
        boundsData.afterBounds.height = height;
        xMin += canvasWidth / 2;
        yMin += canvasWidth / 2;
        boundsData.bounds.x = xMin;
        boundsData.bounds.y = yMin;
        boundsData.bounds.width = width;
        boundsData.bounds.height = height;
        if (width < 1) {
            width = 1;
        }
        if (height < 1) {
            height = 1;
        }
        let data = this.contextClip.getImageData(xMin, yMin, width, height);
        this.contextClip.setTransform(1, 0, 0, 1, 0, 0);
        this.contextClip.restore();
        this.canvasDraw.width = data.width;
        this.canvasDraw.height = data.height;
        let ctx = this.canvasDraw.getContext("2d");
        ctx === null || ctx === void 0 ? void 0 : ctx.putImageData(data, 0, 0);
        return this.canvasDraw.toDataURL("image/png");
    }
    refreshData(data) {
        let tPoints = data.transformedPoints;
        let points = [];
        for (let i = 0; i < tPoints.length; i++) {
            let point = { x: tPoints[i].x, y: tPoints[i].y };
            let tempY = (point.y * Math.cos(-data.rotateAngle) + point.x * Math.sin(-data.rotateAngle)) / (Math.sin(-data.rotateAngle) * Math.sin(-data.rotateAngle) + Math.cos(-data.rotateAngle) * Math.cos(-data.rotateAngle));
            let tempX = (point.x - tempY * Math.sin(-data.rotateAngle)) / Math.cos(-data.rotateAngle);
            tempX += this.img.width / 2;
            tempY += this.img.height / 2;
            point.x = tempX;
            point.y = tempY;
            points[i] = point;
        }
        data.points = points;
        let xMin = 999999999;
        let xMax = -999999999;
        let yMin = 999999999;
        let yMax = -999999999;
        for (let i = 0; i < tPoints.length; i++) {
            if (tPoints[i].x < xMin) {
                xMin = tPoints[i].x;
            }
            if (tPoints[i].x > xMax) {
                xMax = tPoints[i].x;
            }
            if (tPoints[i].y < yMin) {
                yMin = tPoints[i].y;
            }
            if (tPoints[i].y > yMax) {
                yMax = tPoints[i].y;
            }
        }
        let width = xMax - xMin;
        let height = yMax - yMin;
        data.afterBounds.x = xMin;
        data.afterBounds.y = yMin;
        data.afterBounds.width = width;
        data.afterBounds.height = height;
        xMin += this.canvasClip.width / 2;
        yMin += this.canvasClip.width / 2;
        data.bounds.x = xMin;
        data.bounds.y = yMin;
        data.bounds.width = width;
        data.bounds.height = height;
        data.textureData = this.getDataURL(this.canvasClip.width, data.rotateAngle, data.points, this.img, data);
    }
}
