import * as PIXI from "pixi.js";
import { ITEM_MOVE_DRAG_START, ITEM_MOVE_DRAG_END, ITEM_REORDER, ITEM_SELECTED, ITEM_EDITED_IN_KARYOTYPE_PAGE, ITEM_CHANGED, ITEM_GROUP_CHANGED_IN_KARYOTYPE_PAGE, ITEM_REMOVED, ITEM_IMAGE_LOAD_COMPLETE } from "../Event/ItemEventName";
import OutlineTool from "../Core/Tools/OutlineTool";
import MemoData from "../Core/Data/MemoData";
import { OperationType } from "../Core/OperationStepSystem";
import { SYSTEM_OPERATION_STEP_PUSH } from "../Core/Event/SystemEventName";
import Container from "../Core/Component/Container";
import Idiogram from "./Idiogram";
import Menu from "./Menu";
export default class ChromosomeItem extends Container {
    constructor(data, maxWidth, maxHeight, canvas) {
        super();
        this.BTN_HEIGHT = 10;
        this.LINE_WIDTH = 1;
        this.DOTTED_LINE_LENGTH = 2.5;
        this.ZERO_BOUNDS = new PIXI.Rectangle(0, 0, 0, 0);
        this.originalScale = 1;
        this.mousePosition = new PIXI.Point();
        this.moveStartPosition = new PIXI.Point();
        this.isShowIdiogram = false;
        this.ratioY = 0;
        this.isLeftPositive = true;
        this.isTopPositive = true;
        this.scaleBtnType = BtnType.BottomRight;
        this.editMode = OperationMode.None;
        this.isOperationMode = false;
        this.isEditMode = false;
        this.isCentromereMode = false;
        this.isCentromereEdit = false;
        this.isDrawCentromereFrame = false;
        this.isSelected = false;
        this.editorPoints = [];
        this.editorMode = EditorMode.None;
        this.editorPoint = new PIXI.Point();
        const BTN_HEIGHT = this.BTN_HEIGHT;
        const LINE_WIDTH = this.LINE_WIDTH;
        this._id = data.id;
        this._index = data.index;
        this.maxWidth = maxWidth;
        this.maxHeight = maxHeight;
        this.canvas = canvas;
        this._data = data;
        let ratioY = this.data.positionY;
        this.image = new PIXI.Sprite();
        this.addChild(this.image);
        this.loadTexture(data.textureData, (() => {
            this.y = this.maxHeight * ratioY - this.height / 2;
            if (this.y < this.height / 2) {
                this.y = this.height / 2;
            }
            else if (this.y > this.maxHeight - this.height / 2) {
                this.y = this.maxHeight - this.height / 2;
            }
            this.setPosY();
            this.setInteractive(true);
        }).bind(this));
        this.centromereGraphics = new PIXI.Graphics();
        this.addChild(this.centromereGraphics);
        this.centromereGraphics.setStrokeStyle({ color: 0xff0000, width: LINE_WIDTH, alpha: 0.5 });
        this.centromereGraphics.interactive = false;
        this.centromereGraphics.hitArea = this.ZERO_BOUNDS;
        this.boundLine = new PIXI.Graphics();
        this.boundLine.setStrokeStyle({ color: 0xa762c1, width: LINE_WIDTH });
        this.addChild(this.boundLine);
        this.boundLine.visible = false;
        this.rotateBtn = new PIXI.Container();
        this.addChild(this.rotateBtn);
        this.rotateBtn.boundsArea = new PIXI.Rectangle(0, 0, this.width, BTN_HEIGHT);
        this.rotateBtn.hitArea = this.rotateBtn.boundsArea;
        this.rotateBtn.interactive = true;
        this.rotateBtn.cursor = "url(./assets/rotateCursor.png) 8 8, auto";
        this.rotateBtn.visible = false;
        this.idiogram = new Idiogram(this.id, canvas.canvasMain.idiogram, canvas);
        this.addChild(this.idiogram);
        this.isShowIdiogram = false;
        this.idiogram.visible = this.isShowIdiogram;
        this.rotateBtn.on("mousedown", this.onMouseDownInRotateBtn, this);
        this.image.on("mousedown", this.onMouseDownInImage, this);
        this.image.on("wheel", this.onMouseWheel, this);
        this.centromereGraphics.on("mousedown", this.onMouseDownInCentromereGraphics, this);
        this.canvas.stage.on("mousedown", this.onMouseDownInStage, this);
        this.canvas.stage.on("mouseup", this.onMouseUp, this);
        this.canvas.stage.on("mousemove", this.onMouseMove, this);
        this.canvas.on(ITEM_SELECTED, this.onSelected, this);
        this.canvas.on(ITEM_MOVE_DRAG_START, this.onItemDragStart, this);
        this.canvas.on(ITEM_MOVE_DRAG_END, this.onItemDragEnd, this);
        this.canvas.canvasMain.on(ITEM_CHANGED, this.onItemChanged, this);
        this.image.on("rightclick", (e) => {
            e.preventDefault();
            e.stopPropagation();
            new Menu(this.canvas, e.global, this.isShowIdiogram).on("idiogram", (value) => {
                this.setIdiogram(value);
            }, this);
        }, true);
        this.canvas.stage.on("click", (e) => {
            if (e.detail == 2) {
                let localPos = this.toLocal(e.global);
                if (localPos.x < this.width && localPos.x > 0 && localPos.y < this.height && localPos.y > 0) {
                    this.beforeOperationData = this.data.clone();
                    this.image.angle += 180;
                    if (this.image.angle < 0) {
                        this.image.angle += 360;
                    }
                    else if (this.image.angle > 360) {
                        this.image.angle -= 360;
                    }
                    this.canvas.emit(ITEM_REORDER, this);
                    this.data.angle = this.image.angle;
                    this.resetStyle();
                    this.drawCentromere();
                    let memo = new MemoData(this.beforeOperationData.serialize(), this.data.serialize(), OperationType.Transform, "0000100000000000");
                    this.beforeOperationData = undefined;
                    this.canvas.canvasMain.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
                }
            }
        }, this);
    }
    onDestroy() {
        this.rotateBtn.off("mousedown", this.onMouseDownInRotateBtn, this);
        this.image.off("mousedown", this.onMouseDownInImage, this);
        this.image.off("wheel", this.onMouseWheel, this);
        this.image.off("rightclick");
        this.image.off("click");
        this.centromereGraphics.off("mousedown", this.onMouseDownInCentromereGraphics, this);
        this.canvas.stage.off("mousedown", this.onMouseDownInStage, this);
        this.canvas.stage.off("mouseup", this.onMouseUp, this);
        this.canvas.stage.off("mousemove", this.onMouseMove, this);
        this.canvas.off(ITEM_SELECTED, this.onSelected, this);
        this.canvas.off(ITEM_MOVE_DRAG_START, this.onItemDragStart, this);
        this.canvas.off(ITEM_MOVE_DRAG_END, this.onItemDragEnd, this);
        this.canvas.canvasMain.off(ITEM_CHANGED, this.onItemChanged, this);
    }
    get id() { return this._id; }
    get index() { return this._index; }
    get data() { return this._data; }
    setId(value) {
        this._id = value;
        this.data.id = value;
        this.idiogram.setId(value);
    }
    setIndex(value) {
        this._index = value;
        this.data.index = value;
    }
    setData(data) {
        this.data.copyFrom(data);
        this.ratioY = this.data.positionY;
        this.y = this.maxHeight * this.ratioY - this.height / 2;
        this.loadTexture(data.textureData, (() => {
            this.resetStyle();
            this.image.angle = this.data.angle;
            if (this.isEditMode) {
                this.drawEditorGraphics();
            }
        }).bind(this));
    }
    setIdiogram(value) {
        this.isShowIdiogram = value;
        this.idiogram.visible = this.isShowIdiogram;
        this.resetStyle();
        this.canvas.emit(ITEM_REORDER, this);
    }
    loadTexture(url, callback) {
        PIXI.Assets.load(url).then((texture) => {
            this.texture = texture;
            this.image.texture = texture;
            this.resetSize(this.maxWidth, this.maxHeight);
            this.canvas.emit(ITEM_REORDER, this);
            this.canvas.emit(ITEM_IMAGE_LOAD_COMPLETE, undefined);
            if (callback) {
                callback();
            }
        });
    }
    resetSize(maxWidth, maxHeight) {
        if (!this.texture) {
            return;
        }
        this.maxWidth = maxWidth;
        this.maxHeight = maxHeight;
        let maxRatio = maxWidth / maxHeight;
        let ratio = this.texture.width / this.texture.height;
        if (maxRatio < ratio) {
            this.image.width = maxWidth;
            this.image.height = maxWidth / ratio;
        }
        else {
            this.image.width = maxHeight * ratio;
            this.image.height = maxHeight;
        }
        this.idiogram.setHeight(this.image.height);
        this.originalScale = this.image.scale.x;
        this.resetStyle();
        this.y = maxHeight * this.ratioY - this.height / 2;
        if (this.y < this.height / 2) {
            this.y = this.height / 2;
        }
        else if (this.y > this.maxHeight - this.height / 2) {
            this.y = this.maxHeight - this.height / 2;
        }
        this.drawCentromere();
    }
    setPosY() {
        let bottomY = this.y + this.height / 2;
        this.ratioY = bottomY / this.maxHeight;
        this.data.positionY = this.ratioY;
    }
    switchToEditMode() {
        this.isSelected = true;
        this.isEditMode = true;
        this.isOperationMode = false;
        this.isCentromereMode = false;
        this.boundLine.visible = false;
        this.rotateBtn.visible = false;
        this.interactiveChildren = false;
        this.canvas.stage.canMove = false;
        this.isDrawCentromereFrame = false;
        this.centromereGraphics.interactive = false;
        this.drawEditorGraphics();
        this.drawCentromere();
        this.centromereGraphics.hitArea = this.ZERO_BOUNDS;
    }
    switchToCentromereMode() {
        this.isSelected = true;
        this.isCentromereMode = true;
        this.isEditMode = false;
        this.isOperationMode = false;
        this.boundLine.visible = false;
        this.rotateBtn.visible = false;
        this.interactiveChildren = true;
        this.image.interactive = false;
        this.centromereGraphics.interactive = true;
        this.canvas.stage.canMove = false;
        this.isDrawCentromereFrame = true;
        this.drawCentromere();
        this.centromereGraphics.hitArea = this.centromereGraphics.boundsArea;
    }
    switchToOperationMode() {
        if (this.editorGraphics) {
            this.editorGraphics.destroy();
        }
        this.editorMode = EditorMode.None;
        this.editorGraphics = undefined;
        this.isEditMode = false;
        this.isCentromereMode = false;
        this.interactiveChildren = true;
        this.image.interactive = true;
        this.centromereGraphics.interactive = false;
        this.canvas.stage.canMove = true;
        this.isDrawCentromereFrame = false;
        this.isSelected = true;
        this.boundLine.visible = true;
        this.rotateBtn.visible = true;
        this.drawCentromere();
        this.centromereGraphics.hitArea = this.ZERO_BOUNDS;
    }
    switchToNoneMode() {
        if (this.editorGraphics) {
            this.editorGraphics.destroy();
        }
        this.editorMode = EditorMode.None;
        this.editorGraphics = undefined;
        this.isEditMode = false;
        this.isCentromereMode = false;
        this.interactiveChildren = true;
        this.image.interactive = true;
        this.centromereGraphics.interactive = false;
        this.canvas.stage.canMove = true;
        this.isDrawCentromereFrame = false;
        this.isSelected = false;
        this.boundLine.visible = false;
        this.rotateBtn.visible = false;
        this.drawCentromere();
        this.centromereGraphics.hitArea = this.ZERO_BOUNDS;
    }
    drawCentromere() {
        const DOTTED_LINE_LENGTH = this.DOTTED_LINE_LENGTH;
        this.centromereGraphics.boundsArea = new PIXI.Rectangle(0, 0, this.width, this.height);
        this.centromereGraphics.pivot = { x: this.width / 2, y: this.height / 2 };
        this.centromereGraphics.x = this.width / 2;
        this.centromereGraphics.y = this.height / 2;
        let tempY = this.height * this.data.centromere;
        this.centromereGraphics.clear();
        this.centromereGraphics.moveTo(0, tempY);
        let tempX = 0;
        let isDraw = true;
        while (tempX < this.width) {
            tempX += DOTTED_LINE_LENGTH;
            if (isDraw) {
                this.centromereGraphics.lineTo(tempX, tempY);
            }
            else {
                this.centromereGraphics.moveTo(tempX, tempY);
            }
            isDraw = !isDraw;
        }
        if (this.isDrawCentromereFrame) {
            this.centromereGraphics.moveTo(0, 0).lineTo(0, this.height).lineTo(this.width, this.height).lineTo(this.width, 0).lineTo(0, 0);
        }
        this.centromereGraphics.stroke();
    }
    drawEditorGraphics() {
        let bounds = this.data.afterBounds;
        let points = this.editorPoints;
        points.length = 0;
        if (this.editorGraphics) {
            this.editorGraphics.destroy();
        }
        this.editorGraphics = new PIXI.Graphics();
        let graphics = this.editorGraphics;
        this.addChild(graphics);
        for (let i = 0; i < this.data.transformedPoints.length; i++) {
            let oPoint = this.data.transformedPoints[i];
            let point = { x: 0, y: 0 };
            point.x = (oPoint.x - bounds.x) * this.image.scale.x;
            point.y = (oPoint.y - bounds.y) * this.image.scale.y;
            points.push(point);
        }
        graphics.setStrokeStyle({ color: 0xff0000, width: this.LINE_WIDTH });
        graphics.moveTo(points[0].x, points[0].y);
        for (let i = 1; i < points.length; i++) {
            graphics.lineTo(points[i].x, points[i].y);
        }
        graphics.lineTo(points[0].x, points[0].y);
        graphics.stroke();
        graphics.boundsArea = new PIXI.Rectangle(0, 0, this.image.width, this.image.height);
        graphics.pivot = new PIXI.Point(this.image.width / 2, this.image.height / 2);
        graphics.rotation = this.image.rotation;
        graphics.position = this.image.position;
    }
    setInteractive(value) {
        this.image.interactive = value;
    }
    onItemDragStart() {
        this.setInteractive(false);
    }
    onItemDragEnd() {
        this.setInteractive(true);
    }
    onMouseWheel(event) {
        if (!this.isSelected) {
            return;
        }
        event.stopImmediatePropagation();
        if (event.deltaY < 0 && this.image.scale.x < this.originalScale * 2) {
            this.image.scale = this.image.scale.x + 0.1;
        }
        else if (event.deltaY > 0 && this.image.scale.x > this.originalScale / 2) {
            this.image.scale = this.image.scale.x - 0.1;
        }
        this.resetStyle();
        this.drawCentromere();
    }
    onMouseDownInStage(event) {
        if (!this.isEditMode) {
            return;
        }
        let temp = { x: 0, y: 0 };
        temp.x = (event.screenX - this.canvas.stage.x) / this.canvas.stage.scale.x;
        temp.y = (event.screenY - this.canvas.stage.y) / this.canvas.stage.scale.y;
        let global = this.canvas.stage.toGlobal(temp);
        let local = this.toLocal(global);
        this.mousePosition.set(event.screenX, event.screenY);
        this.editorPoint.set(local.x, local.y);
        this.editorMode = OutlineTool.contains(this.editorPoints, this.editorPoint) ? EditorMode.Add : EditorMode.Subtract;
    }
    onMouseDownInRotateBtn(event) {
        this.isOperationMode = true;
        this.editMode = OperationMode.Rotate;
        this.mousePosition.set(event.screenX, event.screenY);
        event.stopPropagation();
        this.beforeOperationData = this.data.clone();
    }
    onMouseDownInImage(event) {
        if (!this.isSelected) {
            this.canvas.emit(ITEM_SELECTED, this);
            return;
        }
        this.isOperationMode = true;
        this.editMode = OperationMode.Move;
        this.mousePosition.set(event.screenX, event.screenY);
        this.moveStartPosition.set(this.x, this.y);
        event.stopPropagation();
        this.canvas.emit(ITEM_MOVE_DRAG_START, this);
        this.interactiveChildren = false;
        this.beforeOperationData = this.data.clone();
    }
    onMouseDownInCentromereGraphics(event) {
        if (!this.isCentromereMode) {
            return;
        }
        this.beforeOperationData = this.data.clone();
        this.isCentromereEdit = true;
    }
    onMouseMove(event) {
        let offsetX = event.screenX - this.mousePosition.x;
        let offsetY = event.screenY - this.mousePosition.y;
        this.mousePosition.set(event.screenX, event.screenY);
        if (this.isOperationMode) {
            if (this.editMode == OperationMode.Rotate) {
                this.image.angle += offsetX;
                this.image.angle += offsetY;
                if (this.image.angle < 0) {
                    this.image.angle += 360;
                }
                else if (this.image.angle > 360) {
                    this.image.angle -= 360;
                }
                this.canvas.emit(ITEM_REORDER, this);
                this.data.angle = this.image.angle;
                this.resetStyle();
                this.drawCentromere();
                this.canvas.canvasMain.stopSaveTimer();
                return;
            }
            else if (this.editMode == OperationMode.Move) {
                this.x += offsetX / this.canvas.stage.scale.x;
                this.y += offsetY / this.canvas.stage.scale.y;
                this.canvas.canvasMain.stopSaveTimer();
            }
        }
        else if (this.isEditMode && this.editorMode != EditorMode.None) {
            if (this.editorPoints && this.editorPoints.length > 2) {
                let temp = { x: 0, y: 0 };
                temp.x = (event.screenX - this.canvas.stage.x) / this.canvas.stage.scale.x;
                temp.y = (event.screenY - this.canvas.stage.y) / this.canvas.stage.scale.y;
                let global = this.canvas.stage.toGlobal(temp);
                let local = this.editorGraphics.toLocal(global);
                var points = [];
                let size = this.canvas.editorPenSize / (this.canvas.stage.scale.x);
                for (var angle = 0; angle <= 360; angle += 10) {
                    var radian = angle * Math.PI / 180;
                    var x = local.x + size * Math.cos(radian);
                    var y = local.y + size * Math.sin(radian);
                    points.push({ x: x, y: y });
                }
                let fun = this.editorMode == EditorMode.Add ? OutlineTool.difference : OutlineTool.difference; //OutlineTool.union : OutlineTool.difference;
                let result = fun([this.editorPoints], false, [points], false);
                points = result.points[0];
                let bigBoundSize = 0;
                for (let i = 0; i < result.points.length; i++) {
                    let boundSizeData = this.getBoundSize(result.points[i]);
                    let boundSize = boundSizeData.width * boundSizeData.width + boundSizeData.height * boundSizeData.height;
                    if (boundSize > bigBoundSize) {
                        bigBoundSize = boundSize;
                        points = result.points[i];
                    }
                }
                let graphics = this.editorGraphics;
                this.editorPoints = points;
                graphics.clear();
                if (points && points.length > 2) {
                    graphics.moveTo(points[0].x, points[0].y);
                    for (let i = 1; i < points.length; i++) {
                        graphics.lineTo(points[i].x, points[i].y);
                    }
                    graphics.lineTo(points[0].x, points[0].y);
                    graphics.stroke();
                }
                this.canvas.canvasMain.stopSaveTimer();
            }
        }
        else if (this.isCentromereMode && this.isCentromereEdit) {
            let temp = { x: 0, y: 0 };
            temp.x = (event.screenX - this.canvas.stage.x) / this.canvas.stage.scale.x;
            temp.y = (event.screenY - this.canvas.stage.y) / this.canvas.stage.scale.y;
            let global = this.canvas.stage.toGlobal(temp);
            let local = this.centromereGraphics.toLocal(global);
            let tempCentromere = local.y / this.centromereGraphics.height;
            tempCentromere = tempCentromere < 0 ? 0 : tempCentromere;
            tempCentromere = tempCentromere > 1 ? 1 : tempCentromere;
            this.data.centromere = tempCentromere;
            this.drawCentromere();
            this.canvas.canvasMain.stopSaveTimer();
        }
    }
    onMouseUp() {
        if (this.isOperationMode) {
            this.isOperationMode = false;
            let beforeOperationData = this.beforeOperationData;
            this.beforeOperationData = undefined;
            if (this.editMode == OperationMode.Move) {
                this.canvas.emit(ITEM_MOVE_DRAG_END, this);
                this.interactiveChildren = true;
                if (this.y < this.height / 2) {
                    this.y = this.height / 2;
                }
                else if (this.y > this.maxHeight - this.height / 2) {
                    this.y = this.maxHeight - this.height / 2;
                }
                this.setPosY();
                let isIdChange = this.data.id != beforeOperationData.id;
                let isIndexChange = this.data.index != beforeOperationData.index;
                let isPosYChange = this.data.positionY != beforeOperationData.positionY;
                if (!isIdChange && !isIndexChange && !isPosYChange) {
                    this.canvas.canvasMain.recoverSaveTimer();
                    return;
                }
                let memo = new MemoData(beforeOperationData.serialize(), this.data.serialize(), OperationType.Transform, `${isIdChange ? "1" : "0"}${isIndexChange ? "1" : "0"}000${isPosYChange ? "1" : "0"}0000000000`);
                this.canvas.canvasMain.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
                if (isIdChange) {
                    this.canvas.canvasMain.emit(ITEM_GROUP_CHANGED_IN_KARYOTYPE_PAGE, this.data);
                }
            }
            else if (this.editMode == OperationMode.Rotate) {
                if (this.data.angle == beforeOperationData.angle) {
                    this.canvas.canvasMain.recoverSaveTimer();
                    return;
                }
                let memo = new MemoData(beforeOperationData.serialize(), this.data.serialize(), OperationType.Transform, "0000100000000000");
                this.canvas.canvasMain.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
            }
        }
        else if (this.isEditMode) {
            this.editorMode = EditorMode.None;
            if (this.editorPoints && this.editorPoints.length > 2) {
                let points = [];
                for (let i = 0; i < this.editorPoints.length; i++) {
                    let oPoint = this.editorPoints[i];
                    let point = { x: 0, y: 0 };
                    point.x = oPoint.x / this.image.scale.x + this.data.afterBounds.x;
                    point.y = oPoint.y / this.image.scale.y + this.data.afterBounds.y;
                    points.push(point);
                }
                let isChanged = false;
                for (let i = 0; i < points.length; i++) {
                    if (Math.abs(points[i].x - this.data.transformedPoints[i].x) > 0.001 || Math.abs(points[i].y - this.data.transformedPoints[i].y) > 0.001) {
                        console.log(points[i].x, this.data.transformedPoints[i].x, points[i].y, this.data.transformedPoints[i].y);
                        isChanged = true;
                        break;
                    }
                }
                if (!isChanged) {
                    this.canvas.canvasMain.recoverSaveTimer();
                    return;
                }
                console.log("changed");
                this.beforeOperationData = this.data.clone();
                this.data.transformedPoints = points;
                this.canvas.canvasMain.emit(ITEM_EDITED_IN_KARYOTYPE_PAGE, this.data);
            }
            else {
                this.beforeOperationData = this.data.clone();
                let after = "";
                let memo = new MemoData(this.data.serialize(), after, OperationType.Delete, "1111111111000000");
                this.canvas.canvasMain.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
                this.canvas.canvasMain.emit(ITEM_REMOVED, this.data);
            }
        }
        else if (this.isCentromereMode) {
            this.isCentromereEdit = false;
            let beforeOperationData = this.beforeOperationData;
            this.beforeOperationData = undefined;
            if (this.data.centromere == beforeOperationData.centromere) {
                this.canvas.canvasMain.recoverSaveTimer();
                return;
            }
            let memo = new MemoData(beforeOperationData.serialize(), this.data.serialize(), OperationType.Transform, "0001000000000000");
            this.canvas.canvasMain.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
        }
    }
    getBoundSize(points) {
        let xMin = 999999999;
        let xMax = -999999999;
        let yMin = 999999999;
        let yMax = -999999999;
        for (let i = 0; i < points.length; i++) {
            if (points[i].x < xMin) {
                xMin = points[i].x;
            }
            if (points[i].x > xMax) {
                xMax = points[i].x;
            }
            if (points[i].y < yMin) {
                yMin = points[i].y;
            }
            if (points[i].y > yMax) {
                yMax = points[i].y;
            }
        }
        let width = xMax - xMin;
        let height = yMax - yMin;
        return { width: width, height: height };
    }
    resetStyle() {
        const BTN_HEIGHT = this.BTN_HEIGHT;
        this.image.angle = this.data.angle;
        this.image.pivot = new PIXI.Point(0, 0);
        let points = [{ x: 0, y: 0 }, { x: this.image.width, y: 0 }, { x: this.image.width, y: this.image.height }, { x: 0, y: this.image.height }];
        for (let i = 0; i < points.length; i++) {
            let point = points[i];
            let tempX = point.x * Math.cos(this.image.rotation) + point.y * Math.sin(this.image.rotation);
            let tempY = point.y * Math.cos(this.image.rotation) - point.x * Math.sin(this.image.rotation);
            point.x = tempX;
            point.y = tempY;
        }
        let boundSize = this.getBoundSize(points);
        let width = boundSize.width;
        let height = boundSize.height;
        this.boundsArea = new PIXI.Rectangle(0, 0, this.isShowIdiogram ? width + this.idiogram.width : width, height);
        this.idiogram.setHeight(this.image.height);
        this.idiogram.x = width;
        this.idiogram.y = height - this.idiogram.height;
        this.pivot = new PIXI.Point(width / 2, height / 2);
        this.image.pivot = new PIXI.Point(this.image.width / this.image.scale.x / 2, this.image.height / this.image.scale.x / 2);
        this.image.x = width / 2;
        this.image.y = height / 2;
        this.boundLine.clear();
        this.boundLine.moveTo(0, 0);
        this.boundLine.lineTo(width, 0);
        this.boundLine.lineTo(width, height);
        this.boundLine.lineTo(0, height);
        this.boundLine.lineTo(0, 0);
        this.boundLine.stroke();
        this.boundLine.rect(0, 0, width, BTN_HEIGHT).fill({ color: 0xa762c1, alpha: 0.3 });
        this.rotateBtn.boundsArea = new PIXI.Rectangle(0, 0, width, BTN_HEIGHT);
        this.rotateBtn.hitArea = this.rotateBtn.boundsArea;
    }
    onSelected(target) {
        let isSelected = target == this;
        this.isSelected = isSelected;
        this.boundLine.visible = isSelected;
        this.image.scale = this.originalScale;
        this.resetStyle();
        this.drawCentromere();
        if (!isSelected) {
            this.switchToNoneMode();
        }
    }
    onItemChanged(data) {
        if (data != this.data) {
            return;
        }
        this.loadTexture(data.textureData, (() => {
            this.resetStyle();
            if (this.isEditMode) {
                this.drawEditorGraphics();
            }
        }).bind(this));
        if (!this.beforeOperationData) {
            return;
        }
        let memo = new MemoData(this.beforeOperationData.serialize(), this.data.serialize(), OperationType.Decoration, "0000001111100000");
        this.canvas.canvasMain.emit(SYSTEM_OPERATION_STEP_PUSH, memo);
        this.beforeOperationData = undefined;
    }
}
var BtnType;
(function (BtnType) {
    BtnType[BtnType["TopLeft"] = 0] = "TopLeft";
    BtnType[BtnType["Top"] = 1] = "Top";
    BtnType[BtnType["TopRight"] = 2] = "TopRight";
    BtnType[BtnType["Left"] = 3] = "Left";
    BtnType[BtnType["Right"] = 4] = "Right";
    BtnType[BtnType["BottomLeft"] = 5] = "BottomLeft";
    BtnType[BtnType["Bottom"] = 6] = "Bottom";
    BtnType[BtnType["BottomRight"] = 7] = "BottomRight";
})(BtnType || (BtnType = {}));
var OperationMode;
(function (OperationMode) {
    OperationMode[OperationMode["None"] = 0] = "None";
    OperationMode[OperationMode["Move"] = 1] = "Move";
    OperationMode[OperationMode["Rotate"] = 2] = "Rotate";
})(OperationMode || (OperationMode = {}));
var EditorMode;
(function (EditorMode) {
    EditorMode[EditorMode["None"] = 0] = "None";
    EditorMode[EditorMode["Add"] = 1] = "Add";
    EditorMode[EditorMode["Subtract"] = 2] = "Subtract";
})(EditorMode || (EditorMode = {}));
